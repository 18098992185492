export const ToolType = Object.freeze({
    POINT: "Point",
    CIRCLE: "Circle",
    POLYGON: "Polygon",
    FREEHAND: "Freehand",
    NONE: "None",
    AREA: "Area",
});

export const ToolType_se = Object.freeze({
    point: "Punkt",
    circle: "Cirkel",
    polygon: "Polygon",
    freehand: "Rita",
    upload: "Sparat område",
    area: "Hämtat område",
});

export const Category_en = Object.freeze({
    WORKPLACE: "workplace",
    EDUCATION: "education",
    COMMERCIALSERVICE: "commercial_service",
    PUBLICSERVICE: "public_service",
    HEALTCARE: "healthcare",
    CULTURE: "culture",
    RECREATION: "recreation",
});

export const Category_sv = Object.freeze({
    workplace: "Arbetsmarknad",
    education: "Utbildning",
    commercial_service: "Kommersiell service",
    public_service: "Offentlig service",
    healthcare: "Sjukvård",
    culture: "Kultur och föreningsliv",
    recreation: "Rekreation",
});

export const TravelOption = Object.freeze({
    CAR: "car",
    WALK: "walk",
    CYCLE: "cycle",
    TRANSIT: "transit",
});

export const ColumsId = {
    overview: [
        "workplace",
        "education",
        "commercial_service",
        "public_service",
        "healthcare",
        "culture",
        "recreation",
    ],
    workplace: [
        "workplace_sni_01",
        "workplace_sni_02",
        "workplace_sni_03",
        "workplace_sni_04",
        "workplace_sni_05",
        "workplace_sni_06",
        "workplace_sni_07",
        "workplace_sni_08",
        "workplace_sni_09",
        "workplace_sni_10",
        "workplace_sni_11",
        "workplace_sni_12",
        "workplace_sni_13",
        "workplace_sni_14",
        "workplace_sni_15",
        "workplace_sni_16",
    ],
    education: [
        "elementary_school",
        "high_school",
        "folk_high_school",
        "university",
    ],
    commercial_service: ["groceries", "fuel", "pharmacy", "postal_service"],
    public_service: ["rescue_service", "police", "library", "town_hall"],
    healthcare: ["health_centers", "hospital"],
    culture: ["cinema", "museum"],
    recreation: ["nature_reserve", "holiday_home"],
};

export const ColumnName = {
    overview: [
        "Arbetsmarknad",
        "Utbildning",
        "Kommersiell service",
        "Offentlig service",
        "Sjukvård",
        "Kultur och föreningsliv",
        "Rekreation",
    ],
    workplace: [
        "Jordbruk, skogsbruk, jakt och fiske",
        "Tillverkning och utvinning",
        "Energi och miljöverksamhet",
        "Byggverksamhet",
        "Handel",
        "Transport och magasinering",
        "Hotell- och restaurangverksamhet",
        "Information och kommunikation",
        "Finans- och försäkringsverksamhet",
        "Fastighetsverksamhet",
        "Företagstjänster",
        "Offentlig förvaltning och försvar",
        "Utbildning",
        "Vård och omsorg, sociala tjänster",
        "Kulturella och personliga tjänster m.m.",
        "Okänd verksamhet",
    ],
    education: [
        "Grundskola",
        "Gymnasieskola",
        "Folkhögskola",
        "Universitet/Högskola",
    ],
    commercial_service: [
        "Dagligvaruhandel",
        "Drivmedel",
        "Apotek",
        "Postombud",
    ],
    public_service: ["Räddningstjänst", "Polis", "Bibliotek", "Kommunhus"],
    healthcare: ["Vårdcentral", "Sjukhus"],
    culture: ["Biograf", "Museum"],
    recreation: ["Naturreservat", "Fritidshus"],
};

export const OL_FILL_COLOR = "#FF990060";
export const OL_STROKE_COLOR = "#FF9900";

import { api } from "@/api";

class MinasidorAreaService {
    async getAll() {
        try {
            return await api.get("minasidor/area/all");
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    async findById(id) {
        try {
            return await api.get("minasidor/area/?id=" + id);
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    async save(area) {
        try {
            await api.post("minasidor/area", area);
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    async update(area) {
        try {
            await api.patch("minasidor/area", area);
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    async delete(id) {
        try {
            await api.delete("minasidor/area?id=" + id);
        } catch (error) {
            console.log(error);
            return error;
        }
    }
}

export default new MinasidorAreaService();

function float16ToFloat(h) {
    const s = (h & 0x8000) >> 15;
    const e = (h & 0x7C00) >> 10;
    const f = h & 0x03FF;

    if (e == 0) {
        return (s ? -1 : 1) * Math.pow(2, -14) * (f / Math.pow(2, 10));
    } else if (e == 0x1F) {
        return f ? NaN : ((s ? -1 : 1) * Infinity);
    }

    return (s ? -1 : 1) * Math.pow(2, e-15) * (1 + (f / Math.pow(2, 10)));
}

export function UnPackAttractions(base64Data) {
    const binary_string = window.atob(base64Data);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }

    const dataView = new DataView(bytes.buffer);
    
    let data = [];
    let idx = 0;
    let geoId = 0;
    let attraction = 0;
    let diff = 0;
    const littleEndian = true;

    const size = dataView.getInt32(idx, littleEndian) * 8;
    idx += 4;

    while (idx < size) {
        geoId = dataView.getInt32(idx, littleEndian);
        idx += 4;
        attraction = float16ToFloat(dataView.getUint16(idx, littleEndian));
        idx += 2;
        diff = dataView.getInt16(idx, littleEndian);
        idx += 2;
        data.push({
            attraction: attraction,
            x: (0xFFFF & geoId) * 250,
            y: (0xFFFF & (geoId >> 16)) * 250,
            diff: diff,
        });
    }
    return data;
};

export function UnPackStatistics(base64Data) {
    const binary_string = window.atob(base64Data);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }

    const dataView = new DataView(bytes.buffer);
    
    let data = [];
    let idx = 0;
    let geoId = 0;
    let x = 0.0;
    let y = 0.0;
    const littleEndian = true;

    const size = dataView.getInt32(idx, littleEndian) * 6;
    idx += 4;
    
    while (idx < size) {
        geoId = dataView.getInt32(idx, littleEndian);
        idx += 4;
        x = dataView.getFloat32(idx, littleEndian);
        idx += 4;
        y = dataView.getFloat32(idx, littleEndian);
        idx += 4;
        data.push({
            geoId,
            x,
            y,
        });
    }
    return data;
};
import ChartService from "@/services/ChartService";
import { ToolType } from "@/constants";

const reset = [
  {
    Category: "Medelålder",
    Område: 0,
    Jämförelseområde: 0,
    Unit: "år",
  },
  {
    Category: "Andel utrikesfödda",
    Område: 0,
    Jämförelseområde: 0,
    Unit: "%",
  },
  {
    Category: "Andel födda per 1000 inv.",
    Område: 0,
    Jämförelseområde: 0,
    Unit: "per 1000 inv.",
  },
  {
    Category: "Antal döda per 1000 inv.",
    Område: 0,
    Jämförelseområde: 0,
    Unit: "per 1000 inv.",
  },
  {
    Category: "Bostadsyta per person",
    Område: 0,
    Jämförelseområde: 0,
    Unit: "m2",
  },
  {
    Category: "Andel eftergymnasial utb.",
    Område: 0,
    Jämförelseområde: 0,
    Unit: "%",
  },
  {
    Category: "Antal personbilar i trafik/pers",
    Område: 0,
    Jämförelseområde: 0,
    Unit: "%",
  },
];

export default {
  state: {
    overview: reset,
    overviewPopulation: [0, 0],
    selectedAreaInformation: {},
    showDiagramPanel: false,
    age: null,
    showLoading: false,
    populationInformationCompareMode: {},
    touristInformationCompareMode: {},
  },
  mutations: {
    changeLocationData(state, { index, value }) {
      state.overview[index].Område = value;
    },
    changeLocationPopulationData(state, { index, value }) {
      state.overviewPopulation[index] = value;
    },
    changeLocationDataComparison(state, { index, value }) {
      if (state.overview[index]) {
        state.overview[index].Jämförelseområde = value;
      }
    },
    resetLocationData(state) {
      state.overview = reset;
    },
    changeAge(state, data) {
      state.age = data;
    },
    changeDiagramPanelVisibility(state, value) {
      state.showDiagramPanel = value;
    },
    resetSelectedAreaInformation(state) {
      state.selectedAreaInformation = {};
    },
    setSelectedAreaInformation(state, geometry) {
      state.selectedAreaInformation = geometry;
    },
    showLoader(state, value) {
      state.showLoading = value;
    },
    setPopulationInformationCompareMode(state, data) {
      state.populationInformationCompareMode = data;
    },
    setTouristInformationCompareMode(state, data) {
      state.touristInformationCompareMode = data;
    },
  },
  actions: {
    async initializeOverview({ commit, dispatch }) {
      const response = await ChartService.getOverviewAll();
      if (response && response.data) {
        response.data.forEach((area, index) => {
          if (area.Category === "SelectionPopulation") {
            commit("changeLocationPopulationData", {
              index: 0,
              value: Math.round(area.Value),
            });
          } else if (area.Category === "SelectionTuristPopulation") {
            commit("changeLocationPopulationData", {
              index: 1,
              value: Math.round(area.Value),
            });
          } else {
            commit("changeLocationData", {
              index,
              value: Math.round(area.Value),
            });
          }
        });
      } else {
        commit("resetLocationData");
      }
      dispatch("setOverviewDataComparison", 0);
    },

    async locationChanged({ dispatch, commit, rootGetters }, geometry) {
      let chartData = false;

      const scenarioInformation = rootGetters["logsum/scenarioInformation"];

      if (scenarioInformation.mode === "compare") {
        dispatch("locationChangedForCompareMode", geometry);
      } else {
        if (geometry.type === ToolType.CIRCLE) {
          const response = await ChartService.getOverviewCircle(geometry);
          chartData = response.data;
        }
        if (geometry.type === ToolType.POLYGON) {
          const response = await ChartService.getOverviewPolygon(geometry);
          chartData = response.data;
        }
        if (chartData && chartData.length > 0) {
          commit("changeDiagramPanelVisibility", true);

          commit("setSelectedAreaInformation", geometry);

          chartData.forEach((area, index) => {
            if (area.Category === "SelectionPopulation") {
              commit("changeLocationPopulationData", {
                index: 0,
                value: Math.round(area.Value),
              });
            } else if (area.Category === "SelectionTuristPopulation") {
              commit("changeLocationPopulationData", {
                index: 1,
                value: Math.round(area.Value),
              });
            } else {
              commit("changeLocationData", {
                index,
                value: Math.round(area.Value),
              });
            }
          });
        } else {
          commit("resetLocationData");
        }
      }
    },

    async locationChangedForCompareMode({ commit, rootGetters }, geometry) {
      const scenario = rootGetters["logsum/scenarioInformation"];

      if (!scenario) return;

      let reference = null;
      let selection = null;

      let promise_sel_from = undefined;
      let promise_sel_to = undefined;

      if (geometry.type === ToolType.CIRCLE) {
        promise_sel_from = await ChartService.getOverviewCircle(
          geometry,
          scenario.compareFromId
        );
        promise_sel_to = await ChartService.getOverviewCircle(
          geometry,
          scenario.compareToId
        );
      } else if (geometry.type === ToolType.POLYGON) {
        promise_sel_from = await ChartService.getOverviewPolygon(
          geometry,
          scenario.compareFromId
        );
        promise_sel_to = await ChartService.getOverviewPolygon(
          geometry,
          scenario.compareToId
        );
      }

      selection = (await promise_sel_to).data;
      reference = (await promise_sel_from).data;

      if (
        reference &&
        reference.length > 0 &&
        selection &&
        selection.length > 0
      ) {
        commit("setSelectedAreaInformation", geometry);
        commit("changeDiagramPanelVisibility", true);

        let populationTo = 0;
        let touristTo = 0;
        let populationFrom = 0;
        let touristFrom = 0;

        let populationDiff = 0;
        let touristDiff = 0;

        selection.forEach((item, index) => {
          if (item.Category === "SelectionPopulation") {
            populationTo = item.Value;
          } else if (item.Category === "SelectionTuristPopulation") {
            touristTo = item.Value;
          } else {
            commit("changeLocationData", {
              index,
              value: Math.round(item.Value),
            });
          }
        });
        reference.forEach((item, index) => {
          if (item.Category === "SelectionPopulation") {
            populationFrom = item.Value;
          } else if (item.Category === "SelectionTuristPopulation") {
            touristFrom = item.Value;
          } else {
            commit("changeLocationDataComparison", {
              index,
              value: Math.round(item.Value),
            });
          }
        });

        if (populationTo && populationFrom) {
          populationDiff = populationTo - populationFrom;
          commit("changeLocationPopulationData", {
            index: 0,
            value: populationDiff,
          });

          commit("setPopulationInformationCompareMode", {
            populationTo,
            populationFrom,
            populationDiff,
          });
        }
        if (touristTo && touristFrom) {
          touristDiff = touristTo - touristFrom;
          commit("changeLocationPopulationData", {
            index: 1,
            value: touristDiff,
          });

          commit("setTouristInformationCompareMode", {
            touristTo,
            touristFrom,
            touristDiff,
          });
        }
      } else {
        commit("resetLocationData");
      }
    },
    async setOverviewDataComparison({ commit }, area) {
      const response = await ChartService.getOverviewPolygon({
        geometry: area,
      });
      const chartData = response.data;
      if (chartData && chartData.length > 0) {
        chartData.forEach((area, index) => {
          commit("changeLocationDataComparison", {
            index,
            value: Math.round(area.Value),
          });
        });
      } else {
        commit("resetLocationData");
      }
    },
    setDiagramPanelVisibility({ commit }, value) {
      commit("changeDiagramPanelVisibility", value);
    },
    setAgeData({ commit }, data) {
      commit("changeAge", data);
    },
    resetSelectedAreaInformation({ commit }) {
      commit("resetSelectedAreaInformation");
    },
  },

  getters: {
    overview: (state) => {
      const clone = JSON.parse(JSON.stringify(state.overview));
      return clone;
    },
    showDiagramPanel: (state) => state.showDiagramPanel,
    overviewPopulation: (state) => state.overviewPopulation,
    overviewSelectedArea: (state) => state.overviewSelectedArea,
    age: (state) => state.age,
    selectedAreaInformation: (state) => state.selectedAreaInformation,
    populationInformationCompareMode: (state) =>
      state.populationInformationCompareMode,
    touristInformationCompareMode: (state) =>
      state.touristInformationCompareMode,
  },
  namespaced: true,
};

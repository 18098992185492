import { defineStore } from "pinia";

export const useIsochroneStore = defineStore("isochroneStore", {
    state: () => ({
        features: [],
        markedFeature: null,
        isochrone: null,
        featuresColor: "#008000",
        accessibilityData: null,
    }),

    getters: {
        getIsochrone: (state) => state.isochrone,
    },
    actions: {
        setIsochron(feature) {
            this.isochrone = feature;
        },
        setColor(color) {
            this.featuresColor = color;
        },
        setMarkedFeature(feature) {
            this.markedFeature = feature;
        },
        addFeature(feature) {
            if (feature && this.features.indexOf(feature) < 0) {
                this.features.push(feature);
                return true;
            }
            return false;
        },
        clearFeatures() {
            this.features = [];
        },
        clearAll() {
            this.features = [];
            this.markedFeature = null;
            this.isochrone = null;
        },
    },
});

<script setup>
import CircleTool from "@/components/maptools/CircleTool.vue";
import PolygonTool from "@/components/maptools/PolygonTool.vue";
import FreeHandTool from "@/components/maptools/FreeHandTool.vue";
import PointTool from "@/components/maptools/PointTool.vue";
import ClearAllTool from "@/components/maptools/ClearAllTool.vue";
import SavedAreaTool from "@/components/maptools/SavedAreaTool.vue";
import AreaTool from "@/components/maptools/AreaTool.vue";
import DarkModeToggle from "@/components/maptools/DarkModeToggle.vue";
import ZoomControl from "@/components/maptools/ZoomControl.vue";
import InformationPopUp from "@/components/general/InformationPopUp.vue";
import { useMapStore } from "@/store/MapStore.js";
import { storeToRefs } from "pinia";
import { onMounted } from "vue";
import { Zoom } from "ol/control";
import Texts from "@/texts/texts.json";

import { resetDrawInteraction } from "@/composable/ol/drawToolInteraction.js";

onMounted(() => {
    const { map } = storeToRefs(useMapStore());

    map.value.addControl(
        new Zoom({
            className: "ol-zoom",
            zoomInTipLabel: "Zooma in",
            zoomOutTipLabel: "Zooma ut",
            target: "zoomContainer",
        })
    );

    document.addEventListener("keyup", (e) => {
        if (e.key == "Escape") {
            resetDrawInteraction();
        }
    });
});
</script>
<template>
    <div id="map-sidebar" class="tools-bar">
        <CircleTool></CircleTool>
        <PolygonTool></PolygonTool>
        <FreeHandTool></FreeHandTool>
        <PointTool></PointTool>
        <AreaTool></AreaTool>
        <SavedAreaTool></SavedAreaTool>
        <ClearAllTool></ClearAllTool>
        <InformationPopUp :text="Texts.helpInformation.no5.text">
        </InformationPopUp>
        <DarkModeToggle></DarkModeToggle>
        <ZoomControl></ZoomControl>
    </div>
</template>
<style lang="scss">
.tools-bar {
    right: 1em;
    z-index: 300;
    background: transparent;
    transition: all 0.4s ease 0s;
    position: fixed;
    display: flex;
    gap: 0.3em;
    flex-direction: column;
    height: 70vh;
    .layer {
        margin-top: 3em;
    }
    .map-tools {
        .menu-btn {
            min-width: 37px;
            background: white;
            box-shadow: #0000003d 0 3px 8px;
            height: 35px;
            border-radius: 6px;
            width: 35px;
            border: none;
            color: #565656;
            cursor: pointer;
            align-items: center;
            display: flex;
            margin-top: 1em;
            justify-content: center;
        }
    }

    .menu-btn:hover,
    .menu-btn.active {
        border: 1px solid #dadada;
        box-shadow: rgb(50 50 93 / 3%) 0px 29px 49px -32px inset,
            rgb(0 0 0 / 23%) -11px 1px 34px -13px inset;
    }
    .menu-btn.active {
        background: rgba(0, 0, 0, 0.85);
        .v-icon {
            color: white;
        }
    }
}
</style>

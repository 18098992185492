import { defineStore } from "pinia";

import markerIconCar from "@/assets/images/icons/directions_car.svg";
import markerIconBicycle from "@/assets/images/icons/directions_bike.svg";
import markerIconWalk from "@/assets/images/icons/directions_walk.svg";
import markerIconTransit from "@/assets/images/icons/directions_bus.svg";

export const useAccessibilityStore = defineStore("AccessibilityStore", {
    state: () => {
        return {
            travelingOptions: {
                type: "car",
                mode: "distance",
                name: "bil",
                selectedIntervalSwipe: 10,
                startTime: 96,
            },
            limitSettings: {
                car: {
                    time: {
                        max: 60,
                        value: 10,
                        text: "Välj tid i minuter",
                        unit: " min",
                    },
                    distance: {
                        max: 120,
                        value: 10,
                        text: "Välj sträcka i km",
                        unit: " km",
                    },
                },
                transit: {
                    time: {
                        max: 120,
                        value: 45,
                        text: "Välj tid i minuter",
                        unit: " min",
                    },
                    distance: {
                        max: 120,
                        value: 45,
                        text: "Välj sträcka i km",
                        unit: " km",
                    },
                },
                walk: {
                    time: {
                        max: 60,
                        value: 20,
                        text: "Välj tid i minuter",
                        unit: " min",
                    },
                    distance: {
                        max: 10,
                        value: 2,
                        text: "Välj sträcka i km",
                        unit: " km",
                    },
                },
                bicycle: {
                    time: {
                        max: 60,
                        value: 60,
                        text: "Välj tid i minuter",
                        unit: " min",
                    },
                    distance: {
                        max: 40,
                        value: 40,
                        text: "Välj sträcka i km",
                        unit: " km",
                    },
                },
            },
        };
    },
    getters: {
        getTravelMode() {
            if (this.travelingOptions.type === "transit") {
                this.travelingOptions.mode = "time";
            }
            this.travelingOptions.selectedIntervalSwipe =
                this.limitSettings[this.travelingOptions.type][
                    this.travelingOptions.mode
                ].value;
            return this.travelingOptions.mode;
        },
        getTravelType() {
            return this.travelingOptions.type;
        },
        getTravelIcon() {
            switch (this.travelingOptions.type) {
                case "car":
                    return markerIconCar;
                case "bicycle":
                    return markerIconBicycle;
                case "walk":
                    return markerIconWalk;
                case "transit":
                    return markerIconTransit;
            }
        },
        getTransitStartTimeInt() {
            return this.travelingOptions.startTime * 5 * 60;
        },
    },
});

import { createApp } from "vue";

import router from "./router/index";
import store from "./store/index";

import "@/assets/styles/project/_mixins.scss";

import "@/assets/styles/project/_library_overrides.scss";
import "@/assets/fonts/_fonts.scss";

import Keycloak from "keycloak-js";
import { setKeycloak } from "./api";
import { useKeycloakStore } from "@/store/KeycloakStore";
import { useUserStore } from "@/store/UserStore";
//Material Icons, Material Design Icons
import "@mdi/font/scss/materialdesignicons.scss";
import { md } from "vuetify/iconsets/md";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import "material-design-icons-iconfont/dist/material-design-icons.css";

// Vuetify
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

import "@/assets/styles/general.scss";
const url =
    window.location.hostname == "regionalanalys.tillvaxtverket.se"
        ? "https://pipos.tillvaxtverket.se/auth"
        : "http://pipos.dev.tillvaxtverket.se/auth";

const initOptions = {
    url: url,
    realm: "Pipos",
    clientId: "regionalanalys",
    onLoad: "login-required",
    redirectUri: `${window.location.origin}${window.location.pathname}`,
};

const keycloak = new Keycloak(initOptions);

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            md,
            mdi,
        },
        iconfont: "md",
    },
});

// Pinia
import { createPinia } from "pinia";
const pinia = createPinia();

import mitt from "mitt";
const emitter = mitt();

import App from "./App.vue";
const app = createApp(App, { keycloak });

keycloak
    .init({ onLoad: initOptions.onLoad })
    .then((auth) => {
        if (!auth) {
            window.location.reload();
        } else {
            console.info("Authenticated");

            app.config.globalProperties.emitter = emitter;
            pinia.use(({ store }) => {
                store.$emitter = app.config.globalProperties.emitter;
            });
            app.use(pinia);
            app.use(store);

            setKeycloak(keycloak);
            const keycloakStore = useKeycloakStore();
            keycloakStore.instance = keycloak;

            const userStore = useUserStore();
            userStore.user = keycloak.idTokenParsed;

            app.use(vuetify);
            app.use(router);

            app.mount("#app");
            if (keycloak.hasResourceRole("regionalanalys", "regionalanalys")) {
                if (window.location.pathname.endsWith("compare")) {
                    router.replace("start/compare");
                } else {
                    router.replace("start");
                }
            } else {
                router.replace("unauthorized");
            }
        }

        // Token Refresh
        setInterval(() => {
            keycloak
                .updateToken(70)
                .then((refreshed) => {
                    if (refreshed) {
                        console.info("Token refreshed" + refreshed);
                    } else {
                        // console.warn(
                        //   "Token not refreshed, valid for " +
                        //     Math.round(
                        //       keycloak.tokenParsed.exp +
                        //         keycloak.timeSkew -
                        //         new Date().getTime() / 1000
                        //     ) +
                        //     " seconds"
                        // );
                    }
                })
                .catch(() => {
                    console.error("Failed to refresh token");
                });
        }, 6000);
    })
    .catch(() => {
        console.error("Authenticated Failed");
        window.location.reload();
    });

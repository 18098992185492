<script setup>
import { onUnmounted } from "vue";
import { useToolStore } from "@/store/ToolStore";
import { storeToRefs } from "pinia";
import { MaIcon as Icon } from "@/utils/maIcons";
import {
    setActivate,
    removeInteraction,
} from "@/composable/ol/drawToolInteraction.js";

const toolConfig = { type: "Polygon", freehand: false };
const includeModify = true;
const toolType = "polygon";
const { getPolygonButtonActive } = storeToRefs(useToolStore());

onUnmounted(() => {
    removeInteraction();
});
</script>
<script>
export default {
    name: "PolygonTool",
};
</script>
<template>
    <div class="map-tools">
        <v-tooltip
            text="Skapa ett område i kartan genom att klicka ut flera punkter till en polygon."
            :max-width="300"
        >
            <template v-slot:activator="{ props }">
                <v-btn
                    v-bind="props"
                    class="menu-btn"
                    :class="getPolygonButtonActive ? 'active' : 'disabled'"
                    @click="setActivate(toolType, toolConfig, includeModify)"
                >
                    <v-icon :icon="Icon['polygon']"></v-icon>
                </v-btn>
            </template>
        </v-tooltip>
    </div>
</template>
<style lang="scss"></style>

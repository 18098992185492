import { defineStore } from "pinia";

export const useKeycloakStore = defineStore({
    id: "KeycloakStore",
    state: () => ({
        instance: null,
    }),
    getters: {},
    actions: {
        logOut() {
            let idx = window.location.pathname.lastIndexOf("/");
            let path = window.location.pathname.slice(0, idx);
            let url = window.location.origin + path + "/start";
            const options = {
                redirectUri: url,
            };
            this.instance.logout(options);
        },
    },
});

class IsochroneWorker {
    constructor() {
        this.worker = new Worker(
            new URL("./internalworker.js", import.meta.url),
            {
                type: "module",
            }
        );
    }

    async init(authToken, basePath) {
        return await this.doWork({
            command: "init",
            payload: { authToken, basePath },
        });
    }

    async loadData() {
        return await this.doWork({ command: "loadData" });
    }

    async generate(settings) {
        return await this.doWork({
            command: "generate",
            payload: {
                settings,
            },
        });
    }

    async generateIsochrone(settings) {
        return await this.doWork({
            command: "generateIsochrone",
            payload: {
                settings,
            },
        });
    }

    async setTargets(targets) {
        return await this.doWork({ command: "setTargets", payload: targets });
    }

    async getNetworks() {
        return await this.doWork({ command: "getNetworks" });
    }

    async getNetwork() {
        return await this.doWork({ command: "getNetwork" });
    }

    async setNetwork(network) {
        return await this.doWork({ command: "setNetwork", payload: network });
    }

    doWork(payload) {
        return new Promise((res, rej) => {
            const channel = new MessageChannel();

            channel.port1.onmessage = ({ data }) => {
                channel.port1.close();
                if (data.error) {
                    rej(data.error);
                } else {
                    res(data.result);
                }
            };

            this.worker.postMessage(payload, [channel.port2]);
        });
    }
}

export default IsochroneWorker;

<script setup>
import { storeToRefs } from "pinia";
import { MaIcon as Icon } from "@/utils/maIcons";
import { useAccessibilityStore } from "@/store/AccessibilityStore";
import { ref } from "vue";
import { computed } from "vue";

const accessStore = useAccessibilityStore();
const { travelingOptions, limitSettings } = storeToRefs(accessStore);

const props = defineProps({
    showPointSettings: {
        type: Boolean,
        required: false,
    },
});

const startTime = ref(96);

const startTimeString = computed(() => {
    const value = startTime.value * 5;
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
});

const changeTransitStartTime = () => {
    accessStore.travelingOptions.startTime = startTime.value;
};

const onKeydown = (e) => e.stopPropagation();
</script>
<script>
export default {
    name: "PointToolSettings",
};
</script>
<template>
    <div class="settings-point">
        <v-card v-show="props.showPointSettings">
            <div v-show="showPointSettings" class="settings">
                <div style="position: absolute"></div>
                <span
                    class="caption-xs"
                    style="display: inline-flex; width: 80px"
                    >Färdsätt</span
                >
                <v-btn-toggle
                    v-model="travelingOptions.type"
                    mandatory
                    density="compact"
                    color="#000"
                >
                    <v-tooltip
                        text="Avstånd mäts med färdsätt Bil"
                        :max-width="300"
                        location="top"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn
                                v-bind="props"
                                @click="
                                    (travelingOptions.type = 'car'),
                                        (travelingOptions.name = 'Bil')
                                "
                                value="car"
                            >
                                <v-icon :icon="Icon['car']" />
                            </v-btn>
                        </template>
                    </v-tooltip>
                    <v-tooltip
                        text="Avstånd mäts med färdsätt Kollektivtrafik (här ingår även Gång för att kunna ta sig mellan hållplatser)"
                        :max-width="300"
                        location="top"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn
                                v-bind="props"
                                @click="
                                    (travelingOptions.type = 'transit'),
                                        (travelingOptions.name =
                                            'Kollektivtrafik'),
                                        (travelingOptions.mode = 'time')
                                "
                                value="transit"
                            >
                                <v-icon :icon="Icon['transit']" />
                            </v-btn>
                        </template>
                    </v-tooltip>
                    <v-tooltip
                        text="Avstånd mäts med färdsätt Cykel"
                        :max-width="300"
                        location="top"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn
                                v-bind="props"
                                @click="
                                    (travelingOptions.type = 'bicycle'),
                                        (travelingOptions.name = 'Cykel')
                                "
                                value="bicycle"
                            >
                                <v-icon :icon="Icon['bicycle']" />
                            </v-btn>
                        </template>
                    </v-tooltip>
                    <v-tooltip
                        text="Avstånd mäts med färdsätt Gång"
                        :max-width="300"
                        location="top"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn
                                v-bind="props"
                                @click="
                                    (travelingOptions.type = 'walk'),
                                        (travelingOptions.name = 'Gång')
                                "
                                value="walk"
                            >
                                <v-icon :icon="Icon['walk']" />
                            </v-btn>
                        </template>
                    </v-tooltip>
                </v-btn-toggle>
                <v-spacer></v-spacer>
                <span
                    class="caption-xs"
                    style="display: inline-flex; width: 80px"
                    >Avståndsmått
                </span>
                <v-btn-toggle
                    class="mt-2"
                    v-model="travelingOptions.mode"
                    mandatory
                    density="compact"
                    color="#000"
                >
                    <v-tooltip
                        text="Avstånd mäts i minuter"
                        :max-width="300"
                        location="bottom"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn value="time" v-bind="props">
                                <v-icon :icon="Icon['time']" />
                            </v-btn>
                        </template>
                    </v-tooltip>
                    <v-tooltip
                        text="Avstånd mäts i kilometer"
                        :max-width="300"
                        location="bottom"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn
                                v-bind="props"
                                value="distance"
                                v-if="travelingOptions.type !== 'transit'"
                            >
                                <v-icon :icon="Icon['distance']" />
                            </v-btn>
                        </template>
                    </v-tooltip>
                </v-btn-toggle>
                <div class="ml-auto" v-if="travelingOptions.type == 'transit'">
                    <span
                        class="caption-xs"
                        style="display: inline-flex; width: 80px"
                        >Starttid för kollektivtrafik</span
                    >
                    <div @keydown.capture="onKeydown">
                        <v-slider
                            dense
                            v-model="startTime"
                            :max="287"
                            :step="1"
                            thumb-size="20"
                            thumb-label="always"
                            class="mt-3"
                            @end="changeTransitStartTime"
                        >
                            <template v-slot:thumb-label="{}">
                                {{ startTimeString }}
                            </template>
                        </v-slider>
                    </div>
                </div>
                <div class="caption-xs pb-3 mt-1">Hur långt kommer man?</div>
                <div>
                    <v-slider
                        dense
                        hide-details
                        thumb-label="always"
                        :thumb-size="20"
                        v-model="travelingOptions.selectedIntervalSwipe"
                        :min="1"
                        :max="
                            limitSettings[travelingOptions.type][
                                travelingOptions.mode
                            ].max
                        "
                        :step="travelingOptions.mode == 'distance' ? 0.1 : 1"
                    >
                        <template v-slot:thumb-label="{ modelValue }">
                            {{
                                `${modelValue}${
                                    limitSettings[travelingOptions.type][
                                        travelingOptions.mode
                                    ].unit
                                }`
                            }}
                        </template>
                    </v-slider>
                </div>
            </div>
        </v-card>
    </div>
</template>
<style lang="scss">
.settings-point {
    position: absolute;
    right: 3em;
    top: 11.3em;
    width: 325px;

    .v-card {
        padding: 0.7em;
    }

    .v-btn {
        min-width: auto;
    }

    .v-slider-thumb__label {
        min-width: max-content;
    }
}
</style>

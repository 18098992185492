import { api } from "@/api";

class SearchService {
  async searchByPattern(pattern) {
    const params = {
        pattern,
        limit: 15,
        groupsize: 5,
    };
    return api.get("api/search/bypattern", { params });
  }

  async getDetails(type, id) {
    const params = {
      type,
      id
    };
    return api.get("api/search/details", { params });
  }
}
export default new SearchService();

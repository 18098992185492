import { createStore } from "vuex";

import overview from "@/store/modules/overview";
import population from "./modules/population";
import map from "@/store/modules/map";
import connector from "@/store/modules/connector";
import options from "@/store/modules/options";
import dashboard from "@/store/modules/dashboard";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    map,
    overview,
    population,
    connector,
    options,
    dashboard,
  },
});

<script setup>
import { MaIcon } from "@/utils/maIcons";
import { useToolStore } from "@/store/ToolStore";
const toolStore = useToolStore();

const removeInteraction = () => {
    toolStore.clearAllTools();
};
</script>
<script>
export default {
    name: "ClearAllTool",
};
</script>
<template>
    <div class="map-tools">
        <v-tooltip text="Släck det aktuella området och avaktivera områdesverktyg" :max-width="300">
            <template v-slot:activator="{ props }">
                <v-btn class="menu-btn" @click="removeInteraction" v-bind="props">
                    <v-icon :icon="MaIcon['close']"></v-icon>
                </v-btn>
            </template>
        </v-tooltip>
    </div>
</template>
<style lang="scss"></style>

<template>
  <div class="spinner">
    <div class="ring-container" :class="{ inverted: getMapMode == 'dark' }">
      <div></div><div></div><div></div><div></div>
    </div>
  </div>
</template>

<script>
import { mapState as mapStateVuex } from "vuex";
import { mapState } from "pinia";
import { useMapStore } from "@/store/MapStore";

export default {
  name: "LoaderSpinner",
  computed: {
    ...mapStateVuex({
      showLoading: (state) => state.showLoading,
    }),
    ...mapState(useMapStore, ['getMapMode']),
  },
};
</script>

<style>
.spinner {
  position: relative;
  width: 88px;
  height: 88px;
  display: inline-block;
  position: fixed;
  z-index: 2000;
  top: 50%;
  left: 50%;
  position: fixed;
  margin: 0 auto;
}

.ring-container {
  display: inline-block;
  position: relative;
  width: 88px;
  height: 88px;
}
.ring-container div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 72px;
  height: 72px;
  margin: 8px;
  border: 8px solid #ccc;
  border-radius: 50%;
  animation: ring-rotation 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ccc transparent transparent transparent;
}
.ring-container {
  &.inverted div {
    border: 8px solid #444;
    border-radius: 50%;
    border-color: #444 transparent transparent transparent;
  }
}
.ring-container div:nth-child(1) {
  animation-delay: -0.45s;
}
.ring-container div:nth-child(2) {
  animation-delay: -0.3s;
}
.ring-container div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes ring-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

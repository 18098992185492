<template>
    <div v-if="mapLoaded">
        <ToolMenu></ToolMenu>
    </div>
    <div id="mapcontainer" ref="mapcontainer" class="errand-color"></div>
    <div v-if="this.showWizard">
        <WizardDialog></WizardDialog>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { mapState } from "pinia";
import "ol/ol.css";
import { Fill, Stroke, Style } from "ol/style";
import Map from "ol/Map";
import View from "ol/View";
import { defaults, ScaleLine } from "ol/control";
import { getWidth } from "ol/extent";
import { MVT, WKT } from "ol/format";
import {
    VectorImage as VectorImageLayer,
    Vector as VectorLayer,
    VectorTile as VectorTileLayer,
    Group,
} from "ol/layer";
import {
    Vector as VectorSource,
    VectorTile as VectorTileSource,
} from "ol/source";

import proj4 from "proj4";
import { get as getProjection } from "ol/proj";
import { register } from "ol/proj/proj4";
import { getLight, getDark } from "@/services/Lantmateriet.js";
import { CalculationHelper } from "@/utils/calculationHelper";

import AttractionService from "@/services/AttractionService";
import WorkerService from "@/services/WorkerService";

import { ToolType } from "@/constants";

import ToolMenu from "@/components/ToolMenu.vue";
import WizardDialog from "@/components/wizard/WizardDialog.vue";

import { useMapStore } from "@/store/MapStore.js";
import { useIsochroneStore } from "@/store/IsochroneStore";
import { useLogsumStore } from "@/store/LogsumStore";
import { useMinasidorSettingsStore } from "@/store/MinasidorSettingsStore";
import { debounce } from "@/utils/debounce";
import { useCategoryStore } from "@/store/CategoryStore";

export default {
    name: "MapContainer",
    components: { ToolMenu, WizardDialog },
    data() {
        return {
            mapLoaded: false,
            comparisonVectorLayerSource: new VectorSource(),
            drawToolLayer: null,
            referenceLayer: null,
            profileDataVectorTileLayer: null,
            accessibilityVectorSource: null,
            logsumLayer: null,
            lmLight: null,
            lmLightText: null,
            lmDark: null,
            lmDarkText: null,
        };
    },

    computed: {
        ...mapGetters("map", [
            "getProfileDataDict",
            "getSelectedProfileData",
            "getComparisonArea",
            "getMap",
        ]),
        ...mapState(useMapStore, [
            "getMapMode",
            "getLogSumLayerOpacity",
            "getPopulationLayerOpacity",
            "getPopulationLayer",
            "getPointLayerCircleSize",
            "getPointLayerOpacity",
            "getColorScale",
        ]),
        ...mapState(useIsochroneStore, ["getIsochrone"]),
        ...mapState(useMinasidorSettingsStore, [
            "getWizardSetting",
            "showWizard",
        ]),
        ...mapState(useCategoryStore, ["getCategoryForTravelReason"]),
    },

    watch: {
        // eslint-disable-next-line vue/no-arrow-functions-in-watch

        getIsochrone(isochrone) {
            if (isochrone) {
                const calculate = CalculationHelper();
                const mapStore = useMapStore();
                const pointSource = mapStore.getPointLayer.getSource();
                const currentFeatures = pointSource.getFeatures();
                const oldIsochrones = currentFeatures.filter(
                    (f) => f.getGeometry().getType() == "MultiPolygon"
                );

                if (oldIsochrones?.length) {
                    oldIsochrones.forEach((element) => {
                        pointSource.removeFeature(element);
                    });
                }
                pointSource.addFeature(isochrone);

                const geometry = isochrone.get("geometry");

                if (
                    geometry.flatCoordinates &&
                    geometry.flatCoordinates.some((x) => isNaN(x))
                ) {
                    return;
                }

                const area = calculate.getAreaFormated(
                    isochrone.get("geometry")
                );

                //kolla arean och lägg till x och y
                const obj = {
                    type: ToolType.POINT,
                    geometry: geometry,
                    properties: {
                        area: area,
                        x: "",
                        y: "",
                    },
                };

                this.updateLocationDebounce(obj);
            }
        },

        getComparisonArea() {
            this.comparisonVectorLayerSource.clear();
            if (this.getComparisonArea.length > 1) {
                const format = new WKT();

                const feature = format.readFeature(this.getComparisonArea, {
                    dataProjection: "EPSG:3006",
                    featureProjection: "EPSG:3006",
                });
                this.comparisonVectorLayerSource.addFeature(feature);
            }
        },
        getMapMode() {
            const mode = this.getMapMode;
            if (mode == "dark") {
                this.lmLight.setVisible(false);
                this.lmLightText.setVisible(false);
                this.lmDark.setVisible(true);
                this.lmDarkText.setVisible(true);
                this.$refs.mapcontainer.style.backgroundColor = "#1e1e1e";
                this.referenceLayer.setStyle(
                    new Style({
                        fill: new Fill({
                            color: "#aaaaaa60",
                        }),
                        stroke: new Stroke({
                            color: "#fff",
                            width: 1,
                        }),
                    })
                );
            }
            if (mode == "light") {
                this.lmLight.setVisible(true);
                this.lmLightText.setVisible(true);
                this.lmDark.setVisible(false);
                this.lmDarkText.setVisible(false);
                this.$refs.mapcontainer.style.backgroundColor = "transparent";
                this.referenceLayer.setStyle(
                    new Style({
                        fill: new Fill({
                            color: "#aaaaaa60",
                        }),
                        stroke: new Stroke({
                            color: "#000",
                            width: 1,
                        }),
                    })
                );
            }
            useMapStore().updateLogsumLayerStyle();
        },
        getLogSumLayerOpacity() {
            const opacity = this.getLogSumLayerOpacity;
            this.logsumLayer.setOpacity(opacity);
        },
        getPopulationLayerOpacity() {
            const opacity = this.getPopulationLayerOpacity;
            this.getPopulationLayer.setOpacity(opacity);
        },
        getPointLayerCircleSize() {
            const size = this.getPointLayerCircleSize;
            this.getMap
                .getLayers()
                .getArray()
                .filter((layer) => layer.get("layerType") == "attraction")
                .forEach((layer) => {
                    layer.updateStyleVariables({ size: size });
                    size < 0.6 ? layer.setZIndex(100) : layer.setZIndex(90);
                });
        },
        getPointLayerOpacity() {
            const opacity = this.getPointLayerOpacity;
            this.getMap
                .getLayers()
                .getArray()
                .filter((layer) => layer.get("layerType") == "attraction")
                .forEach((layer) => {
                    layer.updateStyleVariables({ opacity: opacity });
                });
        },
    },

    methods: {
        ...mapActions("map", [
            "initializeProfileData",
            "setSelectedProfileData",
            "setMap",
        ]),

        updateLocation(obj) {
            const logsumStore = useLogsumStore();
            logsumStore.locationChanged(obj);
        },

        async updateAttractionLayer(errandIds) {
            const layerType = "attraction";
            const map = this.getMap;
            const layers = map
                .getLayers()
                .getArray()
                .filter((layer) => layer.get("layerType") == layerType);

            const mappedErrandIds = layers.map((layer) =>
                layer.get("errandId")
            );
            const layersToRemove = layers.filter(
                (layer) => !errandIds.includes(layer.get("errandId"))
            );
            const errandIdToAdd = errandIds.filter(
                (errandId) => !mappedErrandIds.includes(errandId)
            );

            layersToRemove.forEach((layer) => {
                layer.getSource().clear();
                layer.getRenderer().dispose();
                layer.setSource(null);
                map.removeLayer(layer);
            });

            errandIdToAdd.forEach(async (id) => {
                const attractionVectorLayer =
                    await AttractionService.getPointLayer(id);
                map.addLayer(attractionVectorLayer);
            });
        },

        updateAccessibilityLayer(categories) {
            useMapStore().updateLogsumLayer(categories);
        },
    },

    async mounted() {
        await this.getWizardSetting();

        this.emitter.on("layer-accessibility-change", (event) => {
            this.updateAccessibilityLayer(event);
        });

        WorkerService.init();
        this.emitter.on("layer-attractions-change", async (event) => {
            await this.updateAttractionLayer(event);
        });

        this.initializeProfileData();

        this.updateLocationDebounce = debounce(this.updateLocation, 500);

        proj4.defs(
            "EPSG:3006",
            "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs"
        );
        register(proj4);

        this.profileDataVectorTileLayer = new VectorTileLayer({
            declutter: false,
            visible: false,
            source: new VectorTileSource({
                format: new MVT(),
                url: `${
                    import.meta.env.VITE_API_URL
                }MunicipalityTile/{z}/{x}/{y}.pbf`,
            }),
            style: () =>
                new Style({
                    stroke: new Stroke({
                        color: "black",
                        width: 1,
                    }),
                }),
        });

        const comparisonLayer = new VectorLayer({
            source: new VectorSource(),
            name: "comparisonLayer",
            style: new Style({
                fill: new Fill({
                    color: "#aaaaaa60",
                }),
                stroke: new Stroke({
                    color: "#000",
                    width: 1,
                    lineDash: [4, 4],
                }),
            }),
        });

        this.referenceLayer = new VectorLayer({
            source: new VectorSource(),
            name: "referenceLayer",
            style: new Style({
                fill: new Fill({
                    color: "#aaaaaa60",
                }),
                stroke: new Stroke({
                    color: "#000",
                    width: 1,
                }),
            }),
            zIndex: 101,
        });
        // Tillgänglighetslager
        this.logsumLayer = new VectorImageLayer({
            source: new VectorSource(),
            name: "logsumLayer",
            opacity: this.getLogSumLayerOpacity,
        });

        const styleFunction = function (feature) {
            const color = feature.get("color");
            if (color) {
                return new Style({
                    stroke: new Stroke({
                        color: color,
                        width: 1,
                    }),
                    fill: new Fill({
                        color: color + "50",
                    }),
                });
            }

            if (feature.get("isPreview")) {
                return new Style({
                    stroke: new Stroke({
                        color: "#BC7100",
                        width: 0,
                    }),
                    fill: new Fill({
                        color: "#FF990050",
                    }),
                });
            }

            return new Style({
                stroke: new Stroke({
                    color: "#BC7100",
                    width: 1,
                }),
                fill: new Fill({
                    color: "#FF990050",
                }),
            });
        };
        this.drawToolLayer = new VectorLayer({
            source: new VectorSource(),
            name: "drawToolLayer",
            style: styleFunction,
        });

        this.accessibilityVectorSource = new VectorSource({
            features: [],
        });
        let accessibilityLayer = new VectorImageLayer({
            source: this.accessibilityVectorSource,
            name: "accessibilityLayer",
            style: (feature) => {
                let strokeColor = feature.get("isPreview")
                    ? "#BC710000"
                    : "#BC7100";
                return new Style({
                    stroke: new Stroke({
                        color: strokeColor,
                        width: 0,
                    }),
                    fill: new Fill({
                        color: "#FF990050",
                    }),
                });
            },
        });

        const projection = getProjection("EPSG:3857");
        const projectionExtent = projection.getExtent();
        const size = getWidth(projectionExtent) / 256;
        const resolutions = new Array(19);
        const matrixIds = new Array(19);
        for (let z = 0; z < 19; ++z) {
            resolutions[z] = size / Math.pow(2, z);
            matrixIds[z] = z;
        }

        const { light, lightText } = getLight();
        const { dark, darkText } = getDark();
        this.lmDark = dark;
        this.lmDarkText = darkText;
        this.lmLight = light;
        this.lmLightText = lightText;

        const populationLayer = new VectorImageLayer({
            source: new VectorSource(),
            opacity: this.getPopulationLayerOpacity,
        });

        const map = new Map({
            target: "mapcontainer",
            layers: [
                new Group({
                    layers: [light, lightText, dark, darkText],
                }),
                comparisonLayer,
                this.referenceLayer,
                this.profileDataVectorTileLayer,
                this.logsumLayer,
                this.drawToolLayer,
                populationLayer,
                accessibilityLayer,
                //this.attractionVectorLayer,
            ],
            view: new View({
                projection: "EPSG:3006",
                zoom: 6,
                center: [609924.45, 6877630.37],
                enableRotation: false,
            }),
            controls: defaults({
                zoom: false,
            }),
        });

        const scaleControl = new ScaleLine({
            bar: true,
        });
        map.addControl(scaleControl);
        this.setMap(map);

        const mapStore = useMapStore();
        mapStore.map = map;
        mapStore.populationLayer = populationLayer;
        mapStore.logSumLayer = this.logsumLayer;
        this.mapLoaded = true;
    },
};
</script>

<style lang="scss">
#mapcontainer {
    width: 100%;
    height: calc(100% - 101px);
    position: fixed;

    .reset {
        cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V240c0 8.8-7.2 16-16 16s-16-7.2-16-16V64c0-17.7-14.3-32-32-32s-32 14.3-32 32V336c0 1.5 0 3.1 .1 4.6L67.6 283c-16-15.2-41.3-14.6-56.6 1.4s-14.6 41.3 1.4 56.6L124.8 448c43.1 41.1 100.4 64 160 64H304c97.2 0 176-78.8 176-176V128c0-17.7-14.3-32-32-32s-32 14.3-32 32V240c0 8.8-7.2 16-16 16s-16-7.2-16-16V64c0-17.7-14.3-32-32-32s-32 14.3-32 32V240c0 8.8-7.2 16-16 16s-16-7.2-16-16V32z"/></svg>')
                10 10,
            auto;
    }
}

.ol-control {
    background: none;
}

.ol-control {
    button {
        background: none;
        box-shadow: #0000003d 0 3px 8px;
        height: 35px;
        border-radius: 6px;
        width: 35px;
        background-color: #fff;
        border: none;
        color: #040404;
    }
}
.ol-draw {
    button {
        width: 2em;
        height: 2em;
        padding: 0.2em;
        margin-bottom: 0.5em;
    }
}
button.ol-draw-circle {
    &:before {
        font-family: "Font Awesome 5 Free";
        content: "\f111";
        display: inline-block;
        padding-right: 8px;
        padding-bottom: 1px;
        vertical-align: middle;
        font-weight: 900;
    }
}
button.ol-draw-polygon {
    &:before {
        font-family: "Font Awesome 5 Free";
        content: "\f5ee";
        display: inline-block;
        padding-right: 8px;
        padding-bottom: 1px;
        vertical-align: middle;
        font-weight: 900;
    }
}
button.ol-draw-freehand {
    &:before {
        font-family: "Font Awesome 5 Free";
        content: "\f303";
        display: inline-block;
        padding-right: 8px;
        padding-bottom: 1px;
        vertical-align: middle;
        font-weight: 900;
    }
}
.ol-selector {
    background-color: hsla(0, 0%, 100%, 0.8);
    max-width: 15em;
    left: unset;
    top: 15em;
    right: 0.5em;
    padding-top: 10px;
    padding-left: 5px;
    padding-bottom: 10px;
    padding-right: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.ol-selector input {
    float: left;
    display: block;
}
.ol-selector label {
    position: relative;
    margin-left: 25px;
    display: block;
}

// @keyframes spinner {
//   to {
//     transform: rotate(360deg);
//   }
// }
// .spinner:after {
//   content: "";
//   box-sizing: border-box;
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   width: 40px;
//   height: 40px;
//   margin-top: -20px;
//   margin-left: -20px;
//   border-radius: 50%;
//   border: 5px solid rgba(180, 180, 180, 0.6);
//   border-top-color: rgba(0, 0, 0, 0.6);
//   animation: spinner 0.6s linear infinite;
// }
</style>

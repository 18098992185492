<script setup>
import { ref, computed, toRaw } from "vue";
import { storeToRefs } from "pinia";
import AdminAndSavedAreaSearch from "./AdminAndSavedAreaSearch.vue";

import InformationPopUp from "@/components/general/InformationPopUp.vue";
import Texts from "@/texts/texts.json";

import GeoJSON from "ol/format/GeoJSON";

import { MaIcon as Icon } from "@/utils/maIcons";
import { meterToKm, meter2ToKm2 } from "@/helpers/calculationHelper";
import { zoomTo } from "@/composable/ol/selectInteraction.js";

import { useReportStore } from "@/store/ReportStore";
import { useToolStore } from "@/store/ToolStore";
import { useMinasidorAreaStore } from "@/store/MinasidorAreaStore";
import { useAccessibilityStore } from "@/store/AccessibilityStore";
import { useScenarioInformationStore } from "@/store/ScenarioInformationStore";

const toolStore = useToolStore();
const reportStore = useReportStore();
const minaSidorStore = useMinasidorAreaStore();
const scenarioStore = useScenarioInformationStore();
const { getComparisonName, reportMetadata } = storeToRefs(reportStore);
const { getTravelType } = storeToRefs(useAccessibilityStore());

let areaName = ref("");
const scenarioId = parseInt(scenarioStore.scenarioInformation.scenarioId);
const props = defineProps({
    data: Object,
    comparison: Object,
});

const oneDecimal = (num) => {
    if (num > 3.4e38) return "-";
    return Math.round(num * 10) / 10;
};

const toPercent = (num) => {
    if (num > 3.4e38) return "-";

    return oneDecimal(num * 100) + "%";
};

const toCurrency = (num) => {
    if (num > 3.4e38) return "-";

    return Math.round(num) + " tkr/år";
};

const twoDecimals = (num) => {
    if (num > 3.4e38) return "-";
    const value = (num * 10) / 10;
    return value.toFixed(2);
};

const formatNumber = (num) => {
    if (num > 3.4e38) return "-";
    return num.toLocaleString("se-SE");
};

const squareKmActiveArea = computed(() => {
    if (
        reportStore.getActiveArea != null &&
        Object.hasOwnProperty.call(reportStore.getActiveArea, "area")
    ) {
        return meter2ToKm2(reportStore.getActiveArea.area);
    }
    return "-";
});

const squareKmRefrenceArea = computed(() => {
    if (
        reportStore.comparisonArea != null &&
        Object.hasOwnProperty.call(reportStore.comparisonArea, "area")
    ) {
        return meter2ToKm2(reportStore.comparisonArea.area);
    }
    return "-";
});
const kmActiveArea = computed(() => {
    if (
        reportStore.getActiveArea != null &&
        Object.hasOwnProperty.call(reportStore.getActiveArea, "radius")
    ) {
        return meterToKm(reportStore.getActiveArea.radius);
    }
    return "-";
});

const kmRefrenceArea = computed(() => {
    if (
        reportStore.comparisonArea != null &&
        Object.hasOwnProperty.call(reportStore.comparisonArea, "radius")
    ) {
        return meterToKm(reportStore.comparisonArea.radius);
    }
    return "-";
});

const getSavedName = computed(() => {
    return reportStore.activeArea.name;
});

const saveArea = () => {
    if (areaName.value === "") return;
    let geom = reportStore.activeArea.feature.get("geometry");

    const geoJson = new GeoJSON().writeGeometryObject(toRaw(geom));

    let obj = {
        name: areaName.value,
        geometry: geoJson,
        properties: { Type: toolStore.toolType },
    };
    minaSidorStore.saveArea(obj);
    reportStore.activeArea.name = areaName.value;
    areaName.value = "";
};

const showSaveBtn = computed(() => {
    if (
        (toolStore.toolType === "circle" ||
            toolStore.toolType === "polygon" ||
            toolStore.toolType === "freehand") &&
        getSavedName.value === undefined
    ) {
        return true;
    } else return false;
});

const getTitle = computed(() => {
    let label = "";
    if (getSavedName.value === undefined) {
        label = "Eget område";
    } else {
        label = "Sparat område";
    }

    if (toolStore.toolType === "area") {
        label = getSavedName.value;
    }
    return label;
});

const getAreaName = computed(() => {
    if (toolStore.toolType === "area") {
        return "";
    }

    if (getSavedName.value === undefined) {
        return "";
    } else {
        return getSavedName.value;
    }
});
const kvotLabel = "Fler med samma specialiseringskvot";

const selectionShowKvotBottom = ref(true);
const selectionShowKvotTop = ref(true);
const comparisonShowKvotTop = ref(true);
const comparisonShowKvotbottom = ref(true);

const selectionTopBranches = computed(() => {
    return props.data?.dominantIndustryPercentage.slice(0, 3);
});
const selectionBottomBranches = computed(() => {
    return props.data?.dominantIndustryPercentage.slice(-3);
});
const comparisonTopBranches = computed(() => {
    return props.comparison?.dominantIndustryPercentage.slice(0, 3);
});
const comparisonBottomBranches = computed(() => {
    return props.comparison?.dominantIndustryPercentage.slice(-3);
});

const topBranchSelection = () => {
    const top = selectionTopBranches.value.filter(
        (percentage) => percentage < 3.4e38
    );
    const duplicateElementa = toFindDuplicates(top);
    if (duplicateElementa.length > 0) {
        selectionShowKvotTop.value = false;
        return kvotLabel;
    } else {
        selectionShowKvotTop.value = true;
        return props.data?.dominantIndustry[0];
    }
};

const bottomBranchSelection = () => {
    const bottom = selectionBottomBranches.value.filter(
        (percentage) => percentage < 3.4e38
    );
    const duplicateElementa = toFindDuplicates(bottom);
    if (duplicateElementa.length > 0) {
        selectionShowKvotBottom.value = false;
        return kvotLabel;
    } else {
        selectionShowKvotBottom.value = true;
        return props.data?.dominantIndustry.slice(-1).toString();
    }
};
const topBranchRef = () => {
    const top = comparisonTopBranches.value.filter(
        (percentage) => percentage < 3.4e38
    );
    const duplicateElementa = toFindDuplicates(top);
    if (duplicateElementa.length > 0) {
        comparisonShowKvotTop.value = false;
        return kvotLabel;
    } else {
        comparisonShowKvotTop.value = true;
        return props.comparison?.dominantIndustry[0];
    }
};

const bottomBranchRef = () => {
    const bottom = comparisonBottomBranches.value.filter(
        (percentage) => percentage < 3.4e38
    );
    const duplicateElementa = toFindDuplicates(bottom);
    if (duplicateElementa.length > 0) {
        comparisonShowKvotbottom.value = false;
        return kvotLabel;
    } else {
        comparisonShowKvotbottom.value = true;
        return props.comparison?.dominantIndustry.slice(-1).toString();
    }
};

const toFindDuplicates = (tempList) =>
    tempList.filter((item, index) => tempList.indexOf(item) !== index);
</script>
<script>
export default {
    name: "SelectedAreaStatistics",
};
</script>
<template>
    <div>
        <div class="background1 mb-2 d-flex flex-row" style="width: 506px">
            <div style="width: 57%; padding: 5px; max-width: 220px">
                <div
                    class="caption-l mb-2 d-flex justify-sm-space-between align-top"
                >
                    <div class="info-popup">
                        <div v-html="getTitle" style="max-width: 160px"></div>
                    </div>
                    <div class="d-flex align-self-start flex-row">
                        <InformationPopUp
                            :text="Texts.helpInformation.no6.text"
                        >
                        </InformationPopUp>
                        <v-icon
                            @click="zoomTo('selection')"
                            style="
                                font-size: 20px;
                                cursor: pointer;
                                margin-left: 0.6em;
                            "
                            :icon="Icon['zoomIn']"
                        ></v-icon>
                    </div>
                </div>

                <h4
                    class="caption-l mb-2"
                    style="
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    "
                >
                    {{ getAreaName }}
                </h4>

                <div v-if="showSaveBtn" class="mt-3">
                    <p style="font-size: 12px" class="mb-2">
                        Vill du spara området?
                    </p>
                    <v-text-field
                        v-model="areaName"
                        density="compact"
                        dense
                        variant="solo"
                        label="Välj namn"
                        hide-details="true"
                        :counter="26"
                        :maxlength="26"
                    ></v-text-field>
                    <v-btn
                        style="width: 100%"
                        :disabled="areaName === ''"
                        class="mt-3"
                        @click="saveArea()"
                    >
                        spara
                    </v-btn>
                </div>
            </div>

            <div class="white-box medium px-2">
                <div class="info">
                    <p class="title">Område skapat med</p>
                    <p>
                        <v-icon
                            v-if="toolStore.toolType === 'point'"
                            style="font-size: 20px"
                            :icon="Icon[getTravelType]"
                        ></v-icon>
                        <v-icon
                            v-else
                            style="font-size: 20px"
                            :icon="Icon[toolStore.toolType]"
                        ></v-icon>
                    </p>
                </div>
                <div class="info">
                    <p class="title">Area</p>
                    <p>{{ squareKmActiveArea }} km<sup>2</sup></p>
                </div>
                <div class="info" v-if="toolStore.toolType === 'circle'">
                    <p class="title">Radie</p>
                    <p>{{ kmActiveArea }} km</p>
                </div>
                <div class="info">
                    <p class="title">Folkbokförda</p>
                    <p>
                        <v-tooltip
                            location="top"
                            v-if="
                                scenarioId !== reportMetadata['population'] &&
                                props.data?.population < 3.4e38
                            "
                            :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['population']}.`"
                            :max-width="300"
                        >
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    :size="'small'"
                                    class="text-red"
                                    >mdi-information-outline</v-icon
                                >
                            </template>
                        </v-tooltip>
                        {{ formatNumber(props.data?.population) }}
                    </p>
                </div>
                <div class="info">
                    <p class="title">Sysselsatta</p>
                    <p>
                        <v-tooltip
                            location="top"
                            v-if="
                                scenarioId !== reportMetadata['employed'] &&
                                props.data?.employed < 3.4e38
                            "
                            :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['employed']}.`"
                            :max-width="300"
                        >
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    :size="'small'"
                                    class="text-red"
                                    >mdi-information-outline</v-icon
                                >
                            </template>
                        </v-tooltip>
                        {{ formatNumber(props.data?.employed) }}
                    </p>
                </div>
                <div
                    class="info"
                    style="
                        border-bottom: 1px solid lightgray;
                        padding-bottom: 3px;
                    "
                >
                    <p class="title d-flex">
                        <span class="pr-2"><i>Deltidsboende</i></span>
                        <InformationPopUp
                            :text="Texts.helpInformation.no15.text"
                        ></InformationPopUp>
                    </p>

                    <p>
                        <v-tooltip
                            location="top"
                            v-if="
                                scenarioId !==
                                    reportMetadata['partTimePopulation'] &&
                                props.data?.partTimePopulation < 3.4e38
                            "
                            :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['partTimePopulation']}.`"
                            :max-width="300"
                        >
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    :size="'small'"
                                    class="text-red"
                                    >mdi-information-outline</v-icon
                                >
                            </template>
                        </v-tooltip>
                        {{ formatNumber(props.data?.partTimePopulation) }}
                    </p>
                </div>
                <div class="pt-1">
                    <div class="d-flex justify-space-between align-center">
                        <p class="title" v-bind="props">
                            Högsta specialiseringskvot
                        </p>
                        <InformationPopUp
                            :text="Texts.helpInformation.no17.text"
                        >
                        </InformationPopUp>
                    </div>

                    <div class="d-flex justify-space-between">
                        <p
                            class="text-truncate"
                            :style="{
                                width: selectionShowKvotTop ? '213px' : '260px',
                            }"
                        >
                            {{ topBranchSelection() }}
                        </p>
                        <v-tooltip
                            location="top"
                            v-if="
                                scenarioId !==
                                    reportMetadata[
                                        'dominantIndustryPercentage'
                                    ] &&
                                props.data?.dominantIndustryPercentage < 3.4e38
                            "
                            :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['dominantIndustryPercentage']}.`"
                            :max-width="300"
                        >
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    :size="'small'"
                                    class="text-red"
                                    >mdi-information-outline</v-icon
                                >
                            </template>
                        </v-tooltip>
                        <p v-show="selectionShowKvotTop">
                            {{
                                twoDecimals(
                                    props.data?.dominantIndustryPercentage[0]
                                )
                            }}
                        </p>
                    </div>

                    <div class="d-flex justify-space-between align-center">
                        <p class="title">Lägsta specialiseringskvot</p>
                    </div>
                    <div class="d-flex justify-space-between">
                        <p
                            class="text-truncate"
                            :style="{
                                width: selectionShowKvotBottom
                                    ? '213px'
                                    : '260px',
                            }"
                        >
                            {{ bottomBranchSelection() }}
                        </p>
                        <v-tooltip
                            location="top"
                            v-if="
                                scenarioId !==
                                    reportMetadata[
                                        'dominantIndustryPercentage'
                                    ] &&
                                props.data?.dominantIndustryPercentage < 3.4e38
                            "
                            :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['dominantIndustryPercentage']}.`"
                            :max-width="300"
                        >
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    :size="'small'"
                                    class="text-red"
                                    >mdi-information-outline</v-icon
                                >
                            </template>
                        </v-tooltip>
                        <p v-show="selectionShowKvotBottom">
                            {{
                                twoDecimals(
                                    props.data?.dominantIndustryPercentage.slice(
                                        -1
                                    )
                                )
                            }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="background2 mb-2 d-flex flex-row" style="width: 506px">
            <div style="padding: 5px; width: 57%">
                <h4
                    class="caption-l mb-2 d-flex justify-sm-space-between align-center"
                >
                    Jämförelseområde
                    <InformationPopUp :text="Texts.helpInformation.no20.text">
                    </InformationPopUp>
                    <v-icon
                        @click="zoomTo('compare')"
                        style="font-size: 19px; cursor: pointer"
                        :icon="Icon['zoomIn']"
                    ></v-icon>
                </h4>
                <h4
                    class="caption-l mb-2"
                    style="
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        max-width: 212px;
                    "
                >
                    {{ getComparisonName ?? "Riket" }}
                </h4>

                <AdminAndSavedAreaSearch></AdminAndSavedAreaSearch>
            </div>
            <div
                class="white-box medium px-2"
                style="display: flex; flex-direction: column"
            >
                <div class="info" v-if="reportStore.comparisonArea?.area">
                    <p class="title">Area</p>
                    <p>{{ squareKmRefrenceArea }} km<sup>2</sup></p>
                </div>
                <div class="info" v-if="reportStore.comparisonArea?.radius">
                    <p class="title">Radie</p>
                    <p>{{ kmRefrenceArea }} km</p>
                </div>
                <div class="info">
                    <p class="title">Folkbokförda</p>
                    <p>{{ formatNumber(props.comparison?.population) }}</p>
                </div>
                <div class="info">
                    <p class="title">Sysselsatta</p>
                    <p>
                        <v-tooltip
                            location="top"
                            v-if="
                                scenarioId !== reportMetadata['employed'] &&
                                props.comparison?.employed < 3.4e38
                            "
                            :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['employed']}.`"
                            :max-width="300"
                        >
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    :size="'small'"
                                    class="text-red"
                                    >mdi-information-outline</v-icon
                                >
                            </template>
                        </v-tooltip>
                        {{ formatNumber(props.comparison?.employed) }}
                    </p>
                </div>
                <div
                    class="info"
                    style="
                        border-bottom: 1px solid lightgray;
                        padding-bottom: 3px;
                    "
                >
                    <p class="title d-flex">
                        <span class="pr-2"><i>Deltidsboende</i></span>
                        <InformationPopUp
                            :text="Texts.helpInformation.no15.text"
                        ></InformationPopUp>
                    </p>
                    <p>
                        <v-tooltip
                            location="top"
                            v-if="
                                scenarioId !==
                                    reportMetadata['partTimePopulation'] &&
                                props.comparison?.partTimePopulation < 3.4e38
                            "
                            :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['partTimePopulation']}.`"
                            :max-width="300"
                        >
                            <template v-slot:activator="{ props }">
                                <v-icon
                                    v-bind="props"
                                    :size="'small'"
                                    class="text-red"
                                    >mdi-information-outline</v-icon
                                >
                            </template>
                        </v-tooltip>
                        {{ formatNumber(props.comparison?.partTimePopulation) }}
                    </p>
                </div>
                <div class="pt-1">
                    <div class="d-flex justify-space-between align-center">
                        <p class="title" v-bind="props">
                            Högsta specialiseringskvot
                        </p>
                        <InformationPopUp
                            :text="Texts.helpInformation.no18.text"
                        >
                        </InformationPopUp>
                    </div>

                    <div class="d-flex justify-space-between">
                        <p
                            class="text-truncate"
                            :style="{
                                width: comparisonShowKvotTop
                                    ? '213px'
                                    : '260px',
                            }"
                        >
                            {{ topBranchRef() }}
                        </p>
                        <p v-show="comparisonShowKvotTop">
                            {{
                                twoDecimals(
                                    props.comparison
                                        ?.dominantIndustryPercentage[0]
                                )
                            }}
                        </p>
                    </div>
                    <div class="d-flex justify-space-between align-center">
                        <p class="title">Lägsta specialiseringskvot</p>
                    </div>
                    <div class="d-flex justify-space-between">
                        <p
                            class="text-truncate"
                            :style="{
                                width: comparisonShowKvotbottom
                                    ? '213px'
                                    : '260px',
                            }"
                        >
                            {{ bottomBranchRef() }}
                        </p>
                        <p v-show="comparisonShowKvotbottom">
                            {{
                                twoDecimals(
                                    props.comparison?.dominantIndustryPercentage.slice(
                                        -1
                                    )
                                )
                            }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div
        class="d-flex justify-sm-space-between ml-2 mt-2 mr-1 align-center"
        style="font-weight: 500"
    >
        <span
            style="
                flex: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 390px;
                white-space: nowrap;
            "
            >{{ getSavedName ?? "Eget område" }}</span
        ><span
            style="
                width: 88px;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 88px;
                white-space: nowrap;
            "
            >{{ getComparisonName ?? "Jämförelseområde" }}</span
        >
        <InformationPopUp :text="Texts.helpInformation.no7.text">
        </InformationPopUp>
    </div>
    <div class="grid-container" v-if="props.data && props.comparison">
        <div class="background1"></div>
        <div class="background2"></div>
        <div class="white-box large section1"></div>
        <div class="white-box small section2"></div>
        <div class="white-box large section3"></div>
        <div class="white-box small section4"></div>
        <div class="white-box large section5"></div>
        <div class="white-box small section6"></div>
        <div class="white-box large section7"></div>
        <div class="white-box small section8"></div>
        <div class="white-box large section9"></div>
        <div class="white-box small section10"></div>
        <div class="white-box large section11"></div>
        <div class="white-box small section12"></div>

        <div class="area-info section3">
            <div class="area-entry">
                <h5 class="caption-m">Folkbokförda</h5>
            </div>
            <div class="area-entry">
                <p>Män</p>

                <p>
                    <v-tooltip
                        location="top"
                        v-if="
                            scenarioId !== reportMetadata['men'] &&
                            props.data?.men < 3.4e38
                        "
                        :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['men']}.`"
                        :max-width="300"
                    >
                        <template v-slot:activator="{ props }">
                            <v-icon
                                v-bind="props"
                                :size="'small'"
                                class="text-red"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                    </v-tooltip>
                    {{ toPercent(props.data?.men) }}
                </p>
            </div>
            <div class="area-entry">
                <p>Kvinnor</p>
                <p>
                    <v-tooltip
                        location="top"
                        v-if="
                            scenarioId !== reportMetadata['women'] &&
                            props.data?.women < 3.4e38
                        "
                        :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['women']}.`"
                        :max-width="300"
                    >
                        <template v-slot:activator="{ props }">
                            <v-icon
                                v-bind="props"
                                :size="'small'"
                                class="text-red"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                    </v-tooltip>
                    {{ toPercent(props.data?.women) }}
                </p>
            </div>

            <div class="area-entry">
                <p>Genomsnittsålder</p>
                <p>
                    <v-tooltip
                        location="top"
                        v-if="
                            scenarioId !== reportMetadata['meanAge'] &&
                            props.data?.meanAge < 3.4e38
                        "
                        :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['meanAge']}.`"
                        :max-width="300"
                    >
                        <template v-slot:activator="{ props }">
                            <v-icon
                                v-bind="props"
                                :size="'small'"
                                class="text-red"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                    </v-tooltip>
                    {{ oneDecimal(props.data?.meanAge) }} år
                </p>
            </div>
        </div>
        <div class="compare-info section4">
            <div class="compare-entry">
                <h5 class="caption-m">&nbsp;</h5>
            </div>

            <div class="compare-entry">
                <p>
                    {{ toPercent(props.comparison?.men) }}
                </p>
            </div>
            <div class="compare-entry">
                <p>
                    {{ toPercent(props.comparison?.women) }}
                </p>
            </div>
            <div class="compare-entry">
                <p>{{ oneDecimal(props.comparison?.meanAge) }} år</p>
            </div>
        </div>
        <div class="area-info section5">
            <div class="area-entry">
                <h5 class="caption-m">Kommunikationer</h5>
            </div>
            <div class="area-entry">
                <p>Hushåll med fordon</p>
                <p>
                    <v-tooltip
                        location="top"
                        v-if="
                            scenarioId !==
                                reportMetadata['householdWithWehicle'] &&
                            props.data?.householdWithWehicle < 3.4e38
                        "
                        :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['householdWithWehicle']}.`"
                        :max-width="300"
                    >
                        <template v-slot:activator="{ props }">
                            <v-icon
                                v-bind="props"
                                :size="'small'"
                                class="text-red"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                    </v-tooltip>
                    {{ toPercent(props.data?.householdWithWehicle) }}
                </p>
            </div>
            <div class="area-entry">
                <p>Sysselsatta som pendlar längre än 10 km</p>
                <p>
                    <v-tooltip
                        location="top"
                        v-if="
                            scenarioId !== reportMetadata['commuters10k'] &&
                            props.data?.commuters10k < 3.4e38
                        "
                        :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['commuters10k']}.`"
                        :max-width="300"
                    >
                        <template v-slot:activator="{ props }">
                            <v-icon
                                v-bind="props"
                                :size="'small'"
                                class="text-red"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                    </v-tooltip>
                    {{ toPercent(props.data?.commuters10k) }}
                </p>
            </div>
        </div>
        <div class="compare-info section6">
            <div class="compare-entry">
                <h5 class="caption-m">&nbsp;</h5>
            </div>
            <div class="compare-entry">
                <p>{{ toPercent(props.comparison?.householdWithWehicle) }}</p>
            </div>
            <div class="compare-entry">
                <p>{{ toPercent(props.comparison?.commuters10k) }}</p>
            </div>
        </div>
        <div class="area-info section7">
            <div class="area-entry">
                <h5 class="caption-m">Ekonomi</h5>
            </div>
            <div class="area-entry">
                <p>Individers disponibla inkomst</p>
                <p>
                    <v-tooltip
                        location="top"
                        v-if="
                            scenarioId !== reportMetadata['disposableIncome'] &&
                            props.data?.disposableIncome < 3.4e38
                        "
                        :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['disposableIncome']}.`"
                        :max-width="300"
                    >
                        <template v-slot:activator="{ props }">
                            <v-icon
                                v-bind="props"
                                :size="'small'"
                                class="text-red"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                    </v-tooltip>
                    {{ toCurrency(props.data?.disposableIncome) }}
                </p>
            </div>
        </div>
        <div class="compare-info section8">
            <div class="compare-entry">
                <h5 class="caption-m">&nbsp;</h5>
            </div>
            <div class="compare-entry">
                <p>
                    {{ toCurrency(props.comparison?.disposableIncome) }}
                </p>
            </div>
        </div>

        <div class="area-info section9">
            <div class="area-entry">
                <h5 class="caption-m">Bostäder</h5>
            </div>
            <div class="area-entry">
                <p>Andel personer som bor i äganderätt</p>
                <p>
                    <v-tooltip
                        location="top"
                        v-if="
                            scenarioId !== reportMetadata['homeOwnership'] &&
                            props.data?.homeOwnership < 3.4e38
                        "
                        :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['homeOwnership']}.`"
                        :max-width="300"
                    >
                        <template v-slot:activator="{ props }">
                            <v-icon
                                v-bind="props"
                                :size="'small'"
                                class="text-red"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                    </v-tooltip>
                    {{ toPercent(props.data?.homeOwnership) }}
                </p>
            </div>
            <div class="area-entry">
                <p>Andel personer som bor i bostadsrätt</p>
                <p>
                    <v-tooltip
                        location="top"
                        v-if="
                            scenarioId !==
                                reportMetadata['homeResidentialCondominium'] &&
                            props.data?.homeResidentialCondominium < 3.4e38
                        "
                        :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['homeResidentialCondominium']}.`"
                        :max-width="300"
                    >
                        <template v-slot:activator="{ props }">
                            <v-icon
                                v-bind="props"
                                :size="'small'"
                                class="text-red"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                    </v-tooltip>
                    {{ toPercent(props.data?.homeResidentialCondominium) }}
                </p>
            </div>
            <div class="area-entry mb-3">
                <p>Andel personer som bor i hyresrätt</p>
                <p>
                    <v-tooltip
                        location="top"
                        v-if="
                            scenarioId !==
                                reportMetadata['homeResidentialTenancy'] &&
                            props.data?.homeResidentialTenancy < 3.4e38
                        "
                        :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['homeResidentialTenancy']}.`"
                        :max-width="300"
                    >
                        <template v-slot:activator="{ props }">
                            <v-icon
                                v-bind="props"
                                :size="'small'"
                                class="text-red"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                    </v-tooltip>
                    {{ toPercent(props.data?.homeResidentialTenancy) }}
                </p>
            </div>

            <div class="area-entry">
                <p>Fastighetspris villa</p>
                <p>
                    <v-tooltip
                        location="top"
                        v-if="
                            scenarioId !==
                                reportMetadata['propertyPriceHouse'] &&
                            props.data?.propertyPriceHouse < 3.4e38
                        "
                        :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['propertyPriceHouse']}.`"
                        :max-width="300"
                    >
                        <template v-slot:activator="{ props }">
                            <v-icon
                                v-bind="props"
                                :size="'small'"
                                class="text-red"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                    </v-tooltip>
                    {{
                        props.data?.propertyPriceHouse === 0
                            ? "-"
                            : Math.round(
                                  props.data?.propertyPriceHouse
                              ).toLocaleString("se-SE") + " kr/m²"
                    }}
                </p>
            </div>
            <div class="area-entry">
                <p>Fastighetspris bostadsätt</p>
                <p>
                    <v-tooltip
                        location="top"
                        v-if="
                            scenarioId !==
                                reportMetadata[
                                    'propertyPriceResidentialCondominium'
                                ] &&
                            props.data?.propertyPriceResidentialCondominium <
                                3.4e38
                        "
                        :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['propertyPriceResidentialCondominium']}.`"
                        :max-width="300"
                    >
                        <template v-slot:activator="{ props }">
                            <v-icon
                                v-bind="props"
                                :size="'small'"
                                class="text-red"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                    </v-tooltip>
                    {{
                        props.data?.propertyPriceResidentialCondominium === 0
                            ? "-"
                            : Math.round(
                                  props.data
                                      ?.propertyPriceResidentialCondominium
                              ).toLocaleString("se-SE") + "kr/m²"
                    }}
                </p>
            </div>
        </div>
        <div class="compare-info section10">
            <div class="compare-entry">
                <h5 class="caption-m">&nbsp;</h5>
            </div>
            <div class="compare-entry">
                <p>{{ toPercent(props.comparison?.homeOwnership) }}</p>
            </div>
            <div class="compare-entry">
                <p>
                    {{
                        toPercent(props.comparison?.homeResidentialCondominium)
                    }}
                </p>
            </div>
            <div class="compare-entry mb-3">
                <p>
                    {{ toPercent(props.comparison?.homeResidentialTenancy) }}
                </p>
            </div>
            <div class="compare-entry">
                <p>
                    {{
                        props.comparison?.propertyPriceHouse === 0
                            ? "-"
                            : Math.round(
                                  props.comparison?.propertyPriceHouse
                              ).toLocaleString("se-SE") + " kr/m²"
                    }}
                </p>
            </div>
            <div class="compare-entry">
                <p>
                    {{
                        props.comparison
                            ?.propertyPriceResidentialCondominium === 0
                            ? "-"
                            : Math.round(
                                  props.comparison
                                      ?.propertyPriceResidentialCondominium
                              ).toLocaleString("se-SE") + " kr/m²"
                    }}
                </p>
            </div>
        </div>

        <div class="area-info section11">
            <div class="area-entry">
                <h5 class="caption-m">Utbildning</h5>
            </div>
            <div class="area-entry">
                <p>Eftergymnasial utbildning minst 3 år</p>
                <p>
                    <v-tooltip
                        v-if="
                            scenarioId !== reportMetadata['highEducation'] &&
                            props.data?.highEducation < 3.4e38
                        "
                        :text="`Data saknas för nuvarande scenario. Denna information är simulerad med data från ${reportMetadata['highEducation']}.`"
                        :max-width="300"
                    >
                        <template v-slot:activator="{ props }">
                            <v-icon
                                v-bind="props"
                                :size="'small'"
                                class="text-red"
                                >mdi-information-outline</v-icon
                            >
                        </template>
                    </v-tooltip>
                    {{ toPercent(props.data?.highEducation) }}
                </p>
            </div>
        </div>
        <div class="compare-info section12">
            <div class="compare-entry">
                <h5 class="caption-m">&nbsp;</h5>
            </div>
            <div class="compare-entry">
                <p>{{ toPercent(props.comparison?.highEducation) }}</p>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
p {
    font-size: 14px;
    line-height: 1.7em;
}
.info {
    display: flex;
    justify-content: space-between;
}
.info-popup {
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 5px;
}
.title {
    font-weight: 500;
}
.text-truncate {
    width: 213px;
}
.grid-container {
    display: grid;
    grid-template-columns: 293px 102px 110px;
    grid-template-rows: auto;
    grid-column-gap: 2px;
    grid-row-gap: 2px;
    grid-template-areas:
        "l1 l1 r1"
        "l2 l2 r2"
        "l3 l3 r3"
        "l4 l4 r4"
        "l5 l5 r5"
        "l6 l6 r6";
}

.background1 {
    grid-area: 1 / 1 / 8 / 3;
    background-color: #f7d7a7;
    // border: 2px #f3d98c solid;
    border-radius: 5px;
}

.background2 {
    grid-area: 1 / 3 / 8 / 3;
    background-color: #ebebeb;
    border-radius: 5px;
    // border: 2px dashed #414040;

    .v-field.v-field--appended {
        --v-field-padding-end: 0;
    }
    .v-field__clearable {
        margin-inline-end: -6px;
    }
    .mdi-close-circle {
        font-size: 1.1em;
    }
}

.white-box {
    background-color: white;
    border-radius: 5px;
}

.large {
    width: 389px;
    height: auto;
    margin: 5px;
}

.small {
    width: 100px;
    height: auto;
    margin: 5px;
}

.medium {
    width: 356px;
    height: auto;
    margin: 5px;
}
.section1 {
    grid-area: l1;
}

.section2 {
    grid-area: r1;
}
.section3 {
    grid-area: l2;
}
.section4 {
    grid-area: r2;
}
.section5 {
    grid-area: l3;
}
.section6 {
    grid-area: r3;
}

.section7 {
    grid-area: l4;
}
.section8 {
    grid-area: r4;
}
.section9 {
    grid-area: l5;
}
.section10 {
    grid-area: r5;
}
.section11 {
    grid-area: l6;
}
.section12 {
    grid-area: r6;
}
.area-info {
    display: flex;
    flex-direction: column;
    padding: 0.8em;
}
.area-entry {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.compare-info {
    display: flex;
    flex-direction: column;
    padding: 0.8em;
}

.compare-entry {
    width: 100%;
    display: flex;
    justify-content: space-between;
}
</style>

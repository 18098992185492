export const useBasePath = () => {
    let basePath = document.location.origin;
    let subPath = import.meta.env.VITE_BASE_URL ?? "";
    const subPathFromDocument = document.querySelector('meta[name="base-url"]');

    if (
        subPathFromDocument &&
        subPathFromDocument.content &&
        subPathFromDocument.content != "PLACEHOLDER_VUE_ROUTER_BASE_PATH"
    ) {
        subPath = subPathFromDocument.content;
    }

    basePath += subPath;

    return { basePath, subPath };
};

<template>
    <div class="categories-navbar">
        <div v-for="item in categories" :key="item.id">
            <v-menu
                :v-model="item.id"
                :close-on-content-click="false"
                location="bottom"
                :content-class="['categories-menu']"
            >
                <template v-slot:activator="{ props }">
                    <v-btn
                        :variant="
                            item.forms.some(
                                (form) => form.checkedPoint || form.checkedArea
                            )
                                ? 'text'
                                : 'outlined'
                        "
                        v-bind="props"
                        class="serviceTypeBtn mt-1"
                        v-bind:class="item.id"
                    >
                        <v-icon large class="mr-1" :icon="Icon[item.id]">
                        </v-icon>
                        {{ item.name }}
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item class="first">
                        <v-row>
                            <v-col cols="2" class="checkIconPoint">
                                <v-btn
                                    @click="
                                        formOnClick('all', item, 'checkedPoint')
                                    "
                                    :class="{
                                        active:
                                            item.forms.length ===
                                            item.forms.filter(
                                                (obj) =>
                                                    obj.checkedPoint === true
                                            ).length,
                                    }"
                                >
                                    <v-icon>mdi-checkbox-blank-circle</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="2" class="checkIconArea">
                                <v-btn
                                    @click="
                                        formOnClick('all', item, 'checkedArea')
                                    "
                                    :class="{
                                        active:
                                            item.forms.length ===
                                            item.forms.filter(
                                                (obj) =>
                                                    obj.checkedArea === true
                                            ).length,
                                    }"
                                >
                                    <v-icon>mdi-waves</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="8">
                                <div class="d-flex align-center categoryLabel">
                                    <v-tooltip
                                        text="Väljer alla reseanledningar i denna kategori"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <label v-bind="props"> Alla </label>
                                        </template>
                                    </v-tooltip>
                                </div>
                            </v-col>
                        </v-row>
                    </v-list-item>

                    <v-list-item v-for="form in item.forms" :key="form.id">
                        <v-row>
                            <v-col cols="2" class="checkIconPoint">
                                <v-btn
                                    :class="{ active: form.checkedPoint }"
                                    @click="
                                        formOnClick(
                                            'form',
                                            form,
                                            'checkedPoint'
                                        )
                                    "
                                >
                                    <v-icon>mdi-checkbox-blank-circle</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="2" class="checkIconArea">
                                <v-btn
                                    :class="{
                                        active: form.checkedArea,
                                    }"
                                    @click="
                                        formOnClick('form', form, 'checkedArea')
                                    "
                                >
                                    <v-icon>mdi-waves</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="8">
                                <div class="d-flex align-center categoryLabel">
                                    <v-tooltip :text="form.mouseOverText">
                                        <template v-slot:activator="{ props }">
                                            <label v-bind="props">
                                                {{ form.name }}
                                            </label>
                                        </template>
                                    </v-tooltip>
                                    <v-tooltip
                                        v-if="form.otherYear"
                                        :text="`Data saknas för nuvarande scenario. Denna reseanledning är simulerad med data från ${form.otherYear}.`"
                                        :max-width="300"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-icon
                                                v-bind="props"
                                                :size="'small'"
                                                class="text-red"
                                                >mdi-information-outline</v-icon
                                            >
                                        </template>
                                    </v-tooltip>
                                </div>
                            </v-col>
                        </v-row>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>

        <InformationPopUp
            :text="Texts.helpInformation.no1.text"
            location="bottom"
        >
        </InformationPopUp>

        <MapSettings />

        <InformationPopUp
            :text="Texts.helpInformation.no2.text"
            location="bottom"
        >
        </InformationPopUp>

        <div class="ml-auto d-flex">
            <CalculationSettings />
            <InformationPopUp
                :text="Texts.helpInformation.no3.text"
                class="mr-2"
                location="bottom"
            >
            </InformationPopUp>
            <PopulationMenu></PopulationMenu>
            <InformationPopUp
                :text="Texts.helpInformation.no4.text"
                class="ml-2"
                location="bottom"
            >
            </InformationPopUp>
        </div>
    </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import Texts from "@/texts/texts.json";
import { useCategoryStore } from "@/store/CategoryStore.js";
import { getCurrentInstance } from "vue";
import { MaIcon as Icon } from "@/utils/maIcons";
import MapSettings from "@/components/navigation/MapSettings.vue";
import PopulationMenu from "@/components/navigation/PopulationMenu.vue";
import InformationPopUp from "@/components/general/InformationPopUp.vue";
import CalculationSettings from "./CalculationSettings.vue";

const categoryStore = useCategoryStore();
const { categories } = storeToRefs(categoryStore);
const internalInstance = getCurrentInstance();
const emitter = internalInstance.appContext.config.globalProperties.emitter;

const formOnClick = (type, item, pointArea) => {
    if (type === "all") {
        toggleAll(item, pointArea);
    }
    if (type === "form") {
        toggleThis(item, pointArea);
    }
};

const toggleThis = (form, pointArea) => {
    form[pointArea] = !form[pointArea];
    updateMap();
};

const toggleAll = (item, pointArea) => {
    let formCount = item.forms.length;
    let formCheckedCount = item.forms.filter(
        (obj) => obj[pointArea] === true
    ).length;

    if (formCount === formCheckedCount) {
        item.forms.forEach((item) => {
            item[pointArea] = false;
        });
    } else {
        item.forms.forEach((item) => {
            item[pointArea] = true;
        });
    }
    updateMap();
};

const updateMap = () => {
    let attractionIds = [];
    let accessibilityIds = [];
    categories.value.forEach((category) => {
        category.forms.forEach((form) => {
            if (form.checkedPoint === true) {
                attractionIds.push(form.id);
            }
            if (form.checkedArea === true) {
                accessibilityIds.push(form.id);
            }
        });
    });
    emitter.emit("layer-attractions-change", [...attractionIds].sort());
    emitter.emit("layer-accessibility-change", [...accessibilityIds].sort());
};
</script>

<style lang="scss">
.categories-navbar {
    display: flex;
    gap: 10px;
    padding: 6px 15px 6px 15px;
    border-bottom: 1px #bbb solid;
    flex-direction: row;
    flex-wrap: wrap;
    position: sticky;
    top: 50px;
}

.first {
    .v-list-item__content {
        border-bottom: 1px solid #c6c6c6;
        padding-bottom: 0.7em;
    }
}

.on {
    .v-switch__track {
        background-color: #000;
    }
}

.off {
    .v-switch__track {
        background-color: gray;
    }
}

.v-btn--variant-text.serviceTypeBtn {
    color: rgb(240 240 240);
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 8px;

    &.workplace {
        background-color: $workplace !important;
    }
    &.education {
        background-color: $education !important;
    }
    &.culture {
        background-color: $culture !important;
    }
    &.public_service {
        background-color: $public_service !important;
    }
    &.recreation {
        background-color: $recreation !important;
    }
    &.commercial_service {
        background-color: $commercial_service !important;
    }
    &.healthcare {
        background-color: $healthcare !important;
    }
}

.v-btn--variant-outlined.serviceTypeBtn {
    color: rgb(240 240 240);
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 8px;

    &.workplace {
        color: $workplace !important;
    }
    &.education {
        color: $education !important;
    }
    &.culture {
        color: $culture !important;
    }
    &.public_service {
        color: $public_service !important;
    }
    &.recreation {
        color: $recreation !important;
    }
    &.commercial_service {
        color: $commercial_service !important;
    }
    &.healthcare {
        color: $healthcare !important;
    }
}

.checkIconPoint {
    color: #7a7a7a;

    i {
        &.checked {
            color: #0060df;
        }
    }

    .v-btn {
        min-width: 30px;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.2392156863) 0 3px 3px;
        height: 30px !important;
        border-radius: 6px;
        width: 30px !important;
        border: none;
        color: #565656;
        cursor: pointer;

        &.active {
            background: #000000;
            color: white;
        }
    }
}

.checkIconArea {
    color: #7a7a7a;

    i {
        &.checked {
            color: #0060df;
        }
    }

    .v-btn {
        min-width: 30px;
        background: white;
        box-shadow: rgba(0, 0, 0, 0.2392156863) 0px 1px 4px;
        height: 30px !important;
        border-radius: 6px;
        width: 30px !important;
        border: none;
        color: #565656;
        cursor: pointer;

        &.active {
            background: #000000;
            color: white;
        }
    }
}

.categoryLabel {
    white-space: nowrap;
    padding-top: 5px;
    gap: 5px;
}

.categories-menu {
    .v-list-item {
        padding: 0 16px !important;
    }
    .v-list-item__content {
        padding: 0;
        margin: 0;
        padding-bottom: 5px;
        padding-left: 5px;
    }

    .v-col-2 {
        max-width: 45px;
    }
}
</style>

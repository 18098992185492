<template>
    <v-menu :close-on-content-click="false" :content-class="'populationMenu'">
        <template v-slot:activator="{ props }">
            <v-btn
                variant="tonal"
                v-bind="props"
                class="mt-1 populationBtn"
                :class="{ active: filtered }"
            >
                <template v-slot:prepend>
                    <v-icon
                        :icon="
                            filtered
                                ? Icon['population']
                                : Icon['populationEmpty']
                        "
                    ></v-icon>
                </template>

                Population
            </v-btn>
        </template>

        <v-list>
            <v-list-item class="first">
                <v-row>
                    <v-col>
                        <v-checkbox
                            @click="toggleAll"
                            :density="'compact'"
                            label="Alla populationer"
                            v-model="allChecked"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-list-item>

            <v-list-item
                :density="'compact'"
                v-for="(item, i) in items"
                :key="i"
            >
                <v-row>
                    <v-col :cols="10">
                        <v-checkbox
                            v-model="item.checked"
                            :density="'compact'"
                            :label="item.name"
                            :class="item.name"
                        ></v-checkbox>
                    </v-col>
                    <v-col :cols="2">
                        <InformationPopUp
                            v-if="item.id === 7"
                            :text="Texts.helpInformation.no15.text"
                            location="top"
                        >
                        </InformationPopUp>
                    </v-col>
                </v-row>
            </v-list-item>

            <v-list-item class="last">
                <v-row class="pt-7">
                    <v-label>Genomskinlighet</v-label>
                </v-row>

                <v-row>
                    <v-col
                        class="d-flex align-center flex-row justify-space-between pl-0 pr-0"
                    >
                        <v-slider
                            dense
                            hide-details
                            v-model="populationLayerOpacity"
                            :max="100"
                            :step="1"
                            thumb-label
                            thumb-size="20"
                            :disabled="!filtered"
                        ></v-slider>
                        <InformationPopUp
                            :text="Texts.helpInformation.no16.text"
                            location="top"
                        >
                        </InformationPopUp>
                    </v-col>
                </v-row>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script setup>
import { MaIcon as Icon } from "@/utils/maIcons";
import { watch } from "vue";
import { ref, computed } from "vue";
import { useMapStore } from "@/store/MapStore.js";
import { storeToRefs } from "pinia";

import Texts from "@/texts/texts.json";
import InformationPopUp from "@/components/general/InformationPopUp.vue";

const mapStore = useMapStore();
const { populationLayerOpacity } = storeToRefs(useMapStore());

let allChecked = ref(false);
let items = ref([
    {
        id: 2,
        name: "Folkbokförda",
        checked: false,
        value: "p_total",
    },
    {
        id: 8,
        name: "Sysselsatta ",
        checked: false,
        value: "w_total",
    },
    {
        id: 7,
        name: "Deltidsboende ",
        checked: false,
        value: "t_total",
    },
]);

const filtered = computed(() => {
    return items.value.filter((item) => item.checked).length > 0;
});

const toggleAll = () => {
    let formCount = items.value.length;
    let formCheckedCount = items.value.filter(
        (obj) => obj.checked === true
    ).length;

    if (formCount === formCheckedCount) {
        items.value.forEach((item) => {
            item.checked = false;
        });
    } else {
        items.value.forEach((item) => {
            item.checked = true;
        });
    }
};

watch(
    () => items.value,
    () => {
        if (items.value.filter((item) => !item.checked).length > 0) {
            allChecked.value = false;
        } else {
            allChecked.value = true;
        }
        updateMap();
    },
    { deep: true }
);

const updateMap = () => {
    mapStore.updatePopulationLayer(
        items.value.filter((item) => item.checked).map((item) => item.value)
    );
};
</script>

<style lang="scss">
.v-btn.populationBtn {
    font-size: 12px;

    background-color: gray;
    color: white;
}
.populationBtn.active {
    background-color: black;
    color: white;
}
.populationMenu .v-list-item__content :not(.last) {
    max-height: 55px !important;
}
.populationMenu .last .v-list-item__content {
    margin-top: 10px !important;
    border-top: 1px solid #c6c6c6;
    padding-bottom: 20px !important;
    padding-right: 14px !important;
    padding-left: 20px !important;
}

.populationMenu {
    label {
        min-width: 200px !important;
        font-size: 14px;
        opacity: 1;
    }

    .Deltidsboende {
        .v-label {
            font-style: italic;
        }
    }
    .v-list-item__content {
        overflow: visible;
    }
}
</style>

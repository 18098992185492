import { defineStore } from "pinia";
import MinasidorSettingsService from "@/services/MinasidorSettingsService";
export const useMinasidorSettingsStore = defineStore({
    id: "MinasidorSettingsStore",
    state: () => ({
        settings: {
            application: "serviceanalys",
            properties: {
                wizard: "hide",
            },
        },
    }),
    actions: {
        async getWizardSetting() {
            try {
                const response = await MinasidorSettingsService.get();
                if (!response.status === 200) return this.settings;
                this.settings = response.data;

                if (!this.settings?.properties?.wizard) {
                    this.settings.properties.wizard = "show";
                }
            } catch (error) {
                console.log(error);
                return error;
            }
        },
        async saveWizardSetting(showWizard) {
            await this.getWizardSetting();
            this.settings.properties.wizard = showWizard ? "hide" : "show";

            try {
                await MinasidorSettingsService.save(this.settings);
            } catch (error) {
                console.log(error);
                return error;
            }
        },
    },
    getters: {
        showWizard: (state) => state.settings.properties.wizard === "show",
    },
});

<script setup>
import { onUnmounted } from "vue";
import { storeToRefs } from "pinia";
import AreaPanel from "@/components/maptools/AreaPanel.vue";
import { MaIcon } from "@/utils/maIcons";
import {
    setTool,
    removeInteraction,
} from "@/composable/ol/drawToolInteraction.js";
import { useToolStore } from "@/store/ToolStore";

const toolStore = useToolStore();
const { getAreaActive } = storeToRefs(toolStore);

const setActivate = async () => {
    await toolStore.clearAllTools();

    setTool("area");
};

onUnmounted(() => {
    removeInteraction;
});
</script>
<script>
export default {
    name: "AreaTool",
};
</script>
<template>
    <div class="map-tools">
        <v-tooltip text="Välj en tätort, en kommun eller ett län som då dyker upp som ett område i kartan" :max-width="300">
            <template v-slot:activator="{ props }">
                <v-btn
                    v-bind="props"
                    class="menu-btn"
                    :class="getAreaActive ? 'active' : 'disabled'"
                    @click="setActivate()"
                >
                    <v-icon :icon="MaIcon['areaTool']"></v-icon>
                </v-btn>
            </template>
        </v-tooltip>
    </div>
    <AreaPanel :showAreaPanel="getAreaActive"></AreaPanel>
</template>
<style lang="scss"></style>

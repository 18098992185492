<template>
    <v-menu :close-on-content-click="false" location="bottom" :width="450">
        <template v-slot:activator="{ props }">
            <v-btn
                variant="outlined"
                v-bind="props"
                class="mt-1 mr-3 text-none"
            >
                <div
                    class="d-flex justify-space-between"
                    :style="{ minWidth: '175px' }"
                >
                    <v-icon v-if="logSumModel === 1">mdi-routes-clock</v-icon>
                    <v-icon v-else>mdi-account-clock</v-icon>
                    <div>
                        <v-icon v-if="logSumTravelModes.includes('car')"
                            >md:directions_car</v-icon
                        >
                        <v-icon v-if="logSumTravelModes.includes('bus')"
                            >md:directions_bus</v-icon
                        >
                        <v-icon v-if="logSumTravelModes.includes('bike')"
                            >md:directions_bike</v-icon
                        >
                        <v-icon v-if="logSumTravelModes.includes('walk')"
                            >md:directions_walk</v-icon
                        >
                    </div>
                    <div>
                        Ändra
                        <v-icon class="ml-auto">
                            {{
                                props["aria-expanded"] == "true"
                                    ? "mdi-chevron-up"
                                    : "mdi-chevron-down"
                            }}
                        </v-icon>
                    </div>
                </div>
            </v-btn>
        </template>
        <v-list>
            <v-list-item class="first">
                <v-row> <v-col>Välj beräkningsmodell </v-col></v-row>
                <v-row
                    class="mt-0 d-flex align-center"
                    :style="{ minWidth: '300px' }"
                >
                    <v-col :style="{ textAlign: 'center' }" cols="5">
                        <v-skeleton-loader
                            v-if="logsumStore.loadingModelData"
                            max-width="250"
                            type="button"
                        ></v-skeleton-loader>

                        <v-btn
                            v-else
                            :variant="logSumModel === 1 ? 'text' : 'outlined'"
                            :class="logSumModel === 1 ? 'active' : ''"
                            @click="changeLogSumModel(1)"
                        >
                            Restidsmodell
                            <v-icon class="ml-2">mdi-routes-clock</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col :style="{ textAlign: 'center' }" cols="5">
                        <v-skeleton-loader
                            v-if="logsumStore.loadingModelData"
                            max-width="250"
                            type="button"
                        ></v-skeleton-loader>
                        <v-btn
                            v-else
                            variant="outlined"
                            :class="logSumModel === 2 ? 'active' : ''"
                            @click="changeLogSumModel(2)"
                        >
                            Beteendemodell
                            <v-icon class="ml-2">mdi-account-clock</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="2">
                        <InformationPopUp
                            :text="Texts.helpInformation.no13.text"
                            location="top"
                        >
                        </InformationPopUp>
                    </v-col>
                </v-row>
            </v-list-item>
            <v-list-item>
                <v-row> <v-col>Välj färdsätt </v-col></v-row>
                <v-row
                    class="mt-0 d-flex align-center"
                    :style="{ minWidth: '300px' }"
                >
                    <v-col cols="10">
                        <v-skeleton-loader
                            v-if="logsumStore.loadingModelData"
                            type="button"
                            class="toggle-btn"
                        ></v-skeleton-loader>
                        <v-btn-toggle
                            v-else
                            v-model="logSumTravelModes"
                            mandatory
                            density="compact"
                            color="#000"
                            multiple
                        >
                            <v-btn value="car">
                                <v-icon>md:directions_car</v-icon>
                            </v-btn>

                            <v-btn value="bus">
                                <v-icon>md:directions_bus</v-icon>
                            </v-btn>

                            <v-btn value="bike">
                                <v-icon>md:directions_bike</v-icon>
                            </v-btn>

                            <v-btn value="walk">
                                <v-icon>md:directions_walk</v-icon>
                            </v-btn>
                        </v-btn-toggle></v-col
                    >
                    <v-col cols="2">
                        <InformationPopUp
                            :text="Texts.helpInformation.no14.text"
                            location="top"
                        >
                        </InformationPopUp>
                    </v-col>
                </v-row>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script setup>
import { useCategoryStore } from "@/store/CategoryStore";
import { useLogsumStore } from "@/store/LogsumStore";
import { storeToRefs } from "pinia";
import { getCurrentInstance } from "vue";
import { watch } from "vue";

import Texts from "@/texts/texts.json";
import InformationPopUp from "@/components/general/InformationPopUp.vue";

const internalInstance = getCurrentInstance();
const emitter = internalInstance.appContext.config.globalProperties.emitter;

const categoryStore = useCategoryStore();
const logsumStore = useLogsumStore();

const { logSumModel, categories, logSumTravelModes } =
    storeToRefs(categoryStore);

const changeLogSumModel = (model) => {
    logSumModel.value = model;
};

watch(
    () => [logSumTravelModes.value, logSumModel.value],
    () => {
        let accessibilityIds = [];
        categories.value.forEach((category) => {
            category.forms.forEach((form) => {
                if (form.checkedArea === true) {
                    accessibilityIds.push(form.id);
                }
            });
        });
        emitter.emit(
            "layer-accessibility-change",
            [...accessibilityIds].sort()
        );
    }
);
</script>

<style lang="scss" scoped>
.v-btn {
    text-transform: none;
    &.active {
        background-color: #333;
        color: #fff;
        border: 5px solid #333;
    }
}
</style>

import { defineStore } from "pinia";
import { useMapStore } from "@/store/MapStore";
import { useReportStore } from "@/store/ReportStore";
import { ToolType } from "@/constants";
import { useLogsumStore } from "@/store/LogsumStore";
import { storeToRefs } from "pinia";
import { useIsochroneStore } from "./IsochroneStore";

export const useToolStore = defineStore("ToolStore", {
    state: () => ({
        toolType: "",
        tools: [
            { type: "circle", isActive: false },
            { type: "polygon", isActive: false },
            { type: "freehand", isActive: false },
            { type: "point", isActive: false },
            { type: "upload", isActive: false },
            { type: "area", isActive: false },
        ],
        drawInteraction: null,
        modifyInteraction: null,
        translateInteraction: null,
        source: null,
        pointSource: null,
        referenceSource: null,
    }),
    actions: {
        clearAllTools() {
            const reportStore = useReportStore();
            const isochroneStore = useIsochroneStore();
            const logsumStore = useLogsumStore();

            const { getMap } = useMapStore();
            const { reportVisible } = storeToRefs(reportStore);
            reportVisible.value = false;
            isochroneStore.setMarkedFeature(null);
            for (let i = 0; i < this.tools.length; i++) {
                this.tools[i].isActive = false;
            }
            if (this.drawInteraction != null) {
                getMap.removeInteraction(this.drawInteraction);
                this.drawInteraction = null;
            }
            if (this.modifyInteraction != null) {
                getMap.removeInteraction(this.modifyInteraction);
                this.modifyInteraction = null;
            }
            if (this.translateInteraction != null) {
                getMap.removeInteraction(this.translateInteraction);
                this.translateInteraction = null;
            }
            if (this.source != null) {
                this.source.clear();
            }
            if (this.pointSource != null) {
                this.pointSource.clear();
            }
            if (this.referenceSource != null) {
                this.referenceSource.clear();
            }
            this.freeHandSketchIsOnMap = false;

            logsumStore.selectedComparison = null;
            reportStore.comparisonArea = null;
            reportStore.statisticsComparison = null;
            reportStore.comparisonName = "Riket";
        },
        setActiveArea(feature, savedArea = null) {
            const reportStore = useReportStore();
            const logsumStore = useLogsumStore();

            if (this.getCircleActive) {
                const geometry = feature.getGeometry();
                const area = geometry.getArea();

                reportStore.activeArea = {
                    feature: feature,
                    toolType: ToolType.CIRCLE,
                    area: area,
                    radius: savedArea?.radius,
                };

                const obj = {
                    type: ToolType.CIRCLE,
                    geometry: geometry,
                    area: area,
                    radius: false,
                };
                logsumStore.locationChanged(obj);
                const { reportVisible } = storeToRefs(reportStore);
                reportVisible.value = true;
            }
            if (this.getPolygonActive) {
                const geometry = feature.getGeometry();
                const area = geometry.getArea();

                reportStore.activeArea = {
                    feature: feature,
                    toolType: ToolType.POLYGON,
                    area: area,
                };

                const obj = {
                    type: ToolType.POLYGON,
                    geometry: geometry,
                    area: area,
                    radius: false,
                };
                logsumStore.locationChanged(obj);
                const { reportVisible } = storeToRefs(reportStore);
                reportVisible.value = true;
            }
            if (this.getFreehandActive) {
                const geometry = feature.getGeometry();
                const area = geometry.getArea();

                reportStore.activeArea = {
                    feature: feature,
                    toolType: ToolType.POLYGON,
                    area: area,
                };

                const obj = {
                    type: ToolType.POLYGON,
                    geometry: geometry,
                    area: area,
                    radius: false,
                };
                logsumStore.locationChanged(obj);
                const { reportVisible } = storeToRefs(reportStore);
                reportVisible.value = true;
            }
            if (this.getPointActive) {
                const { reportVisible } = storeToRefs(reportStore);
                if (feature == null) {
                    reportVisible.value = false;
                    return;
                }
                const geometry = feature.getGeometry();
                const area = geometry.getArea();
                reportStore.activeArea = {
                    feature: feature,
                    toolType: ToolType.POINT,
                    area: area,
                };

                reportVisible.value = true;
            }
            if (this.getSavedAreaActive) {
                const geometry = feature.getGeometry();
                const area = geometry.getArea();

                reportStore.activeArea = {
                    feature: feature,
                    toolType: ToolType.UPLOAD,
                    area: area,
                    name: savedArea?.name,
                };
                const obj = {
                    type: ToolType.POLYGON,
                    geometry: geometry,
                    area: area,
                    radius: false,
                };
                logsumStore.locationChanged(obj);
                const { reportVisible } = storeToRefs(reportStore);
                reportVisible.value = true;
            }
            if (this.getAreaActive) {
                let polygon = feature.getGeometry();
                const geometry = feature.getGeometry();
                const area = geometry.getArea();
                let type = "Polygon";
                if (
                    Array.isArray(polygon) &&
                    polygon.length > 0 &&
                    Array.isArray(polygon[0]) &&
                    polygon[0].length > 0 &&
                    Array.isArray(polygon[0][0]) &&
                    polygon[0][0].length > 0 &&
                    Array.isArray(polygon[0][0][0])
                ) {
                    type = "MultiPolygon";
                }
                reportStore.activeArea = {
                    feature,
                    toolType: ToolType.AREA,
                    area: area,
                    name: savedArea.name,
                    geometry: { coordinates: polygon, type: type },
                    properties: feature.properties,
                };
                const obj = {
                    type: type,
                    geometry: geometry,
                    area: area,
                    radius: false,
                };
                logsumStore.locationChanged(obj);
                const { reportVisible } = storeToRefs(reportStore);
                reportVisible.value = true;
            }
        },
    },
    getters: {
        getCircleActive: (state) => state.tools[0].isActive,
        getPolygonActive: (state) => state.tools[1].isActive,
        getFreehandActive: (state) => state.tools[2].isActive,
        getPointActive: (state) => state.tools[3].isActive,
        getSavedAreaActive: (state) => state.tools[4].isActive,
        getAreaActive: (state) => state.tools[5].isActive,
        getCircleButtonActive: (state) =>
            state.tools[0].isActive &&
            state.drawInteraction &&
            state.drawInteraction.getActive(),
        getPolygonButtonActive: (state) =>
            state.tools[1].isActive &&
            state.drawInteraction &&
            state.drawInteraction.getActive(),
        getFreehandButtonActive: (state) =>
            state.tools[2].isActive &&
            state.drawInteraction &&
            state.drawInteraction.getActive(),
        getPointButtonActive: (state) =>
            state.tools[3].isActive &&
            state.drawInteraction &&
            state.drawInteraction.getActive(),
    },
});

import PopulationService from "@/services/PopulationService";
import GeoJSON from "ol/format/GeoJSON";
import { Fill, Style, Stroke } from "ol/style.js";
import { DEVICE_PIXEL_RATIO } from "ol/has.js";
import * as d3 from "d3";
import { defineStore } from "pinia";
import LogsumService from "@/services/LogsumService";
import { useCategoryStore } from "@/store/CategoryStore";
import { createStripedPattern } from "@/utils/canvasOperations";

export const useMapStore = defineStore({
    id: "MapStore",
    state: () => ({
        mapMode: "light",
        map: {},
        logSumLayer: null,
        logSumLayerOpacity: 60,
        logSumThreshold: [0, 1],
        accessibilityData: null,
        populationLayer: null,
        searchGeojson: null,
        populationLayerOpacity: 60,
        pointLayerCircleSize: 4,
        pointLayerOpacity: 100,
        colorScale: {
            dark: [
                "#002F6177",
                "#00768D66",
                "#00B5A166",
                "#61E97E66",
                "#B2EC3899",
            ],
            light: [
                "#00cc9c40",
                "#42E18B70",
                "#7FF16FAA",
                "#BFFB4A90",
                "#FFFF00AA",
            ],
        },
    }),
    actions: {
        reRenderSearchLayer() {
            if (!this.searchGeojson) return;
            let features = new GeoJSON().readFeatures(this.searchGeojson);

            let layer = null;
            this.map.getLayers().forEach((l) => {
                if (l && l.get("name") === "searchLayer") {
                    layer = l;
                }
            });

            const source = layer.getSource();
            source.clear();
            source.addFeatures(features);
            layer.setStyle(
                () =>
                    new Style({
                        stroke: new Stroke({
                            color: this.mapMode === "light" ? "#888" : "#fff",
                            width: 4,
                            lineDash: [4, 8],
                        }),
                    })
            );
        },
        async updatePopulationLayer(populationSettings) {
            const vectorSource = this.populationLayer.getSource();

            if (!populationSettings.length) {
                vectorSource.clear();
                return;
            }

            const categories = populationSettings.join(",");
            const response = await PopulationService.getLayer(categories);

            // TODO: Temp fix: https://github.com/openlayers/openlayers/issues/15385
            let cleanedUp = response.data.features.filter(
                (x) =>
                    x.geometry &&
                    x.geometry.coordinates &&
                    x.geometry.coordinates.length > 0
            );
            response.data.features = cleanedUp;

            let features = new GeoJSON().readFeatures(response.data);
            let weights = features.map((x) => +x.get("total"));
            const scale = d3
                .scaleLinear()
                .domain([0, features.length - 1])
                .range(["#fc723420", "#800026ff"]);
            const colors = d3.range(features.length).map((x) => scale(x));
            const colorScale = {};
            weights.forEach((x, i) => (colorScale[x] = colors[i]));
            vectorSource.clear();
            vectorSource.addFeatures(features);
            this.populationLayer.setStyle(
                (feature) =>
                    new Style({
                        fill: new Fill({
                            color: colorScale[+feature.get("total")],
                        }),
                    })
            );
        },
        async updateLogsumLayer(ids) {
            const vectorSource = this.logSumLayer.getSource();

            if (!ids.length) {
                vectorSource.clear();
                return;
            }
            const categoryStore = useCategoryStore();
            const meansOfTransport = categoryStore.getLogSumTravelModes;
            const response = await LogsumService.getLayer(
                ids,
                meansOfTransport,
                this.logSumThreshold[0],
                this.logSumThreshold[1],
                categoryStore.getLogSumModel
            );
            const data = response.data.features.filter(
                (x) =>
                    x.geometry &&
                    x.geometry.coordinates &&
                    x.geometry.coordinates.length > 0
            );
            response.data.features = data;
            let features = new GeoJSON().readFeatures(response.data);
            let weights = features.sort(
                (a, b) => a.get("total") - b.get("total")
            );
            weights.forEach((x) => x.set("order", weights.indexOf(x)));

            vectorSource.clear();
            vectorSource.addFeatures(weights);
            this.updateLogsumLayerStyle();
        },
        updateLogsumLayerStyle() {
            this.logSumLayer.setStyle((feature) => {
                if (feature.get("order") == 5) {
                    let canvas = document.createElement("canvas");
                    canvas.width = 2 * DEVICE_PIXEL_RATIO;
                    canvas.height = 2 * DEVICE_PIXEL_RATIO;
                    const color = createStripedPattern(
                        canvas,
                        1,
                        6,
                        1,
                        "rgba(0,0,0,0.5)"
                    );
                    canvas.remove();

                    return new Style({
                        fill: new Fill({
                            color: color,
                        }),
                    });
                }
                return new Style({
                    fill: new Fill({
                        color: this.getColorScale[+feature.get("order")],
                    }),
                });
            });
        },
    },

    getters: {
        getAccessibilityData: (state) => state.accessibilityData,
        getMapMode: (state) => state.mapMode,
        getLogSumLayerOpacity: (state) => state.logSumLayerOpacity / 100,
        getLogSumThreshold: (state) => state.logSumThreshold,
        getPopulationLayerOpacity: (state) =>
            state.populationLayerOpacity / 100,
        getPopulationLayer: (state) => state.populationLayer,
        getMap: (state) => state.map,
        getDrawLayer: (state) => {
            let layer = null;
            state.map.getLayers().forEach((l) => {
                if (l && l.get("name") === "drawToolLayer") {
                    layer = l;
                }
            });
            return layer;
        },
        getPointLayer: (state) => {
            let layer = null;
            state.map.getLayers().forEach((l) => {
                if (l && l.get("name") === "accessibilityLayer") {
                    layer = l;
                }
            });
            return layer;
        },
        getReferenceLayer: (state) => {
            let layer = null;
            state.map.getLayers().forEach((l) => {
                if (l && l.get("name") === "referenceLayer") {
                    layer = l;
                }
            });
            return layer;
        },
        getPointLayerCircleSize: (state) =>
            0.5 + state.pointLayerCircleSize * 0.1,
        getPointLayerOpacity: (state) => state.pointLayerOpacity / 100,
        getColorScale: (state) => state.colorScale[state.mapMode],
    },
});

import { defineStore } from "pinia";
import LogsumService from "@/services/LogsumService";
import { useScenarioInformationStore } from "@/store/ScenarioInformationStore";
import { useCategoryStore } from "@/store/CategoryStore";

const mapLogsums = (data) => {
    const categoryStore = useCategoryStore();
    const { categories } = categoryStore;
    let errands = [];
    for (let i = 0; i < categories.length; i++) {
        const categoryForms = categories[i].forms;
        for (let j = 0; j < categoryForms.length; j++) {
            const form = categoryForms[j];
            const index = data.travelReasons.findIndex(
                (e) => e.travelReason === form.id
            );
            if (index !== -1) {
                const mapped = {
                    name: form.id,
                    value: data.travelReasons[index].value,
                    index: data.travelReasons[index].index,
                    category: categories[i].name,
                };
                errands.push(mapped);
            }
        }
    }
    return errands;
};

export const useLogsumStore = defineStore({
    id: "LogsumStore",
    state: () => ({
        _selectionLogsums: [],
        _comparisonLogsums: [],
        _swedenLogsums: [],
        logsumSelection: [],
        selectedComparison: null,
        location: null,
        totalAggregate: 0,
        errandsList: [],
        loadingModelData: false,
    }),
    actions: {
        async initializeLogsumStore() {
            const scenarioStore = useScenarioInformationStore();
            const categoryStore = useCategoryStore();

            const response = await LogsumService.getPolygon(
                this.selectedComparison,
                scenarioStore.scenarioInformation.scenarioId,
                categoryStore.getLogSumTravelModes,
                categoryStore.getLogSumModel
            );

            if (response && response.data) {
                const errands = mapLogsums(response.data);
                this._swedenLogsums = errands;
            }
        },
        async locationChanged(location) {
            this.location = location;
            const scenarioStore = useScenarioInformationStore();
            const categoryStore = useCategoryStore();

            const response = await LogsumService.getPolygon(
                location,
                scenarioStore.scenarioInformation.scenarioId,
                categoryStore.getLogSumTravelModes,
                categoryStore.getLogSumModel
            );

            if (response && response.data) {
                const errands = mapLogsums(response.data);
                this._selectionLogsums = errands;
            }
        },
        async comparisonChanged(location) {
            this.selectedComparison = location;
            const scenarioStore = useScenarioInformationStore();
            const categoryStore = useCategoryStore();

            if (location === null) {
                this._comparisonLogsums = null;
                return;
            }

            const response = await LogsumService.getPolygon(
                location,
                scenarioStore.scenarioInformation.scenarioId,
                categoryStore.getLogSumTravelModes,
                categoryStore.getLogSumModel
            );

            if (response && response.data) {
                const errands = mapLogsums(response.data);
                this._comparisonLogsums = errands;
            }
        },
        async calculationChanged() {
            this.loadingModelData = true;

            const scenarioStore = useScenarioInformationStore();
            const categoryStore = useCategoryStore();

            const requests = [
                LogsumService.getPolygon(
                    this.selectedComparison,
                    scenarioStore.scenarioInformation.scenarioId,
                    categoryStore.getLogSumTravelModes,
                    categoryStore.getLogSumModel
                ),
                LogsumService.getPolygon(
                    this.location,
                    scenarioStore.scenarioInformation.scenarioId,
                    categoryStore.getLogSumTravelModes,
                    categoryStore.getLogSumModel
                ),
            ];

            Promise.allSettled(requests).then((results) => {
                const responseComparison = results[0];
                const responseSelection = results[1];

                if (
                    responseComparison.status === "fulfilled" &&
                    responseComparison.value.data
                ) {
                    const errands = mapLogsums(responseComparison.value.data);
                    if (this.selectedComparison == null) {
                        this._swedenLogsums = errands;
                    }
                    this._comparisonLogsums = errands;
                }

                if (
                    responseSelection.status === "fulfilled" &&
                    responseSelection.value.data
                ) {
                    const errands = mapLogsums(responseSelection.value.data);
                    this._selectionLogsums = errands;
                }
                this.loadingModelData = false;
            });
        },
        setSelectedComparison(geometry) {
            this.selectedComparison = geometry;
        },
        logsumSelectionChange(selection) {
            this.logsumSelection = selection;
        },
    },
    getters: {
        sunburstAdjusted: (state) => {
            const clone = JSON.parse(JSON.stringify(state._selectionLogsums));
            return clone;
        },
        getSelectionLogsums: (state) => {
            return state._selectionLogsums;
        },
        getComparisonLogsums: (state) => {
            return state._comparisonLogsums;
        },
        getSwedenLogsums: (state) => {
            return state._swedenLogsums;
        },
    },
});

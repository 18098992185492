<script setup>
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { onClickOutside } from "@vueuse/core";
import { MaIcon as Icon } from "@/utils/maIcons";
import { useScenarioInformationStore } from "@/store/ScenarioInformationStore";
import { useUserStore } from "@/store/UserStore";
import MenuLinks from "@/components/navigation/MenuLinks.vue";

const router = useRoute();
const scenarioStore = useScenarioInformationStore();
const { scenarioInformation } = storeToRefs(scenarioStore);

const menu = ref(null);
const menuIcon = ref(null);
let isHidden = ref(true);

onClickOutside(
    menu,
    () => {
        if (isHidden.value === false) {
            isHidden.value = true;
        }
    },
    {
        ignore: [menuIcon],
    }
);
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
</script>
<script>
export default {
    name: "TopNavigation",
};
</script>
<template>
    <v-row no-gutters class="top-bar" :class="scenarioInformation.mode">
        <v-col cols="2" sm="1" style="display: flex">
            <v-sheet v-if="router.name === 'Start'">
                <a href="https://pipos.se/" target="_blank">
                    <div class="pipos-logga"></div>
                </a>
            </v-sheet>
            <v-sheet class="pa-1 ma-1" v-if="router.name !== 'Start'">
                <router-link to="/start"
                    ><v-icon :icon="Icon['home']"> </v-icon
                ></router-link>
            </v-sheet>
        </v-col>
        <v-col>
            <v-sheet class="pa-1 ma-1" v-if="router.name !== 'Start'">
                <h4 class="header-text" style="">
                    {{
                        scenarioInformation.mode === "single"
                            ? `Regionalanalys - Scenario ${scenarioInformation.singleId}`
                            : `Jämför scenario ${scenarioInformation.compareFromId} med scenario ${scenarioInformation.compareToId} `
                    }}
                </h4>
            </v-sheet>
        </v-col>
        <v-col
            sm="3"
            md="4"
            lg="2"
            style="justify-content: right; display: flex"
        >
            <v-sheet
                class="pa-1 ma-1"
                style="display: flex; align-items: center; justify-content: end"
            >
                <div
                    class="pr-2"
                    style="
                        display: inline-flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                    "
                >
                    <v-icon :icon="Icon['user']"></v-icon>
                    <span>{{ user.name }}</span>
                </div>
                <v-icon
                    @click="isHidden = !isHidden"
                    :icon="Icon['menu']"
                    ref="menuIcon"
                ></v-icon>
            </v-sheet>
        </v-col>
    </v-row>

    <MenuLinks v-show="!isHidden" ref="menu"></MenuLinks>
</template>
<style lang="scss">
.header-text {
    font-size: 1.3em;
    text-align: center;
    padding: 0;
    margin: 0;
    font-weight: 400;
}

.pipos-logga {
    background-image: url("@/assets/images/pipos_logga.png");
    width: 99px;
    height: 48px;
    background-repeat: no-repeat;
    margin-left: 10px;
    z-index: 100;
}
.top-bar {
    box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
    position: sticky;
    top: 0px;
    height: 50px;
    z-index: 100;
}
</style>

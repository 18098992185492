import { storeToRefs } from "pinia";
import { Draw, Translate, Modify } from "ol/interaction";
import { fromCircle } from "ol/geom/Polygon";
import Feature from "ol/Feature";
import { Style, Text, Icon } from "ol/style";

import { useAccessibilityStore } from "@/store/AccessibilityStore";
import { useIsochroneStore } from "@/store/IsochroneStore";
import { useMapStore } from "@/store/MapStore";
import { useToolStore } from "@/store/ToolStore";

import { ToolType } from "@/constants";

export const setTool = (tool) => {
    const toolStore = useToolStore();
    toolStore.toolType = tool;
    let isActive = false;

    for (let i = 0; i < toolStore.tools.length; i++) {
        if (toolStore.tools[i].type === tool) {
            toolStore.tools[i].isActive = !toolStore.tools[i].isActive;
            isActive = toolStore.tools[i].isActive;
        } else {
            toolStore.tools[i].isActive = false;
        }
    }
    return isActive;
};

const _drawStart = () => {
    const { modifyInteraction, translateInteraction } = storeToRefs(useToolStore());
    const mapStore = useMapStore();
    const drawSource = mapStore.getDrawLayer.getSource();
    if (modifyInteraction.value) {
        mapStore.getMap.removeInteraction(modifyInteraction.value);
        modifyInteraction.value = null;
    }
    if (translateInteraction.value) {
        mapStore.getMap.removeInteraction(translateInteraction.value);
        translateInteraction.value = null;
    }
    drawSource.clear();
};

const _interactionEvents = (includeModify, toolConfig) => {
    const { drawInteraction, modifyInteraction, translateInteraction } = storeToRefs(useToolStore());
    const mapStore = useMapStore();
    const toolStore = useToolStore();
    const drawSource = mapStore.getDrawLayer.getSource();
    const pointSource = mapStore.getPointLayer.getSource();
    const isochroneStore = useIsochroneStore();

    if (toolConfig.type.toLowerCase() === ToolType.POINT.toLowerCase()) {
        drawInteraction.value.on("drawstart", async () => {
            _drawStart();
            isochroneStore.isochroneGenerated = false;
            pointSource.clear();
        });

        drawInteraction.value.on("drawend", async (event) => {
            const { getTravelIcon } = storeToRefs(useAccessibilityStore());
            event.feature.setStyle(
                new Style({
                    image: new Icon({
                        scale: 1.2,
                        crossOrigin: "anonymous",
                        src: getTravelIcon.value,
                    }),
                })
            );

            isochroneStore.setMarkedFeature(event.feature);

            drawInteraction.value.setActive(false);
        });
    } else {
        drawInteraction.value.on("drawstart", async () => {
            _drawStart();
        });

        drawInteraction.value.on("drawend", async (event) => {
            if (toolStore.toolType == ToolType.CIRCLE.toLowerCase()) {
                const geometry = event.feature.getGeometry();

                const radius = geometry.getRadius();
                const areaInformation = {
                    radius,
                };

                let polygon = fromCircle(event.feature.get("geometry"), 64);
                const feature = new Feature(polygon);
                toolStore.setActiveArea(feature, areaInformation);
            } else if (toolStore.toolType == ToolType.FREEHAND.toLowerCase()) {
                const drawFeature = event.feature;

                if (drawFeature.getGeometry().getFlatCoordinates().length < 10) {
                    const source = mapStore.getDrawLayer.getSource();
                    source.clear();
                    return;
                }

                toolStore.setActiveArea(drawFeature);
            } else {
                toolStore.setActiveArea(event.feature);
            }
            
            drawInteraction.value.setActive(false);

            if (includeModify) {
                modifyInteraction.value = new Modify({
                    source: drawSource,
                    style: new Style({
                        text: new Text({
                            text: String.fromCodePoint(0xf01be),
                            font: 'normal normal normal 24px/1 "Material Design Icons"',
                        }),
                    }),
                });

                modifyInteraction.value.on("modifyend", (e) => {
                    if (toolStore.toolType == ToolType.CIRCLE.toLowerCase()) {
                        const geometry = event.feature.getGeometry();
                        const radius = geometry.getRadius();
                        const areaInformation = {
                            radius,
                        };

                        let polygon = fromCircle(
                            event.feature.get("geometry"),
                            64
                        );

                        const feature = new Feature(polygon);
                        toolStore.setActiveArea(feature, areaInformation);
                    } else {
                        toolStore.setActiveArea(e.features.getArray()[0]);
                    }
                });

                translateInteraction.value = new Translate({
                    layers: [mapStore.getDrawLayer],
                });

                translateInteraction.value.on("translateend", (e) => {
                    if (toolStore.toolType == ToolType.CIRCLE.toLowerCase()) {
                        const geometry = event.feature.getGeometry();
                        const radius = geometry.getRadius();
                        const areaInformation = {
                            radius,
                        };

                        let polygon = fromCircle(
                            event.feature.get("geometry"),
                            64
                        );

                        const feature = new Feature(polygon);
                        toolStore.setActiveArea(feature, areaInformation);
                    } else {
                        toolStore.setActiveArea(e.features.getArray()[0]);
                    }
                });

                mapStore.getMap.addInteraction(modifyInteraction.value);
                mapStore.getMap.addInteraction(translateInteraction.value);
            }
        });
    }
};

export const setActivate = (
    toolType,
    toolConfig,
    includeModify,
    source = null
) => {
    removeInteraction();
    const isActive = setTool(toolType);
    if (isActive) {
        _createDrawInteraction(toolConfig, source);
        _interactionEvents(includeModify, toolConfig);
    }
};

export const removeInteraction = () => {
    const toolStore = useToolStore();
    toolStore.clearAllTools();
};

export const resetDrawInteraction = () => {
    const toolStore = useToolStore();

    if (toolStore.toolType === "circle" || toolStore.toolType === "polygon") {
        const mapStore = useMapStore();

        mapStore.getMap.removeInteraction(toolStore.drawInteraction);
        mapStore.getMap.addInteraction(toolStore.drawInteraction);
    }
};
const _createDrawInteraction = (type, source) => {
    const mapStore = useMapStore();
    const toolStore = useToolStore();

    if (source == null) {
        source = mapStore.getDrawLayer.getSource();
    }

    //Remove property freehand from type if it exists, since freehand:true overrides freehandCondition
    let freehand = false;
    let drawType = {};
    if (type.freehand) {
        drawType = { type: 'Polygon' }
        freehand = true;
    } else {    
        drawType = {...type};
    }

    toolStore.drawInteraction = new Draw({
        ...drawType,
        source,
        condition: (e) => {
            const pointerEvent = e.activePointers[0];

            if (pointerEvent.buttons === 1) {
                return true;
            } else {
                return false;
            }
        },
        freehandCondition: function () {
            const numberOfTouches = this.getPointerCount() === 0 ? 1 : this.getPointerCount();
            return freehand && numberOfTouches == 1;
        }
    });
    toolStore.source = source;
    mapStore.getMap.addInteraction(toolStore.drawInteraction);
};

<script setup>
import { useKeycloakStore } from "@/store/KeycloakStore";
import { useMinasidorSettingsStore } from "@/store/MinasidorSettingsStore";
import { useRoute } from "vue-router";
const keycloakStore = useKeycloakStore();
const settingsStore = useMinasidorSettingsStore();
const logOut = () => {
    keycloakStore.logOut();
};

const router = useRoute();
const showWizard = () => {
    settingsStore.settings.properties.wizard = "show";
};
</script>
<script>
export default {
    name: "MenuLinks",
};
</script>
<template>
    <div class="links-menu" id="linksMenu">
        <ul>
            <li>
                <a
                    class="menu-link"
                    target="_blank"
                    href="https://pipos.se/vara-tjanster/regionalanalys/data-och-metoder-i-regionalanalys"
                    >Data och metoder</a
                >
            </li>
            <li>
                <a
                    class="menu-link"
                    target="_blank"
                    href="https://pipos.se/vara-tjanster/regionalanalys/support---regionalanalys"
                    >Support</a
                >
            </li>
            <li>
                <a
                    class="menu-link"
                    target="_blank"
                    href=" https://pipos.se/vara-tjanster/regionalanalys/villkor-regionalanalys"
                    >Villkor</a
                >
            </li>
            <li v-if="router.name !== 'Start'">
                <v-btn class="btn-plain" variant="plain" @click="showWizard()"
                    >Visa guiden</v-btn
                >
            </li>
            <li style="border-bottom: none">
                <a @click="logOut" class="menu-link">Logga ut</a>
            </li>
        </ul>
    </div>
</template>
<style lang="scss">
.links-menu {
    position: absolute;
    right: 0;
    width: 200px;
    top: 42px;
    z-index: 350;
    background-color: rgb(255 255 255);
    .v-btn--variant-plain {
        margin: 0;
        color: #7f7f7f;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: inherit;
        text-transform: none;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        height: 56px !important;
        padding-left: 1em;
        opacity: 1;
    }
    .btn-plain:hover {
        color: white;
    }
    .menu-link {
        text-decoration: none;
        margin: 0;
        padding: 1em;
        display: flex;
        color: #7f7f7f;
    }
    .menu-link:hover {
        color: white;
    }
    li {
        border-bottom: 1px solid #d6d6d6;
        cursor: pointer;
        list-style: none;
    }
    li:hover {
        background-color: #7f7f7f;
        color: white;
    }

    .btn-link {
        margin: 0;
        color: #7f7f7f;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: inherit;
        text-transform: none;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        height: 56px !important;
        padding-left: 1em;
    }
    .btn-link:hover {
        color: white;
    }
    .mapSwitch {
        grid-template-rows: 10px 30px;
    }
}
</style>

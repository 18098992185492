import { api } from "@/api";

class MinasidorSettingsService {
    async get() {
        try {
            return await api.get(
                "minasidor/settings/?application=regionalanalys"
            );
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    async save(settings) {
        try {
            await api.post("minasidor/settings", settings);
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    async update(settings) {
        try {
            await api.patch("minasidor/settings", settings);
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    async delete() {
        try {
            await api.delete("minasidor/settings?application=regionalanalys");
        } catch (error) {
            console.log(error);
            return error;
        }
    }
}

export default new MinasidorSettingsService();

import slide1 from "@/assets/images/bg_map.jpg";
import slide2 from "@/assets/images/slide_1.jpg";
import slide3 from "@/assets/images/slide_2.jpg";
import slide4 from "@/assets/images/slide_3.jpg";
import slide5 from "@/assets/images/slide_4.jpg";
import slide6 from "@/assets/images/slide_5.jpg";
import slide7 from "@/assets/images/slide_6.jpg";
import slide8 from "@/assets/images/bg_map.jpg";

export const getBackgroundUrl = (pageNo) => {
    switch (pageNo) {
        case 1:
            return `url(${slide1})`;
        case 2:
            return `url(${slide2})`;
        case 3:
            return `url(${slide3})`;
        case 4:
            return `url(${slide4})`;
        case 5:
            return `url(${slide5})`;
        case 6:
            return `url(${slide6})`;
        case 7:
            return `url(${slide7})`;
        case 8:
            return `url(${slide8})`;
        default:
            break;
    }
};

// export const showWizard

<script setup>
import { onUnmounted } from "vue";
import { storeToRefs } from "pinia";
import PointToolSettings from "@/components/maptools/PointToolSettings.vue";
import { MaIcon } from "@/utils/maIcons";

import { useToolStore } from "@/store/ToolStore";
import { useMapStore } from "@/store/MapStore.js";
import { useAccessibilityStore } from "@/store/AccessibilityStore";
import {
    setActivate,
    removeInteraction,
} from "@/composable/ol/drawToolInteraction.js";
import { watch } from "vue";
import { useIsochroneStore } from "@/store/IsochroneStore";
import { Icon, Style } from "ol/style";

const { getPointActive, getPointButtonActive } = storeToRefs(useToolStore());
const accessStore = useAccessibilityStore();
const mapStore = useMapStore();

const { markedFeature } = storeToRefs(useIsochroneStore());
const { getTravelIcon } = storeToRefs(accessStore);

const toolConfig = { type: "Point" };
const includeModify = true;
const toolType = "point";
const pointSource = mapStore.getPointLayer.getSource();

onUnmounted(() => {
    removeInteraction;
});

watch(getTravelIcon, (newVal) => {
    if (!markedFeature.value) return;
    markedFeature.value.setStyle(
        new Style({
            image: new Icon({
                scale: 1.2,
                crossOrigin: "anonymous",
                src: newVal,
            }),
        })
    );
});
</script>
<script>
export default {
    name: "PointTool",
};
</script>
<template>
    <div class="map-tools">
        <v-tooltip
            text="Ställ in färdsätt, avståndsmått samt ett avstånd och klicka sedan vart som helst i kartan för att skapa ett område runt ditt klick, baserat på inställningarna"
            :max-width="300"
        >
            <template v-slot:activator="{ props }">
                <v-btn
                    v-bind="props"
                    class="menu-btn"
                    :class="getPointButtonActive ? 'active' : 'disabled'"
                    @click="
                        setActivate(
                            toolType,
                            toolConfig,
                            includeModify,
                            pointSource
                        )
                    "
                >
                    <v-icon :icon="MaIcon[accessStore.travelingOptions.type]" />
                </v-btn>
            </template>
        </v-tooltip>
    </div>
    <PointToolSettings :showPointSettings="getPointActive"></PointToolSettings>
</template>
<style lang="scss"></style>

<template>
  <div class="login-page">
    <p>Hej, {{userName}}</p>
  </div>
</template>

<script>

export default {
  name: 'ProfileView',
  components: {},
  computed: {},
  watch: {},
  data() {
    return {
      userName: 'unknown',
    };
  },
  created() {
    const token = this.getCookie('access_token');
    if (token) {
      const jwt = this.parseJwt(token);
      this.userName = jwt['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
    }
  },
  methods: {
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) {
        return parts.pop().split(';').shift();
      }
      return false;
    },
    parseJwt(token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

      return JSON.parse(jsonPayload);
    },
  },
};
</script>

<style lang="scss" scoped>
.login-page {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
}
</style>

import { mapGetters, mapActions } from "vuex";
import { mapState } from "pinia";
import { useMapStore } from "@/store/MapStore";
import { Fill, Stroke, Style } from "ol/style";
import GeoJSON from "ol/format/GeoJSON";
import { Vector as VectorSource } from "ol/source";
import { Vector as VectorLayer } from "ol/layer";
import { Modify, Draw, Select, Translate } from "ol/interaction";

export default {
    data() {
        return {
            vectorLayer: null,
            borderColor: "#000",
        };
    },
    computed: {
        ...mapGetters("map", ["getMap"]),
        ...mapState(useMapStore, ["getMapMode"]),
    },

    methods: {
        ...mapActions("overview", [
            "setDiagramPanelVisibility",
            "resetSelectedAreaInformation",
        ]),
        // ...mapActions("connector", ["setLocation"]),

        clearLayerAndInteractions() {
            const map = this.getMap;
            let interactionsToRemove = map
                .getInteractions()
                .getArray()
                .filter(
                    (el) =>
                        el instanceof Select ||
                        el instanceof Draw ||
                        el instanceof Translate ||
                        el instanceof Modify
                );

            interactionsToRemove.forEach((el) => map.removeInteraction(el));

            if (this.vectorLayer) {
                this.vectorLayer.getSource().clear();
                map.removeLayer(this.vectotLayer);
            }

            map.getTargetElement().style.cursor = "default";
            this.setDiagramPanelVisibility(false);
            this.resetSelectedAreaInformation();
        },
        showLocation(geoJson) {
            const map = this.getMap;
            const feature = new GeoJSON().readFeature(geoJson);
            const geometry = feature.getGeometry();

            const source = new VectorSource();
            this.vectorLayer = new VectorLayer({
                source,
                name: "searchLayer",
                style: new Style({
                    stroke: new Stroke({
                        color: this.getMapMode === "light" ? "#888" : "#fff",
                      width: 4,
                      lineDash: [4, 8],
                    }),
                  }),
                zIndex: 100,
            });
            map.addLayer(this.vectorLayer);
            source.addFeature(feature);
            map.getView().fit(geometry, {
                padding: [80, 50, 50, 400],
                duration: 500,
            });
        },
    },
};

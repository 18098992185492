import { api } from "@/api";
import GeoJSON from "ol/format/GeoJSON";

const getScenarioId = () => sessionStorage.getItem("scenarioId") ?? "2021";

class ChartService {
  async getOverviewAll() {
    const params = {
      scenarioid: getScenarioId(),
    };
    return api.get("api/overview/all", { params });
  }

  async getOverviewCircle(circle, scenarioId) {
    scenarioId = scenarioId || getScenarioId();

    const params = {
      x: circle.center[1],
      y: circle.center[0],
      radius: circle.radius,
      scenarioid: scenarioId,
    };
    return api.get("api/overview/circle", { params });
  }

  async getOverviewPolygon(polygon, scenarioId) {
    scenarioId = scenarioId || getScenarioId();

    let geoJson = null;
    if (polygon.geometry) {
      geoJson = new GeoJSON().writeGeometryObject(
        polygon.geometry,
        "EPSG:3006",
        "EPSG:3006"
      );
    }
    const body = {
      polygon: geoJson,
      scenarioid: scenarioId,
    };
    return api.post("api/overview/polygon", body);
  }

  async getOverviewArea(id) {
    const params = {
      id,
      scenarioid: getScenarioId(),
    };
    return api.get("api/overview/area", { params });
  }
}

export default new ChartService();

import { defineStore } from "pinia";
import MinasidorAreaService from "@/services/MinasidorAreaService";
import { useReportStore } from "./ReportStore";
export const useMinasidorAreaStore = defineStore({
    id: "MinasidorAreaStore",
    state: () => ({
        savedAreas: [],
    }),

    actions: {
        async getAllSavedAreasForUser() {
            try {
                const response = await MinasidorAreaService.getAll();
                if (!response) return this.regions;
                this.savedAreas = response.data;
            } catch (error) {
                console.log(error);
                return error;
            }
        },
        async deleteArea(area) {
            try {
                const response = await MinasidorAreaService.delete(area);
                this.getAllSavedAreasForUser();
                if (!response) return response;
            } catch (error) {
                console.log(error);
                return error;
            }
        },
        async saveArea(area) {
            try {
                const response = await MinasidorAreaService.save(area);
                this.getAllSavedAreasForUser();
                if (!response) return response;
            } catch (error) {
                console.log(error);
                return error;
            }
        },
    },
    getters: {
        getSavedAreas: (state) => state.savedAreas,
    },
});

export const MaIcon = Object.freeze({
    search: "mdi-magnify",
    plus: "mdi mdi-plus",
    minus: "mdi mdi-minus",
    layers: "mdi:mdi-layers-outline",
    circle: "mdi:mdi-circle-outline",
    polygon: "mdi:mdi-pentagon-outline",
    freehand: "mdi:mdi-draw",
    county: "md:flag_circle",
    close: "mdi mdi-close",
    closeCircle: "mdi mdi-close-circle",
    home: "md:home",
    menu: "md:menu",
    user: "mdi mdi-account",
    spinner: "md:hourglass_bottom",
    municipality: "md:tour",
    locality: "md:location_city",
    provider: "md:store",
    address: "mdi mdi-home-map-marker",
    point: "mdi:mdi-pin-outline",
    upload: "mdi:mdi-upload-outline",
    time: "mdi: mdi-clock-time-five-outline",
    distance: "md: straighten",
    transit: "md:directions_bus",
    walk: "md:directions_walk",
    bicycle: "md:directions_bike",
    car: "md: directions_car",
    toggleUp: "mdi: mdi-chevron-up",
    toggleDown: "mdi: mdi-chevron-down",
    layer: "mdi mdi-layers-outline",
    areaTool: "md: flag_circle",
    delete: "mdi: mdi-delete-outline",
    info: "mdi: mdi-information-outline",
    removePoint: "md:wrong_location",
    addPoint: "md:add_location_alt",
    undo: "md:undo",
    redo: "md:redo",
    reset: "md:restart_alt",
    location: "mdi mdi-map-marker",
    Kontantuttag: "md:local_atm",
    Betalningsförmedling: "md:payments",
    Dagskasseinsättning: "mdi mdi-wallet-outline",
    Posttjänster: "mdi mdi-email-outline",
    Drivmedel: "mdi mdi-gas-station-outline",
    Apoteksvaror: "md: local_pharmacy",
    Dagligvaror: "mdi mdi-cart-outline",
    workplace: "mdi mdi-briefcase-variant",
    education: "mdi mdi-school",
    commercial_service: "mdi mdi-cart-outline",
    public_service: "mdi mdi-bank-outline",
    healthcare: "mdi mdi-hospital-box",
    culture: "md:museum",
    recreation: "mdi mdi-pine-tree",
    tune: "mdi-tune",
    areaEmpty: "mdi:mdi-chart-areaspline",
    area: "md: flag_circle",
    zoomIn: "md: zoom_in_map",
    squareCircleEmpty: "mdi:mdi-square-circle-outline",
    squareCircle: "mdi:mdi-square-circle",
    populationEmpty: "mdi:mdi-account-group-outline",
    population: "mdi:mdi-account-group",
    questionMark: "mdi mdi-help-circle-outline",
    riket: "mdi mdi-flag-variant-outline",
});

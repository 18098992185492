<template>
    <v-row class="mt-2" style="min-width: 400px;">
        <v-col cols="12" class="pa-2 align-center d-flex">
            <v-label class="mr-3" style="min-width: 80px;"><b>Konturkarta</b></v-label>
            <div class="legend" :style="legendThreshold">
                <div class="striped-bg" :style="thresholdHighStyle"></div>
            </div>
        </v-col>
    </v-row>
    <v-row class="align-center mt-0" :style="{ minWidth: '300px' }">
        <v-col cols="10" class="pa-2">
            <v-range-slider
                dense
                hide-details
                v-model="logSumThreshold"
                :max="1"
                :step="0.01"
                thumb-size="20"
                label="Tröskelvärden"
                :disabled="!categoryStore.getActiveAreaCategories.length"
            ></v-range-slider>
        </v-col>
        <v-col cols="2" class="pa-2">
            <InformationPopUp :text="Texts.helpInformation.no11.text">
            </InformationPopUp>
        </v-col>
    </v-row>
    <v-row class="align-center mt-0">
        <v-col cols="10" class="pa-2">
            <v-slider
                dense
                hide-details
                v-model="logSumLayerOpacity"
                :max="100"
                :step="1"
                thumb-label
                thumb-size="20"
                label="Genomskinlighet"
                :disabled="!categoryStore.getActiveAreaCategories.length"
            ></v-slider>
        </v-col>
        <v-col cols="2" class="pa-1">
            <InformationPopUp :text="Texts.helpInformation.no12.text">
            </InformationPopUp>
        </v-col>
    </v-row>
</template>

<script setup>
import { useCategoryStore } from "@/store/CategoryStore";
import { useMapStore } from "@/store/MapStore";
import { storeToRefs } from "pinia";
import { getCurrentInstance } from "vue";
import { watch } from "vue";
import InformationPopUp from "@/components/general/InformationPopUp.vue";
import Texts from "@/texts/texts.json";
import { computed } from "vue";
const mapStore = useMapStore();
const { logSumLayerOpacity, logSumThreshold } = storeToRefs(mapStore);
const categoryStore = useCategoryStore();
let timer = null;

const internalInstance = getCurrentInstance();
const emitter = internalInstance.appContext.config.globalProperties.emitter;

const { categories } = storeToRefs(useCategoryStore());

watch(
    () => logSumThreshold.value,
    () => {
        // debounce for 500ms
        if (timer != null) {
            clearTimeout(timer);
            timer = null;
        }
        timer = setTimeout(() => {
            let accessibilityIds = [];
            categories.value.forEach((category) => {
                category.forms.forEach((form) => {
                    if (form.checkedArea === true) {
                        accessibilityIds.push(form.id);
                    }
                });
            });
            emitter.emit(
                "layer-accessibility-change",
                [...accessibilityIds].sort()
            );
        }, 500);
    }
);

const legendThreshold = computed(() => {
    const [min, max] = mapStore.getLogSumThreshold ?? [0, 1];
    const step = (max - min) / 5;
    const round = (value) => Math.round(value * 100);
    const thresholds = [min, min + step, min + step * 2, min + step * 3, min + step * 4, max].map(i => round(i));

    const lightModeBackground = `linear-gradient(90deg, #ffffff00 ${thresholds[0]}%, #b7eade ${thresholds[0]}% ${thresholds[1]}%, #87eabd ${thresholds[1]}% ${thresholds[2]}%, #82ef8a ${thresholds[2]}% ${thresholds[3]}%, #a5f766 ${thresholds[3]}% ${thresholds[4]}%, #e1fc22 ${thresholds[4]}% ${thresholds[5]}%, #ffffff00 ${thresholds[5]}%)`;
    const darkModeBackground = `linear-gradient(90deg, #ffffff00 ${thresholds[0]}%, #002F61 ${thresholds[0]}% ${thresholds[1]}%, #00768D ${thresholds[1]}% ${thresholds[2]}%, #00B5A1 ${thresholds[2]}% ${thresholds[3]}%, #61E97E ${thresholds[3]}% ${thresholds[4]}%, #B2EC38 ${thresholds[4]}% ${thresholds[5]}%, #ffffff00 ${thresholds[5]}%)`;

    const background = mapStore.getMapMode === "dark" ? darkModeBackground : lightModeBackground;
    
    return {
        background
    }
});

const thresholdHighStyle = computed(() => {
    const [, max] = mapStore.getLogSumThreshold ?? [0, 1];

    const darkModeBackground = `repeating-linear-gradient(45deg, #00000070, #00000070 2px, #B2EC38 2px, #B2EC38 6px)`;
    const lightModeBackground = `repeating-linear-gradient(45deg, #00000070, #00000070 2px, #e1fc22 2px, #e1fc22 6px)`;

    const background = mapStore.getMapMode === "dark" ? darkModeBackground : lightModeBackground;
    const width = max < 1 ? `${100 - (max * 100)}%` : '0%';

    return {
        width,
        background
    };
});
</script>
<style lang="scss">
.map-settings {
    .v-slider.v-input--horizontal {
        margin-inline-start: 0;
        margin-inline-end: 0;
    }
}
.legend {
    width: 199px;
    height: 100%;
    margin-left: 25px;
}
.striped-bg {
    width: 0;
    height: 100%;
    float: right;
    
}
</style>

import { api } from "@/api";
import { UnPackAttractions } from "@/composable/io/pack";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { WebGLPoints as WebGLPointsLayer } from "ol/layer";
import SimpleVectorSource from "@/composable/ol/simplevectorsource";
import { useCategoryStore } from "@/store/CategoryStore";
import { useMapStore } from "@/store/MapStore";
import { useScenarioInformationStore } from "@/store/ScenarioInformationStore";

class AttractionService {
    async getGeoJSON(scenarioId, errand) {
        const params = {
            errand,
            scenarioId,
        };
        return api.get("api/attraction/geojson", { params });
    }

    async getBinary(scenarioId, errand, scenarioId2 = -1) {
        const params = {
            errand,
            scenarioId,
            scenarioId2,
        };
        return api.get("api/attraction/binary", { params });
    }

    async getPointLayer(errand) {
        const categoryStore = useCategoryStore();
        const scenarioInformationStore = useScenarioInformationStore();
        const mapStore = useMapStore();
        const compare =
            scenarioInformationStore.getScenarioInformation.mode == "single"
                ? -1
                : scenarioInformationStore.getScenarioInformation.compareToId;
        const result = await this.getBinary(
            scenarioInformationStore.getScenarioInformation.scenarioId,
            errand,
            compare
        );

        const data = UnPackAttractions(result.data);
        let maxAttraction = Number.MIN_SAFE_INTEGER;
        let minAttraction = Number.MAX_SAFE_INTEGER;

        const features = data.map((i) => {
            maxAttraction = Math.max(maxAttraction, i.attraction);
            minAttraction = Math.min(minAttraction, i.attraction);
            let geometry = new Point([i.x, i.y]);
            return new Feature({
                geometry: geometry,
                attraction: i.attraction,
                category: categoryStore.getCategoryForTravelReason(errand),
                diff: i.diff,
            });
        });

        // Gör ett litet spann som att circlarna ritas ut med mellanstorlek
        if (minAttraction == maxAttraction) {
            minAttraction -= 1;
            maxAttraction += 1;
        }

        const attractionSimpleVectorSource = new SimpleVectorSource({
            features: features,
        });

        const attractionLayerStyle = {
            "circle-radius": [
                "interpolate",
                ["linear"],
                ["zoom"],
                5, ["*", 2, ["var", "size"]],    // At zoom level 5, multiply the base size (5) by the user-defined size
                14, ["*", 10, ["var", "size"]]  // At zoom level 14, multiply the base size (120) by the user-defined size
            ],
            "circle-fill-color": [
                "case",
                ["==", ["get", "category"], "workplace"],
                "#007398",
                ["==", ["get", "category"], "education"],
                "#a4123f",
                ["==", ["get", "category"], "commercial_service"],
                "#00205b",
                ["==", ["get", "category"], "public_service"],
                "#78aa00",
                ["==", ["get", "category"], "healthcare"],
                "#1998d6",
                ["==", ["get", "category"], "culture"],
                "#dc8633",
                ["==", ["get", "category"], "recreation"],
                "#93328e",
                "rgb(0,0,172)", // Default color if none of the categories match
            ],
            "circle-stroke-width": 2,
            "circle-stroke-color": [
                "case",
                ["==", ["get", "diff"], -1], // finns endast i scenario1
                "red",
                ["==", ["get", "diff"], 1], // finns endast i scenario2
                "green",
                ["==", ["get", "diff"], 2], // scenario1 har fler
                "orange",
                ["==", ["get", "diff"], 3], // scenario2 har fler
                "yellow",
                [
                    "case",
                    ["==", ["get", "category"], "workplace"],
                    "#007398",
                    ["==", ["get", "category"], "education"],
                    "#a4123f",
                    ["==", ["get", "category"], "commercial_service"],
                    "#00205b",
                    ["==", ["get", "category"], "public_service"],
                    "#78aa00",
                    ["==", ["get", "category"], "healthcare"],
                    "#1998d6",
                    ["==", ["get", "category"], "culture"],
                    "#dc8633",
                    ["==", ["get", "category"], "recreation"],
                    "#93328e",
                    "rgb(0,0,172)", // Default color if none of the categories match
                ],
            ],
            "circle-opacity":["var", "opacity"],
            variables: {
                size: mapStore.getPointLayerCircleSize,
                opacity: mapStore.getPointLayerOpacity,
            },
        };

        const attractionVectorLayer = new WebGLPointsLayer({
            source: attractionSimpleVectorSource,
            style: attractionLayerStyle,
            properties: {
                errandId: errand,
                layerType: "attraction",
            },
            zIndex: 90,
        });

        return attractionVectorLayer;
    }
}
export default new AttractionService();

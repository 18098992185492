<script setup>
import { onUnmounted } from "vue";
import { MaIcon } from "@/utils/maIcons";
import { removeInteraction } from "@/composable/ol/drawToolInteraction.js";
import { useToolStore } from "@/store/ToolStore";
import { storeToRefs } from "pinia";
import { setTool } from "@/composable/ol/drawToolInteraction.js";
import SavedAreaPanel from "@/components/maptools/SavedAreaPanel.vue";

const toolStore = useToolStore();
const { getSavedAreaActive } = storeToRefs(toolStore);

const setActivate = async () => {
    await toolStore.clearAllTools();

    setTool("upload");
};
onUnmounted(() => {
    removeInteraction;
});
</script>
<script>
export default {
    name: "SavedAreaTool",
};
</script>
<template>
    <div class="map-tools">
        <v-tooltip text="Hämta ett område som du tidigare sparat" :max-width="300">
            <template v-slot:activator="{ props }">
                <v-btn
                    v-bind="props"
                    class="menu-btn"
                    :class="getSavedAreaActive ? 'active' : 'disabled'"
                    @click="setActivate()"
                >
                    <v-icon :icon="MaIcon['upload']"></v-icon>
                </v-btn>
            </template>
        </v-tooltip>
    </div>
    <SavedAreaPanel :showSavedAreaPanel="getSavedAreaActive"></SavedAreaPanel>
</template>
<style lang="scss"></style>

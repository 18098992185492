<script setup>
import { watch } from "vue";
import SelectedAreaStatistics from "@/components/report/SelectedAreaStatistics.vue";
import LogsumComparer from "@/components/report/LogsumComparer.vue";
import { useLogsumStore } from "@/store/LogsumStore";
import { useReportStore } from "@/store/ReportStore";
import { storeToRefs } from "pinia";
import { debounce } from "@/utils/debounce";
import { useCategoryStore } from "@/store/CategoryStore";

const logsumStore = useLogsumStore();
const reportStore = useReportStore();
const categoryStore = useCategoryStore();

const { getStatistics, getStatisticsComparison } = storeToRefs(reportStore);
const { getSelectionLogsums, getComparisonLogsums, getSwedenLogsums } =
    storeToRefs(logsumStore);

const updateReportDebounce = debounce(reportStore.updateReport, 500);

watch(
    () => reportStore.activeArea,
    () => {
        updateReportDebounce();
    }
);

watch(
    () => reportStore.comparisonArea,
    (newVal) => {
        const isCompare = true;
        reportStore.updateReport(isCompare);
        logsumStore.comparisonChanged(newVal);
    }
);
watch(
    () => [categoryStore.getLogSumTravelModes, categoryStore.getLogSumModel],
    () => {
        logsumStore.calculationChanged();
    }
);
</script>
<script>
export default {
    name: "SunburstWithCompareSelector",
};
</script>
<template>
    <div
        class="pl-2 pt-t pb-2 mt-2"
        tile
        width="100%"
        style="position: relative"
    >
        <SelectedAreaStatistics
            :data="getStatistics"
            v-if="getStatistics != null && getStatisticsComparison != null"
            :comparison="getStatisticsComparison"
        ></SelectedAreaStatistics>

        <LogsumComparer
            :selection="getSelectionLogsums"
            :comparison="getComparisonLogsums"
            :sweden="getSwedenLogsums"
            :style="{ marginBottom: '20px' }"
        ></LogsumComparer>
    </div>
</template>
<style lang="scss">
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        font-size: 1em;
        font-weight: 600;
        display: flex;
    }
}
.comparison-selector {
    line-height: 1.3em;
    font-size: 0.9em;
    width: 100%;

    .search-input {
        width: 100%;
    }
    .top {
        padding-bottom: 4px;
    }
    .p-dropdown {
        width: 100%;
    }
}
.profileBtn {
    height: 48px;
    width: 81%;
    color: $base-font;
    background: white;
    box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    cursor: pointer;
}
</style>

<style lang="scss" scoped>
.v-card {
    overflow-y: auto;
    height: 100%;
}
</style>

<template>
    <div
        class="gauge"
        :style="{
            width: '275px',
            '--rotation': props.value.degrees + 'deg',
            marginLeft: 'auto',
            marginRight: 'auto',
        }"
    >
        <div class="percentage"></div>
        <div class="mask"></div>
    </div>
</template>

<script setup>

const props = defineProps({
    value: {
        type: Object,
        required: true,
    },
});
</script>

<style lang="scss" scoped>
.gauge {
    position: relative;
    border-radius: 50%/100% 100% 0 0;
    background-color: #f7d7a7;
    overflow: hidden;
    border: 7px #fff solid;
    box-sizing: border-box;
    margin: 2px;
}
.gauge:before {
    content: "";
    display: block;
    padding-top: 50%; /* ratio of 2:1*/
}
.gauge .chart {
    overflow: hidden;
}
.gauge .mask {
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 0;
    top: 20%;
    background-color: #fff;
    border-radius: 50%/100% 100% 0 0;
    
}

.gauge .percentage {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: 0;
    right: -1px;
    background-color: #eee;
    transform: rotate(var(--rotation));
    transform-origin: bottom center;
}
.gauge .min {
    position: absolute;
    bottom: 0;
    left: 5%;
}
.gauge .max {
    position: absolute;
    bottom: 0;
    right: 5%;
}
</style>

<script setup>
import SearchService from "@/services/SearchService";
import { useMinasidorAreaStore } from "@/store/MinasidorAreaStore";
import { useReportStore } from "@/store/ReportStore";
import { useMapStore } from "@/store/MapStore";
import { ref, watch } from "vue";
import { MaIcon } from "@/utils/maIcons";
import { searchSavedAreaResultSelected, searchSavedAreaResultDeselected } from "@/composable/ol/selectInteraction.js";
const minaSidorStore = useMinasidorAreaStore();
const reportStore = useReportStore();
const mapStore = useMapStore();
let items = ref([]);
let search = ref("");
let input = ref("");
const loading = ref(false);

watch(search, async (val) => {
    val && val !== input.value && (await querySelections(val));
});

watch(input, async (val) => selectedItem(val));

const areas = minaSidorStore.savedAreas.map((item) => ({
    ...item,
    id: item.id,
    location: "Sparat område",
    name: item.name,
    type: "upload",
}));

const filteredRegions = (text) => {
    return areas.filter((x) =>
        x.name.toLowerCase().includes(text.toLowerCase())
    );
};

const querySelections = async (text) => {
    loading.value = true;

    const response = await SearchService.searchByPattern(text);
    response.data.push({
        id: 0,
        location: "",
        name: "Riket",
        seachText: "",
        type: "riket",
    });
    let savedAreasFilterd = [];

    savedAreasFilterd = filteredRegions(text);

    items.value = [...savedAreasFilterd, ...response.data];

    loading.value = false;
};
const selectedItem = async (item) => {
    if (item === null) return;

    if (item.type === "riket") {
        await reportStore.setDefaultRefrenceArea();
        mapStore.getMap.getView().setZoom(6);
        reportStore.comparisonName = "Riket";
        return;
    }
    if (item.type === "upload") {
        reportStore.comparisonName = item.name;
        searchSavedAreaResultSelected(item.geometry);

        return;
    }
    const response = await SearchService.getDetails(item.type, item.id);
    if (response && response.data) {
        const name =
            response.data.properties.county_name ??
            response.data.properties.municipality_name ??
            response.data.properties.name + " tätort";
        reportStore.comparisonName = name;

        searchSavedAreaResultSelected(response.data);
    }
};
const clear = () => {
    input.value = null;
    searchSavedAreaResultDeselected();
};
</script>
<script>
export default {
    name: "AdminAndSavedAreaSearch",
};
</script>
<template>
    <div class="autocomplete-wrapper">
        <v-autocomplete
            v-model="input"
            v-model:search="search"
            :loading="loading"
            :items="items"
            item-value="name"
            item-title="name"
            :no-filter="true"
            class="search"
            hide-no-data
            @click:clear="clear"
            clearable
            variant="solo"
            :density="'compact'"
            return-object
            append-icon=""
            :single-line="true"
            prepend-inner-icon="mdi-magnify"
            menu-icon=""
            label="Ändra jämförelseområde"
        >
            <template v-slot:item="{ props, item }">
                <v-list-item
                    v-bind="props"
                    :prepend-icon="MaIcon[item?.raw?.type]"
                    :title="item?.raw?.name"
                    :subtitle="item?.raw?.location"
                ></v-list-item>
            </template>
        </v-autocomplete>
    </div>
</template>
<style lang="scss">
.autocomplete-wrapper {
    max-height: 40px;
    .v-field__prepend-inner {
        max-height: 40px;
    }
    .v-field {
        max-height: 40px;
    }
    .v-field__field {
        max-height: 40px;
    }
    .v-field__clearable {
        max-height: 40px;
    }
}
</style>

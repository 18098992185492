<template>
    <TopNavigation></TopNavigation>
    <div class="map-container" id="piposmap">
        <OlMap></OlMap>
    </div>
</template>

<script>
import OlMap from "@/components/OlMap.vue";
import TopNavigation from "@/components/navigation/TopNavigation.vue";

export default {
    name: "App",
    components: {
        OlMap,
        TopNavigation,
    },
};
</script>

<style lang="scss">
ul {
    padding: 0;
    margin: 0;
}

body {
    margin: 0;
}
.map-container {
    width: 100%;
    height: 100%;
    position: fixed;
}
</style>

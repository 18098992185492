import { defineStore } from "pinia";
import { toRaw } from "vue";
import GeoJSON from "ol/format/GeoJSON";
import ReportService from "@/services/ReportService";
import { useScenarioInformationStore } from "@/store/ScenarioInformationStore";
import { useMapStore } from "@/store/MapStore";
export const useReportStore = defineStore({
    id: "ReportStore",
    state: () => ({
        reportVisible: false,
        activeArea: null,
        statistics: null,
        comparisonArea: null,
        comparisonName: "Riket",
        statisticsComparison: null,
        reportMetadata: {},
    }),
    actions: {
        async updateReport(referenceArea = false) {
            this.reportMetadataScenario();

            const senarioStore = useScenarioInformationStore();
            const scenario = senarioStore.scenarioInformation.scenarioId;

            if (referenceArea) {
                if (this.comparisonArea == null) return;
                let geom = this.comparisonArea?.get("geometry");

                const area = geom.getArea();
                this.comparisonArea.area = area;

                const geoJson = new GeoJSON().writeGeometryObject(toRaw(geom));

                let obj = {
                    ScenarioId: scenario,
                    Geometry: geoJson,
                };

                const properties = this.comparisonArea.getProperties();

                if (properties && "municipality_code" in properties) {
                    obj.Code = properties.municipality_code;
                    obj.CodeType = "municipality";
                }

                if (properties && "county_code" in properties) {
                    obj.Code = properties.county_code;
                    obj.CodeType = "county";
                }

                const response = await ReportService.AreaStatistics(obj);
                if (response.status == 200) {
                    const data = response.data;
                    this.statisticsComparison = data;
                }
                return;
            }

            if (this.activeArea == null && this.activeArea.feature) {
                console.log("no area active");
                return;
            }
            let geom = this.activeArea.feature.get("geometry");

            const geoJson = new GeoJSON().writeGeometryObject(toRaw(geom));

            let obj = {
                ScenarioId: scenario,
                Geometry: geoJson,
            };

            const properties = this.activeArea.feature.getProperties();

            if (properties && "municipality_code" in properties) {
                obj.Code = properties.municipality_code;
                obj.CodeType = "municipality";
            }

            if (properties && "county_code" in properties) {
                obj.Code = properties.county_code;
                obj.CodeType = "county";
            }

            const response = await ReportService.AreaStatistics(obj);

            if (response.status == 200) {
                const data = response.data;
                this.statistics = data;
            }

            // default för riktet
            if (this.statisticsComparison === null) {
                await this.setDefaultRefrenceArea();
            }
        },
        async setDefaultRefrenceArea() {
            const senarioStore = useScenarioInformationStore();
            const scenario = senarioStore.scenarioInformation.scenarioId;

            const mapStore = useMapStore();
            const source = mapStore.getReferenceLayer.getSource();
            source.clear();

            let obj = {
                Geometry: null,
                ScenarioId: scenario,
            };
            const response = await ReportService.AreaStatistics(obj);
            if (response.status == 200) {
                const data = response.data;
                this.statisticsComparison = data;
            }

            this.comparisonArea = null;
        },
        async reportMetadataScenario() {
            const senarioStore = useScenarioInformationStore();
            const scenario = senarioStore.scenarioInformation.scenarioId;

            const response = await ReportService.ReportMetadata(
                parseInt(scenario)
            );
            if (response.status == 200) {
                const data = response.data;
                this.reportMetadata = data;
            }
        },
    },
    getters: {
        getReportVisible: (state) => state.reportVisible,
        getActiveArea: (state) => state.activeArea,
        getStatistics: (state) => state.statistics,
        getComparisonArea: (state) => state.comparisonArea,
        getStatisticsComparison: (state) => state.statisticsComparison,
        getComparisonName: (state) => state.comparisonName,
        getReportMetadata: (state) => state.reportMetadata,
    },
});

<template>
    <v-expansion-panels v-model="expanded" multiple>
        <v-expansion-panel value="expanded">
            <v-expansion-panel-title>
                Teckenförklaring
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <Transition :name="getAnimation(9)">
                    <v-row
                        v-if="reportVisible && readyToAnimate"
                        align="center"
                    >
                        <v-col :cols="2">
                            <div class="selected-area square"></div>
                        </v-col>
                        <v-col :cols="10">
                            <label>{{ selectedAreaLabel }}</label>
                        </v-col>
                    </v-row>
                </Transition>
                <Transition :name="getAnimation(10)">
                    <v-row
                        v-if="compareVisible && readyToAnimate"
                        align="center"
                    >
                        <v-col :cols="2">
                            <div class="compare-area square"></div>
                        </v-col>
                        <v-col :cols="10">
                            <label>Jämförelseområde</label>
                        </v-col>
                    </v-row>
                </Transition>
                <Transition :name="getAnimation(1)">
                    <v-row
                        v-if="pointWorkplace && readyToAnimate"
                        align="center"
                    >
                        <v-col :cols="2">
                            <div class="workplace point"></div>
                        </v-col>
                        <v-col :cols="10">
                            <label>Arbetsmarknad</label>
                        </v-col>
                    </v-row>
                </Transition>
                <Transition :name="getAnimation(2)">
                    <v-row
                        v-if="pointEducation && readyToAnimate"
                        align="center"
                    >
                        <v-col :cols="2">
                            <div class="education point"></div>
                        </v-col>
                        <v-col :cols="10">
                            <label>Utbildning</label>
                        </v-col>
                    </v-row>
                </Transition>
                <Transition :name="getAnimation(3)">
                    <v-row
                        v-if="pointCommercial && readyToAnimate"
                        align="center"
                    >
                        <v-col :cols="2">
                            <div class="commercial_service point"></div>
                        </v-col>
                        <v-col :cols="10">
                            <label>Kommersiell service</label>
                        </v-col>
                    </v-row>
                </Transition>
                <Transition :name="getAnimation(4)">
                    <v-row v-if="pointPublic && readyToAnimate" align="center">
                        <v-col :cols="2">
                            <div class="public_service point"></div>
                        </v-col>
                        <v-col :cols="10">
                            <label>Offentlig service</label>
                        </v-col>
                    </v-row>
                </Transition>
                <Transition :name="getAnimation(5)">
                    <v-row
                        v-if="pointHealthcare && readyToAnimate"
                        align="center"
                    >
                        <v-col :cols="2">
                            <div class="healthcare point"></div>
                        </v-col>
                        <v-col :cols="10">
                            <label>Sjukvård</label>
                        </v-col>
                    </v-row>
                </Transition>
                <Transition :name="getAnimation(6)">
                    <v-row
                        v-if="pointRecreation && readyToAnimate"
                        align="center"
                    >
                        <v-col :cols="2">
                            <div class="recreation point"></div>
                        </v-col>
                        <v-col :cols="10">
                            <label>Fritid</label>
                        </v-col>
                    </v-row>
                </Transition>
                <v-row
                    v-if="
                        scenarioInformationStore.getIsCompare &&
                        (pointWorkplace ||
                            pointCommercial ||
                            pointEducation ||
                            pointHealthcare ||
                            pointPublic ||
                            pointRecreation)
                    "
                    align="center"
                >
                    <v-col :cols="2">
                        <div class="add-point"></div>
                    </v-col>
                    <v-col :cols="10">
                        <label>Reseanledning som tillkommit</label>
                    </v-col>
                </v-row>
                <v-row
                    v-if="
                        scenarioInformationStore.getIsCompare &&
                        (pointWorkplace ||
                            pointCommercial ||
                            pointEducation ||
                            pointHealthcare ||
                            pointPublic ||
                            pointRecreation)
                    "
                    align="center"
                >
                    <v-col :cols="2">
                        <div class="remove-point"></div>
                    </v-col>
                    <v-col :cols="10">
                        <label>Reseanledning som tagits bort</label>
                    </v-col>
                </v-row>
                <Transition :name="getAnimation(7)">
                    <v-row
                        v-if="logsumLayerLight && readyToAnimate"
                        align="center"
                    >
                        <v-col :cols="2">
                            <v-tooltip location="top">
                                <template v-slot:activator="{ props }">
                                    <div
                                        class="gradient-logsum-light"
                                        v-bind="props"
                                    ></div>
                                </template>
                                <div class="gradient-tooltip-container">
                                    <div
                                        class="gradient-logsum-light-expanded"
                                    ></div>
                                    <div class="gradient-tooltip-row">
                                        <div class="gradient-tooltip-text">
                                            Högst tillgänglighet
                                        </div>
                                        <div class="gradient-tooltip-text">
                                            &nbsp;
                                        </div>
                                        <div class="gradient-tooltip-text">
                                            &nbsp;
                                        </div>
                                        <div class="gradient-tooltip-text">
                                            &nbsp;
                                        </div>
                                        <div class="gradient-tooltip-text">
                                            Lägst tillgänglighet
                                        </div>
                                    </div>
                                </div>
                            </v-tooltip>
                        </v-col>
                        <v-col :cols="10" class="d-flex align-baseline">
                            <label
                                >Tillgänglighetsindex för valda reseanledningar </label
                            ><InformationPopUp
                                :text="Texts.helpInformation.no19.text"
                                location="top"
                            >
                            </InformationPopUp>
                        </v-col>
                    </v-row>
                </Transition>
                <Transition :name="getAnimation(8)">
                    <v-row
                        v-if="logsumLayerDark && readyToAnimate"
                        align="center"
                    >
                        <v-col :cols="2">
                            <v-tooltip location="top">
                                <template v-slot:activator="{ props }">
                                    <div
                                        class="gradient-logsum-dark"
                                        v-bind="props"
                                    ></div>
                                </template>
                                <div class="gradient-tooltip-container">
                                    <div
                                        class="gradient-logsum-dark-expanded"
                                    ></div>
                                    <div class="gradient-tooltip-row">
                                        <div class="gradient-tooltip-text">
                                            Högst tillgänglighet
                                        </div>
                                        <div class="gradient-tooltip-text">
                                            &nbsp;
                                        </div>
                                        <div class="gradient-tooltip-text">
                                            &nbsp;
                                        </div>
                                        <div class="gradient-tooltip-text">
                                            &nbsp;
                                        </div>
                                        <div class="gradient-tooltip-text">
                                            Lägst tillgänglighet
                                        </div>
                                    </div>
                                </div>
                            </v-tooltip>
                        </v-col>
                        <v-col :cols="10" class="d-flex align-baseline">
                            <label
                                >Tillgänglighetsindex för valda reseanledningar
                            </label>
                            <InformationPopUp
                                :text="Texts.helpInformation.no19.text"
                            >
                            </InformationPopUp>
                        </v-col>
                    </v-row>
                </Transition>
                <Transition :name="getAnimation(0)">
                    <v-row
                        v-if="populationLayerVisible && readyToAnimate"
                        align="center"
                    >
                        <v-col :cols="2">
                            <v-tooltip location="top">
                                <template v-slot:activator="{ props }">
                                    <div 
                                        class="gradient-population" 
                                        v-bind="props"
                                    ></div>
                                </template>
                                <div class="gradient-tooltip-container">
                                    <div class="gradient-population-expanded"></div>
                                    <div class="gradient-tooltip-row">
                                        <div class="gradient-tooltip-text">
                                            Hög densitet
                                        </div>
                                        <div class="gradient-tooltip-text">
                                            &nbsp;
                                        </div>
                                        <div class="gradient-tooltip-text">
                                            &nbsp;
                                        </div>
                                        <div class="gradient-tooltip-text">
                                            &nbsp;
                                        </div>
                                        <div class="gradient-tooltip-text">
                                            Låg densitet
                                        </div>
                                    </div>
                                </div>
                            </v-tooltip>
                        </v-col>
                        <v-col :cols="10">
                            <label>Population</label>
                        </v-col>
                    </v-row>
                </Transition>
                <div ref="readyToAnimate" align="center"></div>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script setup>
import Texts from "@/texts/texts.json";
import InformationPopUp from "@/components/general/InformationPopUp.vue";

import { useAccessibilityStore } from "@/store/AccessibilityStore";
import { useCategoryStore } from "@/store/CategoryStore";
import { useMapStore } from "@/store/MapStore";
import { useReportStore } from "@/store/ReportStore";
import { useToolStore } from "@/store/ToolStore";
import { useScenarioInformationStore } from "@/store/ScenarioInformationStore";
import { storeToRefs } from "pinia";
import { watch, onMounted, ref, computed } from "vue";

const mapStore = useMapStore();
const { mapMode } = storeToRefs(mapStore);
const categoryStore = useCategoryStore();
const reportStore = useReportStore();
const { reportVisible } = storeToRefs(reportStore);
const expanded = ref([]);
const readyToAnimate = ref(null);
const stuffToAnimate = ref([]);
const scenarioInformationStore = useScenarioInformationStore();

const getAnimation = (index) => {
    if (stuffToAnimate.value.includes(index)) {
        return "bounce";
    }
    return "no-animation";
};

const compareVisible = computed(() => {
    return reportVisible.value && reportStore.getComparisonArea != null;
});

const logsumLayerLight = computed(() => {
    return (
        categoryStore.getActiveAreaCategories.length > 0 &&
        mapMode.value === "light"
    );
});

const logsumLayerDark = computed(() => {
    return (
        categoryStore.getActiveAreaCategories.length > 0 &&
        mapMode.value === "dark"
    );
});

const populationLayerVisible = computed(() => {
    if (mapStore.getPopulationLayer === null) {
        return false;
    }
    const source = mapStore.getPopulationLayer.getSource();
    return source.getFeatures().length > 0;
});

const pointWorkplace = computed(() => {
    const points = categoryStore.getActivePointCategories;
    return points.some((point) => point.id === "workplace");
});
const pointEducation = computed(() => {
    const points = categoryStore.getActivePointCategories;
    return points.some((point) => point.id === "education");
});
const pointCommercial = computed(() => {
    const points = categoryStore.getActivePointCategories;
    return points.some((point) => point.id === "commercial_service");
});
const pointPublic = computed(() => {
    const points = categoryStore.getActivePointCategories;
    return points.some((point) => point.id === "public_service");
});
const pointHealthcare = computed(() => {
    const points = categoryStore.getActivePointCategories;
    return points.some((point) => point.id === "healthcare");
});
const pointRecreation = computed(() => {
    const points = categoryStore.getActivePointCategories;
    return points.some((point) => point.id === "recreation");
});

const updateStuffToAnimate = (newIndexes) => {
    stuffToAnimate.value = newIndexes;
    setTimeout(() => {
        stuffToAnimate.value = [];
    }, 4000);
};

const toggleExpanded = () => {
    if (expanded.value.length === 0) {
        expanded.value = ["expanded"];
        setTimeout(() => {
            expanded.value = [];
        }, 4000);
    }
};

// watch for changes in active categories and if not expanded, expand for 5 seconds
onMounted(() => {
    watch(
        [
            populationLayerVisible,
            pointWorkplace,
            pointEducation,
            pointCommercial,
            pointPublic,
            pointHealthcare,
            pointRecreation,
            logsumLayerLight,
            logsumLayerDark,
            reportVisible,
            compareVisible,
        ],
        (newVal, oldVal) => {
            const newIndexes = newVal
                .map((val, index) => (val && !oldVal[index] ? index : -1))
                .filter((index) => index !== -1);

            if (newIndexes.length) {
                updateStuffToAnimate(newIndexes);
                toggleExpanded();
            }
        }
    );
});

const selectedAreaLabel = computed(() => {
    const { toolType } = storeToRefs(useToolStore());
    if (toolType.value) {
        if (toolType.value === "point") {
            const accessibilityStore = useAccessibilityStore();
            const { travelingOptions } = storeToRefs(accessibilityStore);
            const mapping = {
                transit: "kollektivtrafik",
                car: "bil",
                bicycle: "cykel",
                walk: "gång",
            };
            const travelName = mapping[accessibilityStore.getTravelType];

            if (accessibilityStore.getTravelMode == "distance") {
                const km = travelingOptions.value.selectedIntervalSwipe;
                return `Eget område (upp till ${km} km med ${travelName})`;
            }
            if (accessibilityStore.getTravelMode == "time") {
                const travelTime = travelingOptions.value.selectedIntervalSwipe;
                return `Eget område (upp till ${travelTime} minuter med ${travelName})`;
            }
        }
        if (toolType.value === "area") {
            const { activeArea } = storeToRefs(reportStore);
            if (activeArea.value) {
                return activeArea.value.name;
            }
        }
        if (toolType.value === "upload") {
            const { activeArea } = storeToRefs(reportStore);
            if (activeArea.value) {
                return activeArea.value.name;
            }
        }
    }
    return "Eget område";
});
</script>

<style lang="scss" scoped>
.v-expansion-panel {
    position: fixed;
    bottom: 5px;
    width: 170px;
    right: 125px;
}
.v-expansion-panel--active {
    width: 350px;
}

.v-expansion-panel-title {
    min-height: unset !important;
}
.selected-area {
    border: 1px solid #bc7100;
    background-color: #ff990050;
}

.compare-area {
    border: 1px #414040 solid;
    background-color: #ebebeb;
}
.square {
    width: 24px;
    height: 24px;
    border-radius: 5px;
}
.workplace {
    background-color: $workplace;
}
.commercial_service {
    background-color: $commercial_service;
}
.public_service {
    background-color: $public_service;
}
.healthcare {
    background-color: $healthcare;
}
.recreation {
    background-color: $recreation;
}
.education {
    background-color: $education;
}
.point {
    border-radius: 50%;
    width: 18px;
    height: 18px;
    margin-top: 3px;
    margin-left: 3px;
}
.add-point {
    border-radius: 50%;
    border: 2px solid green;
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-left: 3px;
}
.remove-point {
    border-radius: 50%;
    border: 2px solid red;
    width: 16px;
    height: 16px;
    margin-top: 3px;
    margin-left: 3px;
}
// Adjust margin for all rows but first
.v-row:not(:first-child) {
    margin-top: -16px;
}

.gradient-logsum-light {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    background: linear-gradient(
        135deg,
        #e1fc22 20%,
        #a5f766 20% 40%,
        #82ef8a 40% 60%,
        #87eabd 60% 80%,
        #b7eade 80%
    );
    grid-area: 1 / 1 / 6 / 2;
}

.gradient-tooltip-row {
    grid-area: 1 / 2 / 6 / 3;
    grid-auto-columns: auto;
}

.gradient-logsum-light-expanded {
    width: 24px;
    height: 112px;
    border-radius: 5px;
    background: linear-gradient(
        180deg,
        #e1fc22 20%,
        #a5f766 20% 40%,
        #82ef8a 40% 60%,
        #87eabd 60% 80%,
        #b7eade 80%
    );
}

.gradient-logsum-dark {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    background: linear-gradient(
        135deg,
        #b2ec38 20%,
        #61e97e 20% 40%,
        #00b5a1 40% 60%,
        #00768d 60% 80%,
        #002f61 80%
    );
}

.gradient-logsum-dark-expanded {
    width: 24px;
    height: 112px;
    border-radius: 5px;
    background: linear-gradient(
        180deg,
        #b2ec38 20%,
        #61e97e 20% 40%,
        #00b5a1 40% 60%,
        #00768d 60% 80%,
        #002f61 80%
    );
}

.gradient-population-expanded {
    width: 24px;
    height: 112px;
    border-radius: 5px;
    background: linear-gradient(
        180deg,
        #9a182a 20%,
        #b33736 20% 40%,
        #cd5f51 40% 60%,
        #dd9086 60% 80%,
        #e8c1b6 80%
    );
}

.gradient-population {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    background: linear-gradient(
        135deg,
        #9a182a 20%,
        #b33736 20% 40%,
        #cd5f51 40% 60%,
        #dd9086 60% 80%,
        #e8c1b6 80%
    );
}

.gradient-tooltip-container {
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 24px;
    width: 180px;
}

.no-animation {
    opacity: 1;
}

.bounce-enter-active {
    animation: bounce-in 5s;
}

@keyframes bounce-in {
    0% {
        opacity: 0.5;
    }
    10% {
        opacity: 1;
    }
    20% {
        opacity: 0.5;
    }
    30% {
        opacity: 1;
    }
    40% {
        opacity: 0.5;
    }
    50% {
        opacity: 0.5;
    }
    60% {
        opacity: 1;
    }
    70% {
        opacity: 0.5;
    }
    80% {
        opacity: 1;
    }
    90% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}
</style>

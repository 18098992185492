import { Source } from "ol/source";

class SimpleVectorSource extends Source {
        
  constructor(options) {
    options = options || {};

    super({
      attributions: options.attributions,
      interpolate: true,
      projection: undefined,
      state: 'ready',
      wrapX: options.wrapX !== undefined ? options.wrapX : true,
    });
    
    this.features_ = options.features;
  }

  forEachFeature(callback) {
    return this.features_.forEach(callback);
  }

  loadFeatures(extent, resolution, projection) {}

  clear() {
    this.features_ = [];
    this.dispatchEvent("clear");
  }
}

export default SimpleVectorSource;

<script setup>
import { onMounted } from "vue";
import { useScenarioInformationStore } from "@/store/ScenarioInformationStore";
import { useLogsumStore } from "@/store/LogsumStore";
import { storeToRefs } from "pinia";
import { useReportStore } from "@/store/ReportStore";
import SunburstWithCompareSelector from "./SunburstWithCompareSelector.vue";
const reportStore = useReportStore();

const { getReportVisible } = storeToRefs(reportStore);

const logsumStore = useLogsumStore();
const senarioStore = useScenarioInformationStore();

const mode = senarioStore.scenarioInformation.mode;

onMounted(() => {
    if (mode === "single") {
        logsumStore.initializeLogsumStore();
    }
});
</script>
<script>
export default {
    name: "ReportPanel",
};
</script>
<template>
    <div
        class="report-container"
        id="piposchart"
        v-show="getReportVisible"
        :class="[mode ? 'single' : 'compare']"
    >
        <div class="report-content">
            <SunburstWithCompareSelector></SunburstWithCompareSelector>
        </div>
    </div>
</template>
<style lang="scss">
.report-container {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 100;
    width: 547px;
    background: white;
    box-shadow: 0px 2px 1px -1px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
        0px 1px 1px 0px
            var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
        0px 1px 3px 0px
            var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.12));
    margin: 10px;
    border-radius: 10px;
    border: 5px white solid;
    max-height: 88vh;

    .report-content {
        display: grid;
        align-items: center;
        grid-auto-flow: row;
        overflow-y: auto;
        overflow-x: hidden;
        height: auto;
        width: 536px;
    }
}
@media (max-width: $mapgrid-breakpoint-2) {
    .report-container {
        grid-row: 2;
    }
}
</style>

import { defineStore } from "pinia";

export const useScenarioInformationStore = defineStore({
    id: "ScenarioInformationStore",
    state: () => ({
        scenarioInformation: {
            compareFromId: "2012",
            compareToId: "",
            mode: "single",
            scenarioId: "2020",
            singleId: "2020",
        },
    }),
    actions: {
        storeScenario(scenarioInformation) {
            this.scenarioInformation = scenarioInformation;

            sessionStorage.setItem(
                "scenarioId",
                scenarioInformation.scenarioId
            );
            sessionStorage.setItem(
                "scenarioInformation",
                JSON.stringify(this.scenarioInformation)
            );
        },
    },
    getters: {
        getScenarioInformation() {
            return this.scenarioInformation;
        },
        getIsCompare() {
            return this.scenarioInformation.mode == "compare";
        },
    },
});

<script setup>
import { ref, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import { MaIcon } from "@/utils/maIcons";
import { useMinasidorAreaStore } from "@/store/MinasidorAreaStore";
import { renderSavedSelection } from "@/composable/ol/selectInteraction.js";
const minaSidorStore = useMinasidorAreaStore();

const { savedAreas } = storeToRefs(minaSidorStore);

const props = defineProps({
    showSavedAreaPanel: {
        type: Boolean,
        required: false,
    },
});

let search = ref("");
let sorted = ref(false);

const headers = [
    { type: "type", name: "Typ", sorted: false },
    { type: "name", name: "Namn", sorted: false },
    { type: "date", name: "Datum", sorted: false },
];

const filteredRegions = computed(() => {
    return minaSidorStore.savedAreas
        ?.filter((x) =>
            x.name.toLowerCase().includes(search.value.toLowerCase())
        )
        .reverse((a, b) => (a["date"] > b["date"] ? 1 : -1));
});

const deleteArea = async (id) => {
    await minaSidorStore.deleteArea(id);
};
const orderBy = (type) => {
    if (sorted.value) {
        savedAreas.value.reverse((a, b) => (a[type] > b[type] ? 1 : -1));
        sorted.value = false;
    } else {
        savedAreas.value.sort((a, b) => (a[type] > b[type] ? 1 : -1));
        sorted.value = true;
    }
};
onMounted(async () => {
    await minaSidorStore.getAllSavedAreasForUser();
});
</script>
<script>
export default {
    name: "SavedAreaPanel",
};
</script>
<template>
    <div class="upload-table-wrapper" v-show="props.showSavedAreaPanel">
        <v-card style="margin-top: 1em" class="upload-table">
            <div class="panel-selector">
                <v-row>
                    <v-col cols="12">
                        <div class="caption-xs mb-1">
                            Hämta ett sparat område
                        </div>
                        <v-text-field
                            density="compact"
                            v-model="search"
                            :append-inner-icon="MaIcon.search"
                            label="Sök på område"
                            single-line
                            hide-details
                        ></v-text-field>
                        <v-table>
                            <thead>
                                <tr>
                                    <th
                                        :key="header.name"
                                        v-for="header in headers"
                                        @click="
                                            header.sorted = !header.sorted;
                                            orderBy(header.type);
                                        "
                                    >
                                        <span>{{ header.name }}</span>
                                        <v-icon
                                            class="_click"
                                            :icon="
                                                header.sorted
                                                    ? MaIcon['toggleDown']
                                                    : MaIcon['toggleUp']
                                            "
                                        />
                                    </th>
                                    <th>Ta bort</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    class="select"
                                    v-for="item in filteredRegions"
                                    :key="item.id"
                                >
                                    <td @click="renderSavedSelection(item.id)">
                                        <v-icon
                                            :icon="
                                                MaIcon[
                                                    item.properties.Type?.toLowerCase()
                                                ]
                                            "
                                        />
                                    </td>
                                    <td @click="renderSavedSelection(item.id)">
                                        {{ item.name }}
                                    </td>
                                    <td @click="renderSavedSelection(item.id)">
                                        {{
                                            new Date(
                                                item.date
                                            ).toLocaleDateString()
                                        }}
                                    </td>
                                    <td
                                        style="
                                            border-left: 1px solid #e0e0e0;
                                            text-align: center;
                                        "
                                    >
                                        <v-dialog width="500">
                                            <template
                                                v-slot:activator="{ props }"
                                            >
                                                <div v-bind="props">
                                                    <v-icon
                                                        class="_click"
                                                        :icon="MaIcon['delete']"
                                                    />
                                                </div>
                                            </template>

                                            <template
                                                v-slot:default="{ isActive }"
                                            >
                                                <v-card title="Ta bort område?">
                                                    <v-card-text>
                                                        Du håller på att ta bort
                                                        ett sparat område. Om du
                                                        är användare i både
                                                        Regionalanalys och
                                                        Serviceanalys kan du
                                                        öppna dina sparade
                                                        områden i båda
                                                        tjänsterna. Om du tar
                                                        bort ett område kommer
                                                        det därför också att tas
                                                        bort i båda tjänsterna.
                                                    </v-card-text>

                                                    <v-card-actions
                                                        class="px-5 pb-5"
                                                    >
                                                        <v-btn
                                                            variant="outlined"
                                                            text="Confirm"
                                                            class="text-red"
                                                            @click="
                                                                deleteArea(
                                                                    item.id
                                                                )
                                                            "
                                                        >
                                                            Ta bort område
                                                        </v-btn>
                                                        <v-spacer></v-spacer>
                                                        <v-btn
                                                            variant="outlined"
                                                            text="Confirm"
                                                            @click="
                                                                isActive.value = false
                                                            "
                                                        >
                                                            Avbryt
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </template>
                                        </v-dialog>
                                    </td>
                                </tr>
                                <tr v-if="filteredRegions.length === 0">
                                    <td colspan="6">
                                        Du har inga sparade områden
                                    </td>
                                </tr>
                            </tbody>
                        </v-table>
                    </v-col>
                </v-row>
            </div>
        </v-card>
    </div>
</template>
<style lang="scss">
.upload-table-wrapper {
    position: absolute;
    right: 3em;
    top: 16.7em;
    width: 350px;
    z-index: 200;
}
.panel-selector {
    .v-table__wrapper {
        overflow-y: scroll;
        overflow-x: hidden;
        max-height: 210px;
        height: auto;
    }
}
.upload-table {
    width: 350px;
    .v-table > .v-table__wrapper > table > tbody > tr > td {
        padding: 4px;
        height: 35px;
        font-size: 12px;
    }

    .v-table--density-default > .v-table__wrapper > table > thead > tr > th {
        height: 35px;
        font-size: 12px;
        padding-left: 4px;
        background-color: #8d8d8d;
        color: white;

        .v-icon {
            color: white;
        }
    }
    tbody {
        tr.select:hover td:not(:last-child) {
            background-color: lightgrey;
        }

        td {
            padding: 0 8px;
        }

        td.select:hover {
            cursor: pointer;
        }
    }
    .v-label {
        font-size: 12px;
    }
}
</style>

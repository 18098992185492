import { api } from "@/api";
import GeoJSON from "ol/format/GeoJSON";

const getScenarioId = () => sessionStorage.getItem("scenarioId") ?? "2021";

class LogsumService {
    async getCircle(circle, id) {
        const params = {
            x: circle.center[1],
            y: circle.center[0],
            radius: circle.radius,
            id,
            scenarioid: getScenarioId(),
        };
        return api.get("api/logsum/circle", { params });
    }

    async getPolygon(polygon, scenarioId, meansOfTransport, model = 1) {

        let geometry = null;
        let geoJson = null;
        if (polygon && Object.hasOwnProperty.call(polygon, "geometry")) {
            geometry = polygon.geometry;
        } else if (polygon != null) {
            geometry = polygon.getGeometry();
        }
        if (polygon) {
            geoJson = new GeoJSON().writeGeometryObject(
                geometry,
                "EPSG:3006",
                "EPSG:3006"
            ); // KANSKE tas bort eps
        }
        const mapping = {
            car: "car",
            bus: "transit",
            walk: "walk",
            bike: "bicycle",
        };
        meansOfTransport = meansOfTransport.map((mode) => mapping[mode]);
        const body = {
            ScenarioId: scenarioId,
            Geometry: geoJson,
            MeansOfTransport: meansOfTransport,
        };

        if (model === 2) {
            return api.post("api/accessibility/model2", body);
        }

        return api.post("api/accessibility/model1", body);
    }

    async getAll(id) {
        const params = {
            id,
            scenarioid: getScenarioId(),
        };
        return api.get("api/logsum/all", { params });
    }

    async getErrands() {
        return api.get("api/logsum/errands", {});
    }

    async getLayer(travelReasons, meansOfTransport, low = 0.001, high = 0.999, model = 1) {      
        const mapping = {
            car: "car",
            bus: "transit",
            walk: "walk",
            bike: "bicycle",
        };
        meansOfTransport = meansOfTransport.map((mode) => mapping[mode]);
        const params = {
            travelReasons: travelReasons.join(","),
            meansOfTransport: meansOfTransport.join(","),
            low,
            high,
            contours: 5,
            scenarioid: getScenarioId(),
        };
        if (model == 2) {
            return await api.get("davinci/LogsumIndex", { params });
        }
        return await api.get("davinci/AccessibilityIndex", { params });
    }
}

export default new LogsumService();

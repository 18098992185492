<script setup>
import { ref } from "vue";
import { MaIcon as Icon } from "@/utils/maIcons";
let showTooltip = ref(false);
const props = defineProps({
    text: {
        type: String,
        required: true,
    },
    location: {
        type: String,
        required: false,
    },
});

function render(text) {
    return text;
}
</script>
<script>
export default {
    name: "InformationPopUp",
};
</script>
<template>
    <div class="information-popup-container">
        <v-tooltip
            :max-width="300"
            v-model="showTooltip"
            open-on-click
            :open-on-hover="false"
            @click:outside="showTooltip = false"
            :location="props.location"
        >
            <template v-slot:activator="{ props }">
                <v-btn
                    icon
                    v-bind="props"
                    variant="plain"
                    :ripple="false"
                    width="20"
                    height="20"
                >
                    <v-icon class="info" :icon="Icon['questionMark']" />
                </v-btn>
            </template>
            <div v-html="render(props.text)"></div>
        </v-tooltip>
    </div>
</template>
<style lang="scss">
.information-popup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .v-icon {
        cursor: pointer;
        font-size: 1.2em;
    }
}
</style>

<template class="">
    <TopNavigation></TopNavigation>
    <TopNavigationMenu></TopNavigationMenu>
    <LoaderSpinner v-show="showLoading"> </LoaderSpinner>
    <div style="position: relative">
        <div class="map-grid" :class="{ 'panel-active': getReportVisible }">
            <ReportPanel></ReportPanel>
            <SearchBar
                @searchResultSelected="searchResultSelected"
                @searchResultDeselected="searchResultDeselected"
            ></SearchBar>
        </div>
    </div>
    <OlMap></OlMap>
    <!-- <DashBoard v-if="showDashboard" :type="dashboardEventType"></DashBoard> -->
    <div
    class="tillvaxtverket-logga"
    :class="{ inverted: getMapMode == 'dark' }"
    ></div>
    <MapLegend></MapLegend>
</template>

<script>
import OlMap from "@/components/OlMap.vue";
import LoaderSpinner from "@/components/LoaderSpinner.vue";
import MapLegend from "@/components/legend/MapLegend.vue";
import ReportPanel from "@/components/report/ReportPanel.vue";
import TopNavigation from "@/components/navigation/TopNavigation.vue";
import SearchBar from "@/components/SearchBar.vue";
// import DashBoard from "@/components/dashboard/DashBoard.vue";

import searchMixin from "@/composable/ol/searchMixin";

import { mapGetters, mapState as mapStateVuex } from "vuex";
import TopNavigationMenu from "@/components/navigation/TopNavigationMenu.vue";
import { useMapStore } from "@/store/MapStore";
import { mapState } from "pinia";
import { useReportStore } from "@/store/ReportStore";
import { useCategoryStore } from "@/store/CategoryStore";

export default {
    name: "MapView",
    props: ["keycloak"],
    data() {
        return {
            dashboardEventType: "",
        };
    },
    mixins: [searchMixin],
    components: {
        OlMap,
        MapLegend,
        ReportPanel,
        TopNavigation,
        LoaderSpinner,
        SearchBar,
        // DashBoard,
        TopNavigationMenu,
    },
    computed: {
        ...mapStateVuex({
            showLoading: (state) => state.overview.showLoading,
        }),
        ...mapGetters("dashboard", ["showDashboard"]),
        ...mapGetters("logsum", ["scenarioInformation"]),
        ...mapGetters("overview", ["overview"]),
        ...mapState(useMapStore, ["getMapMode"]),
        ...mapState(useReportStore, ["getReportVisible"]),
    },
    beforeCreate() {
        const CategoryStore = useCategoryStore();   
        CategoryStore.getCategories();
    },
    mounted() {
        if (document.body) {
            document.body.classList.remove("start");
        }
        this.emitter.on("map-interactions-clear", async (data) => {
            if (data.sender !== this) {
                if (this.$refs.searchInput) {
                    await this.$refs.searchInput.clearSearch();
                }
            }
        });
    },
    methods: {
        searchResultSelected: function (geoJson) {
            this.emitter.emit("map-interactions-clear", { sender: this });
            this.clearLayerAndInteractions();
            this.showLocation(geoJson);
        },
        searchResultDeselected: function () {
            this.clearLayerAndInteractions();
        },
    },
};
</script>
<style lang="scss">
.flex-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-flow: column wrap;
    align-content: space-between;
}
.map-grid {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 0px auto auto;
    grid-template-rows: calc(100vh - 110px);
    position: absolute;
    width: 100%;
}
.panel-active {
    grid-template-columns: 470px auto auto;
}
@media (max-width: $mapgrid-breakpoint-2) and (min-width: $mapgrid-breakpoint-3) {
    .map-grid {
        grid-auto-flow: row;
        grid-auto-columns: auto;
        grid-template-rows: 70px 75vh;
    }
    .panel-active {
        grid-auto-columns: 470px;
        grid-template-columns: 1fr;
    }
}

@media (max-width: $mapgrid-breakpoint-3) {
    .map-grid {
        grid-auto-flow: row;
        grid-auto-columns: auto;
        grid-template-rows: 70px 70vh;
    }
    .panel-active {
        grid-auto-columns: 470px;
        grid-template-columns: 1fr;
    }
}

@media (max-width: $mapgrid-breakpoint-1) and (min-width: $mapgrid-breakpoint-2) {
    .map-grid {
        grid-template-rows: calc(100vh - 160px);
    }
}

@media (max-width: $mapgrid-breakpoint-4) {
    .map-grid {
        grid-template-rows: 70px calc(100vh - 290px);
    }
}
</style>

<script setup>
import { watch, ref } from "vue";
import SearchService from "@/services/SearchService";
import { MaIcon as Icon } from "@/utils/maIcons";
import { useMapStore } from "@/store/MapStore.js";
const mapStore = useMapStore();
let items = ref([]);
let search = ref("");
let select = ref(null);
const loading = ref(false);
const props = defineProps({
    hint: {
        type: String,
        default: "",
    },
    label: {
        type: String,
        default: "Panorera till län, kommun eller tätort",
    },
});

watch(search, async (val) => {
    val && val !== select.value && (await querySelections(val));
});

watch(select, async (val) => itemSelected(val));

const querySelections = async (text) => {
    loading.value = true;
    const response = await SearchService.searchByPattern(text);
    items.value = response.data;
    loading.value = false;
};
const emit = defineEmits(["searchResultSelected", "searchResultDeselected"]);

const itemSelected = async (item) => {
    if (item) {
        const response = await SearchService.getDetails(item.type, item.id);
        if (response && response.data) {
            emit("searchResultSelected", response.data);
            mapStore.searchGeojson = response.data;
            return;
        }
    }
};
const clear = () => {
    emit("searchResultDeselected");
};
</script>

<template>
    <div class="search-wrapper">
        <v-autocomplete
            v-model="select"
            v-model:search="search"
            :loading="loading"
            :items="items"
            :no-filter="true"
            item-value="id"
            item-title="name"
            class="search"
            variant="solo"
            hide-no-data
            :eager="true"
            clearable
            @click:clear="clear"
            :hint="props.hint"
            density="compact"
            return-object
            prepend-inner-icon="mdi-magnify"
            :label="props.label"
        >
            <template v-slot:item="{ props, item }">
                <v-list-item
                    v-if="!loading"
                    v-bind="props"
                    :prepend-icon="Icon[item?.raw?.type]"
                    :title="
                        item?.raw?.name +
                        (item?.raw?.type === 'locality' ? ' tätort' : '')
                    "
                    :subtitle="item?.raw?.location"
                ></v-list-item>
                <v-skeleton-loader
                    v-else
                    type="list-item-two-line"
                    :loading="loading"
                    :transition="false"
                ></v-skeleton-loader>
            </template>
        </v-autocomplete>
    </div>
</template>

<style lang="scss">
.search-wrapper {
    width: 320px;
    margin: 10px auto 0;
    grid-column: 2;

    i.mdi-close-circle {
        font-size: 1.1em;
    }
}

@media (max-width: $mapgrid-breakpoint-2) {
    .search-wrapper {
        grid-column: 1;
        grid-row: 1;
    }
}

.search {
    text-align: center;
    z-index: 100;
    position: relative;

    .v-field .v-field__input {
        font-size: 14px;
    }
    .v-label {
        font-size: 0.9rem;
    }
}
</style>

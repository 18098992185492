<template>
    <div class="logsum-wrapper">
        <div class="logsum-header">
            <div class="logsum-legend-wrapper">
                <div class="selectionLegend"></div>
                <div class="trim">{{ getSavedName }}</div> 
                <div>{{ totalLogsum.value }}</div>
            </div>
            <div class="logsum-legend-wrapper">
                <div class="trim">{{ getComparisonName }}</div>
                <div>{{ totalLogsum.value2 }}</div>
                <div class="comparisonLegend"></div>
            </div>
        </div>
        <div style="position: absolute; top: 5px; right: -1px">
            <InformationPopUp :text="Texts.helpInformation.no8.text">
            </InformationPopUp>
        </div>
        <div class="reference-name">Riket</div>
        <div class="reference"></div>
        <TotalGauge :value="totalLogsum"></TotalGauge>
        
        <v-select 
            v-model="sortBy"
            :items="sortOptions"
            :density="'compact'"
            :center-affix="true"
            label="Sortera efter"
            variant="underlined"
            hide-details
            return-object
            :single-line="true"
            style="width: 240px; margin-left: auto; margin-bottom: 30px;"
        ></v-select>
        <TransitionGroup tag="div" name="list" v-if="logsums">
            <div
                v-for="category in logsums"
                :key="category.id"
                class="category"
            >
                <LogsumThermometer :category="category"></LogsumThermometer>
            </div>
        </TransitionGroup>
    </div>
</template>

<script setup>
import LogsumThermometer from "./LogsumThermometer.vue";
import InformationPopUp from "@/components/general/InformationPopUp.vue";
import TotalGauge from "./TotalLogsumGauge.vue";
import { storeToRefs } from "pinia";
import { useCategoryStore } from "@/store/CategoryStore.js";
import { computed } from "vue";
import { useReportStore } from "@/store/ReportStore";
import Texts from "@/texts/texts.json";
import { ref } from "vue";

const reportStore = useReportStore();
const getSavedName = computed(() => {
    if (reportStore.activeArea && reportStore.activeArea.name) {
        return reportStore.activeArea.name;
    }
    return 'Eget område';
});
const { getComparisonName } = storeToRefs(reportStore);
const categoryStore = useCategoryStore();
const { categories } = storeToRefs(categoryStore);

const clamp = function(value, min, max) {
  return Math.min(Math.max(value, min), max);
};

const invlerp = function(value, min, max) {
    return (clamp(value, min, max) - min) / (max - min);
}

const logsum_therm_value = function(selection,  comparison) {
    if(selection < comparison) {
        return invlerp(selection, 0.0, comparison) * 50.0;
    } else {
        return invlerp(selection, comparison, 1.0) * 50.0 + 50.0;
    }
}

const props = defineProps({
    selection: {
        type: Object,
        required: true,
    },
    comparison: {
        type: Object,
    },
    sweden: {
        type: Object,
        required: true,
    },
});

const totalLogsum = computed(() => {
    let totalSelection = 0;
    for (let i = 0; i < props.selection.length; i++) {
        totalSelection += props.selection[i].index;
    }
    totalSelection = totalSelection / props.selection.length;

    let totalComparison = 0;
    let comparison = props.comparison;
    if (comparison == null || comparison.length == 0) {
        comparison = props.sweden;
    }
    for (let i = 0; i < comparison.length; i++) {
        totalComparison += comparison[i].index;
    }

    totalComparison = totalComparison / comparison.length;

    return {
        degrees: logsum_therm_value(totalSelection, totalComparison) * 1.80,
        value: (totalSelection * 100.0).toFixed(1),
        value2: (totalComparison * 100.0).toFixed(1),
    };
});

const logsums = computed(() => {
    if (!categories.value) return null;

    const returner = categories.value
        .map((category) => {
            const forms = category.forms
                .map((form) => {
                    let selectionSum = { index: 0, value: 0 };
                    if (props.selection && props.selection.length > 0) {
                        selectionSum = props.selection.find(
                            (s) => s.name === form.id
                        );
                    }

                    let comparisonSum = { index: 0, value: 0 };
                    if (props.comparison && props.comparison.length > 0) {
                        comparisonSum = props.comparison.find(
                            (c) => c.name === form.id
                        );
                    } else {
                        if (props.sweden && props.sweden.length > 0) {
                            comparisonSum = props.sweden.find(
                                (s) => s.name === form.id
                            );
                        }
                    }
                   
                    let swedenSum = { index: 0, value: 0 };
                    if (props.sweden && props.sweden.length > 0) {
                        swedenSum = props.sweden.find((s) => s.name === form.id);
                    }

                    return {
                        ...form,
                        value: logsum_therm_value(selectionSum.index, swedenSum.index),
                        value2: logsum_therm_value(comparisonSum.index, swedenSum.index),
                        selectionIndex: selectionSum.index,
                        comparisonIndex: comparisonSum.index,
                        swedenIndex: swedenSum.index,
                    };
                })
                .sort((a, b) => b.value - a.value);

            return {
                id: category.id,
                name: category.name,
                value: calculateTotalLogsum(forms, "selectionIndex"),
                thermometerValue: calculateTotalThermometer(forms, "selectionIndex"),
                value2: calculateTotalLogsum(forms, "comparisonIndex"),
                thermometerValue2: calculateTotalThermometer(forms, "comparisonIndex"),
                forms: forms
                    .map((form) => {
                        return {
                            id: form.id,
                            name: form.name,
                            value: form.value,
                            value2: form.value2,
                            otherYear: form.otherYear,
                            mouseOverText: form.mouseOverText,
                        };
                    })
                    .sort((a, b) => b.value - a.value),
            };
        })
        .sort((a, b) => sortFunction(a, b));

    return returner;
});

const calculateTotalLogsum = (forms, key) => {
    let totalSelection = 0;
    for (let i = 0; i < forms.length; i++) {
        totalSelection += forms[i][key];
    }
    totalSelection = totalSelection / forms.length;

    return (totalSelection * 100.0).toFixed(1);// ((totalSelection / (totalComparison * 2)) * 100).toFixed(2);
};

const calculateTotalThermometer = (forms, key) => {
    let totalSelection = 0;
    for (let i = 0; i < forms.length; i++) {
        totalSelection += forms[i][key];
    }
    totalSelection = totalSelection / forms.length;

    let totalComparison = 0;
    for (let i = 0; i < forms.length; i++) {
        totalComparison += forms[i].swedenIndex;
    }
    totalComparison = totalComparison / forms.length;

    return logsum_therm_value(totalSelection, totalComparison);// ((totalSelection / (totalComparison * 2)) * 100).toFixed(2);
};

const sortOptions = [
    { title: "Eget område (högt till lågt)", value: "selectionDesc"},
    { title: "Eget område (lågt till högt)", value: "selectionAsc"},
    { title: "Jämförelseområde (högt till lågt)", value: "comparisonDesc"},
    { title: "Jämförelseområde (lågt till högt)", value: "comparisonAsc"},
    { title: "Störst skillnad", value: "differenceDesc"},
    { title: "Minst skillnad", value: "differenceAsc"},
];

const sortBy = ref(sortOptions[0]);

const sortFunction =  (a,b) => {
    const sortByValue = sortBy.value.value;
    if (sortBy.value == null || sortByValue == "selectionDesc") {
        return b.thermometerValue - a.thermometerValue;
    }
    if (sortByValue == "selectionAsc") {
        return a.thermometerValue - b.thermometerValue;
    }
    if (sortByValue == "comparisonDesc") {
        return b.thermometerValue2 - a.thermometerValue2;
    }
    if (sortByValue == "comparisonAsc") {
        return a.thermometerValue2 - b.thermometerValue2;
    }
    if (sortByValue == "differenceDesc") {
        return Math.abs(b.thermometerValue2 - b.thermometerValue) - Math.abs(a.thermometerValue2 - a.thermometerValue);
    }
    if (sortByValue == "differenceAsc") {
        return Math.abs(a.thermometerValue2 - a.thermometerValue) - Math.abs(b.thermometerValue2 - b.thermometerValue);
    }
}
</script>

<style lang="scss">
.logsum-wrapper {
    position: relative;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
}
.logsum-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 30px;
    position: relative;

    h3 {
        font-size: 16px;
    }
}
.reference-name {
    text-align: center;
    z-index: 1;
    top: 210px;
    position: relative;
    font-size: 16px;
    font-weight: 500;
}
.category {
    margin-bottom: 15px;
    margin-top: 15px;
}

.list-move, /* apply transition to moving elements */
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.list-leave-active {
    position: absolute;
}

.reference {
    position: absolute;
    width: 50%;
    height: calc(100% - 240px);
    border-right: 1px #00000044 solid;
    z-index: 1;
    top: 260px;
}

.selectionLegend {
    width: 20px;
    height: 20px;
    border: 1px #000 solid;
    border-radius: 5px;
    background: #f7d7a7;
    float: left;
    margin-right: 5px;
    margin-top: 2px;
}

.comparisonLegend {
    width: 20px;
    height: 20px;
    border: 1px #000 solid;
    border-radius: 5px;
    background: #ebebeb;
    float: right;
    margin-left: 5px;
    margin-top: 2px;
}

.logsum-legend-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.trim {
    max-width: 170px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>

import { Category_sv, Category_en } from "@/constants";
export default {
  state: {
    showDashboard: false,
    dashboardTitle: "",
    dashboardEventType: "",
    selectedErrandType: "",
  },
  mutations: {
    setDashboardVisibility(state, value) {
      state.showDashboard = value;
    },
    setDashboardTitle(state, value) {
      const title_sv = Category_sv[value];
      if (title_sv) {
        state.dashboardTitle = title_sv;
      } else {
        state.dashboardTitle = value;
      }
    },
    setDashboardEventType(state, value) {
      state.dashboardEventType = value;
    },
    setSelectedErrandType(state, value) {
      switch (value) {
        case "Arbetsmarknad":
          state.selectedErrandType = Category_en.WORKPLACE;
          break;
        case "Utbildning":
          state.selectedErrandType = Category_en.EDUCATION;
          break;
        case "Kommersiell service":
          state.selectedErrandType = Category_en.COMMERCIALSERVICE;
          break;
        case "Offentlig service":
          state.selectedErrandType = Category_en.PUBLICSERVICE;
          break;
        case "Sjukvård":
          state.selectedErrandType = Category_en.HEALTCARE;
          break;
        case "Kultur och föreningsliv":
          state.selectedErrandType = Category_en.CULTURE;
          break;
        case "Rekreation":
          state.selectedErrandType = Category_en.RECREATION;
          break;
        default:
          state.selectedErrandType = value;
      }
    },
  },

  getters: {
    dashboardTitle: (state) => state.dashboardTitle,
    showDashboard: (state) => state.showDashboard,
    dashboardEventType: (state) => state.dashboardEventType,
    selectedErrandType: (state) => state.selectedErrandType,
  },
  namespaced: true,
};

<template>
    <div id="countdown-wrap">
        <div class="button-wrap">
            <v-btn :icon="MaIcon[externalProps.category.id]" variant="text" size="large" class="expand-button" @click="handleExpand" v-bind:class="externalProps.category.id"></v-btn>
            <v-icon v-if="state.expanded" class="expand-indicator" icon="mdi-chevron-up" @click="handleExpand"></v-icon>
            <v-icon v-else class="expand-indicator" icon="mdi-chevron-down" @click="handleExpand"></v-icon>
        </div>

        <div id="goal">
            <v-tooltip v-if="externalProps.category.forms.some(form => (!!form.otherYear))"
                text="Data saknas för en eller fler reseanledningar. Denna kategori är simulerad med data från andra år."
                :max-width="300"
            >
                <template v-slot:activator="{ props }">
                    <span>{{externalProps.category.name}} &nbsp;</span>
                    <v-icon class="text-red" v-bind="props" :size="'small'">mdi-information-outline</v-icon>
                </template>
            </v-tooltip>
            <div v-else>{{externalProps.category.name}}</div>
        </div>
        <div id="glass" v-bind:class="externalProps.category.id">
            <div class="progress-container">
                <div class="progress-prepend"></div>
                <div class="progress" :style="calculatedWidth(externalProps.category.thermometerValue)">
                    <p :class="{
                        outer: ((externalProps.category.thermometerValue)) < 7,
                        }">{{ ((externalProps.category.value)) }}</p>
                </div>
            </div>
        </div>
        <div id="glass" v-bind:class="externalProps.category.id" class="glass2">
            <div class="progress-container">
                <div class="progress-prepend2"></div>
                <div class="progress progress2" :style="calculatedWidth(externalProps.category.thermometerValue2)">
                    <p :class="{
                        outer: ((externalProps.category.thermometerValue2)) < 7,
                        }">{{ ((externalProps.category.value2)) }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="expand-wrapper" v-if="state.expanded">
        <div v-for="form in externalProps.category.forms" :key="form.id">
            <div class="sublabel">
                <v-tooltip :text="form.mouseOverText">
                    <template v-slot:activator="{ props }">
                        <div v-bind="props">{{ form.name }}</div>
                    </template>
                </v-tooltip>
                <v-tooltip 
                    v-if="form.otherYear"
                    :text="`Data saknas för nuvarande scenario. Denna reseanledning är simulerad med data från ${form.otherYear}.`"
                    :max-width="300"
                >
                    <template v-slot:activator="{ props }">
                        <v-icon class="text-red" v-bind="props" :size="'small'">mdi-information-outline</v-icon>
                    </template>
                </v-tooltip>
            </div>
            <div class="subglass">
                <div class="subprogress" :style="calculatedWidth(form.value)"></div>
            </div>
            <div class="subglass subglass2">
                <div class="subprogress subprogress2" :style="calculatedWidth(form.value2)"></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive } from 'vue';
import { MaIcon } from '@/utils/maIcons';

const externalProps = defineProps({
    category: {
        type: Object,
        required: true,
    },
});

const state = reactive({
    expanded: false,
});

const handleExpand = () => {
    state.expanded = !state.expanded;
}

const calculatedWidth = (value) => {
    value = Math.max(0, Math.min(100, value));

    return { width: `${value}%` };
}

</script>

<style lang="scss" scoped>

.progress-container {
    display: grid;
    grid-template-columns: 20px auto 20px;
    width: 100%;
}
.progress-prepend {
    background-color: #f7d7a7;
}
.progress-prepend2 {
    background-color: #ebebeb;
}
.button-wrap {
    position: relative;
    z-index: 3;
    left: -10px;
}
#countdown-wrap {
  width: 100%;
  max-height: 60px;
  position: relative;
}

.glass2 {
    top: 30px !important;
    // border-radius: 0 0 16px 16px !important;
    border-radius: 0 0 24px 24px !important;

}
#glass {
  width: calc(100% - 60px);
  height: 24px;
  background: #fff;
  border-radius: 24px 24px 0 0;
  border: 1px #000 solid;
  overflow: hidden;
  position: absolute;
  top: 7px;
  left: 30px;

}

.progress {
    height: 24px;
    background: #f7d7a7;

    &.disabled {
        background: #ccc !important;
    }

    p {
        text-align: right;
        color: #000;
        padding-right: 5px;
        bottom: 1px;
        position: relative;
        z-index: 2;

        &.outer {
            padding-left: 5px;
            padding-right: 0px !important;
            color: #000;
        }
    }
}

.progress2 {
    background: #ebebeb !important;
}

#goal {
    font-size: 14px;
    text-align: right;
    color: #000;
    position: absolute;
    right: 30px;
    top: -15px;

    @media only screen and (max-width : 640px) {
        text-align: center;  
    }

    i {
        z-index: 3;
    }
}


.expand-button {
  background-color: #007398;
  color: #fff;
  border: 3px#fff solid;
//   margin-left: 10px;
  box-shadow: 1px 2px 5px #00000099;


  &.workplace {
    background: $workplace !important;
    }
    &.education {
        background: $education !important;
    }
    &.culture {
        background: $culture !important;
    }
    &.public_service {
        background: $public_service !important;
    }
    &.recreation {
        background: $recreation !important;
    }
    &.commercial_service {
        background: $commercial_service !important;
    }
    &.healthcare {
        background: $healthcare !important;
    }
}

.expand-indicator {
  margin-left: -40px;
  color: #fff;
  margin-top: 32px;
  z-index: 2;
}

.expand-wrapper {
    position: relative;
    padding: 0 30px;
}

.subglass {
    width: 100%;
    height: 12px;
    background:#fff;
    border-radius: 12px 12px 0 0;
    border: 1px #000000 solid;
    overflow: hidden;

    &.subglass2 {
        top: -1px !important;
        position: relative;
        border-radius: 0 0 12px 12px !important;
    }
}

.subprogress {
    height: 12px;
    background: #f7d7a7;

    &.disabled {
        background: #ccc !important;
    }

    &.subprogress2 {
        background: #ebebeb !important;
    }
}

.sublabel {
    position: relative;
    font-size: 12px;
    display: flex;
    gap: 5px;
    align-items: center;
    z-index: 2;

    i {
        z-index: 3;
    }
}
</style>
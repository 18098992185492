export function CalculationHelper() {
  function getAreaFormated(drawObject) {
    const options = { projection: "EPSG:3006" };
    const temp = drawObject.getArea(options);
    let area = formatArea(temp);
    return area;
  }

  function getArea(drawObject) {
    const options = { projection: "EPSG:3006" };
    return drawObject.getArea(options);
  }

  function formatArea(area) {
    if (typeof area === "string") {
      area = parseInt(area);
    }
    let output;
    output = (Math.round((area / 1000000) * 100) / 100).toFixed(1);
    let temp = output.toString().replace(".", ",");
    return temp + " km²";
  }
  return { formatArea, getArea, getAreaFormated };
}

<script setup>
import { useUserStore } from "@/store/UserStore";
import { useKeycloakStore } from "@/store/KeycloakStore";
const userStore = useUserStore();
const keycloakStore = useKeycloakStore();

const send = () => {
    userStore.sendMail();
};
const logOut = () => {
    keycloakStore.logOut();
};
</script>

<template>
    <div>
        <v-container class="unauthorized" v-show="!userStore.sent">
            <v-row no-gutters>
                <v-col
                    cols="10"
                    lg="6"
                    align-self="center"
                    style="padding: 2em"
                    v-if="userStore.errorMessage === ''"
                >
                    <h1 class="pt-7">
                        Bara ett steg kvar för att få behörighet till
                        Regionalanalys…
                    </h1>
                    <p class="pt-7">
                        Klicka på knappen nedan för att ansöka om behörighet. Du
                        kommer att få ett mail när vi hanterat din ansökan.
                    </p>
                    <p class="pt-7">
                        Regionalanalys riktar sig till <b>offentlig sektor</b>.
                        Är du studerande eller konsult som arbetar med ett
                        uppdrag för offentlig sektor kan du få tidsbegränsad
                        behörighet. Vi kommer då att återkomma med några frågor
                        innan vi kan bedöma din ansökan. Kan du inte få
                        behörighet tas ditt Pipos-konto bort.
                    </p>
                    <p class="pt-7">
                        Om du ångrar dig och inte vill ansöka om behörighet till
                        någon av Pipos tjänster kan du kontakta
                        <a
                            target="_blank"
                            class="no-link"
                            style="text-decoration: underline"
                            href="https://pipos.se/vara-tjanster/regionalanalys/support---regionalanalys"
                        >
                            vår support</a
                        >
                        så tar vi bort ditt Pipos-konto helt.
                    </p>

                    <div class="pt-7">
                        <v-btn
                            variant="tonal"
                            class="send"
                            size="large"
                            @click="send"
                            >Skicka ansökan om behörighet till
                            Regionalanalys</v-btn
                        >
                    </div>

                    <v-btn variant="plain" @click="logOut" class="link">
                        &lt; Tillbaka till inloggningen</v-btn
                    >
                </v-col>
                <v-col
                    cols="10"
                    align-self="center"
                    style="padding: 2em"
                    v-else
                >
                    <div>
                        <p class="pt-7">
                            {{ userStore.errorMessage }}
                        </p>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <v-container class="unauthorized" v-show="userStore.sent">
            <v-row no-gutters>
                <v-col
                    cols="10"
                    lg="6"
                    align-self="center"
                    style="padding: 2em"
                >
                    <div>
                        <h1>Tack för din ansökan!</h1>
                        <p class="pt-7 pb-9">
                            Du kommer få ett mail av oss när vi har behandlat
                            din ansökan.
                        </p>

                        <v-btn variant="plain" @click="logOut" class="link">
                            &lt; Tillbaka till inloggningen</v-btn
                        >
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style lang="scss">
@import "@/assets/styles/project/_variables.scss";

.unauthorized {
    .link {
        text-decoration: none;
        text-transform: none;
        color: #404040;
        font-size: 13px;
        font-weight: 500;
        display: flex;
        padding: 0;
    }
    h1 {
        font-weight: 400;
        font-size: 1.5em;
    }
    p {
        font-size: 1.1em;
    }
    .send {
        background-color: $tillvaxtverket-green;
        color: white;
        border-radius: 0;

        .v-btn__content {
            font-weight: 400;
            font-style: normal;
            text-transform: none;
            letter-spacing: normal;
        }
    }
}
</style>

import { defineStore } from "pinia";
import CategoryService from "@/services/CategoryService";
import { useScenarioInformationStore } from "./ScenarioInformationStore";

const categoryMapping = {
  "Arbetsmarknad": "workplace",
  "Utbildning": "education",
  "Kommersiell service": "commercial_service",
  "Offentlig service": "public_service",
  "Sjukvård": "healthcare",
  "Fritid": "recreation",
}

export const useCategoryStore = defineStore("categoryStore", {
  id: "CategoryStore",
  state: () => ({
    categories: [],
    logSumModel: 1,
    logSumTravelModes: ["car", "bus", "bike", "walk"],
  }),
  actions: {
    async getCategories() {
      const response = await CategoryService.getCategories();
      let dummy = [];
      if(response.status === 200) {
        const senarioStore = useScenarioInformationStore();
        const scenario = senarioStore.scenarioInformation.scenarioId;

        const categories = [...new Set(response.data.map(item => item.category))];
        for (const category of categories) {
          const forms = response.data.filter(item => item.category === category).map((form) => {
            return {
              id: form.dataname,
              name: form.travel_reason,
              mouseOverText: form.mouse_over_text,
              checkedPoint: false,
              checkedArea: false,
              otherYear: ((form[`scenario${scenario}`] == scenario) ? false : form[`scenario${scenario}`]),
              order: form.internal_category_order
            }
          }).sort((a, b) => { return a.order - b.order });
          dummy.push({
            id: categoryMapping[category],
            name: category,
            forms: forms
          });
        }
      } else {
        console.error("Unable to get categories");
      }
      this.categories = dummy.sort((a, b) => { return a.name.localeCompare(b.name) });
    }
  },
  getters: {
    getActiveCategories: (state) => {
      return state.categories.filter((category) => {
        return category.forms.some((form) => {
          return form.checkedPoint || form.checkedArea;
        });
      });
    },
    getActiveAreaCategories: (state) => {
      return state.categories.filter((category) => {
        return category.forms.some((form) => {
          return form.checkedArea;
        });
      });
    },
    getActivePointCategories: (state) => {
      return state.categories.filter((category) => {
        return category.forms.some((form) => {
          return form.checkedPoint;
        });
      });
    },
    getLogSumModel: (state) => {
      return state.logSumModel;
    },
    getLogSumTravelModes: (state) => {
      return state.logSumTravelModes;
    },
    getCategoryForTravelReason: (state) => {
      return (travelReason) => { 
        const category = state.categories.find((category) => {
          for (const element of category.forms) {
            if (element.id === travelReason) {
              return true;
            } 
          }
          return false;
        }); 
        return category.id;
      }
    },
  },
});

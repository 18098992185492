<template>
    <div class="map-tools">
        <v-tooltip text="Växla mellan mörk eller ljus karta" :max-width="300">
            <template v-slot:activator="{ props }">
                <v-btn
                    class="menu-btn"
                    v-bind="props"
                    @click="switchMapMode"
                    :class="{ active: mapMode == 'dark' }"
                >
                    <v-icon>mdi-theme-light-dark</v-icon>
                </v-btn>
            </template>
        </v-tooltip>
    </div>
</template>
<script setup>
import { useMapStore } from "@/store/MapStore.js";
import { storeToRefs } from "pinia";
const { mapMode } = storeToRefs(useMapStore());
const mapStore = useMapStore();
const switchMapMode = () => {
    mapMode.value = mapMode.value == "light" ? "dark" : "light";

    mapStore.reRenderSearchLayer();
};
</script>
<style lang="scss" scoped>
.map-tools {
    margin-top: auto;
}
</style>

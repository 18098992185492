import axios from "axios";

let keycloak = undefined;

function setKeycloak(keycloakInstance) {
  keycloak = keycloakInstance;
}

const api = axios.create();


api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status || 403 === error.response.status) {
      window.location.href = "/unauthorized";
    } else {
      return Promise.reject(error);
    }
  }
);

api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${keycloak.token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { api, setKeycloak };

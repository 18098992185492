import ProfileDataService from "@/services/ProfileDataService";

export default {
  state: {
    profileDataDict: {},
    metadata: {},
    selectedProfileData: {},
    comparisonArea: "",
    categories: {},
    map: {},
  },
  mutations: {
    changeProfileDataDict(state, data) {
      state.profileDataDict = data;
    },
    changeMetadata(state, data) {
      state.metadata = { ...data };
    },
    changeSelectedProfileData(state, data) {
      state.selectedProfileData = { ...data };
    },
    changeComparisonArea(state, polygon) {
      state.comparisonArea = polygon;
    },
    changeCategories(state, categories) {
      state.categories = categories;
    },
    mapSet(state, map) {
      state.map = map;
    },
  },
  actions: {
    async initializeProfileData({ commit }) {
      const response = await ProfileDataService.getProfileData();

      // Convert array to dict with kn as key
      const converted = Object.assign(
        {},
        ...response.data.map((x) => ({ [x.kn]: x }))
      );
      commit("changeProfileDataDict", converted);

      const minEducationRatio = Math.min(
        ...response.data.map((o) => o.education_ratio),
        Infinity
      );
      const maxEducationRatio = Math.max(
        ...response.data.map((o) => o.education_ratio),
        0
      );

      const minAvgAge = Math.min(
        ...response.data.map((o) => o.avg_age),
        Infinity
      );
      const maxAvgAge = Math.max(...response.data.map((o) => o.avg_age), 0);

      const minForeignBorn = Math.min(
        ...response.data.map((o) => o.foreign_born_ratio),
        Infinity
      );
      const maxForeignBorn = Math.max(
        ...response.data.map((o) => o.foreign_born_ratio),
        0
      );

      const minBirths = Math.min(
        ...response.data.map((o) => o.births_per_1000_people),
        Infinity
      );
      const maxBirths = Math.max(
        ...response.data.map((o) => o.births_per_1000_people),
        0
      );

      const minDeaths = Math.min(
        ...response.data.map((o) => o.deaths_per_1000_people),
        Infinity
      );
      const maxDeaths = Math.max(
        ...response.data.map((o) => o.deaths_per_1000_people),
        0
      );

      commit("changeMetadata", {
        education_ratio: {
          min: minEducationRatio,
          max: maxEducationRatio,
        },
        avg_age: {
          min: minAvgAge,
          max: maxAvgAge,
        },
        foreign_born_ratio: {
          min: minForeignBorn,
          max: maxForeignBorn,
        },
        births_per_1000_people: {
          min: minBirths,
          max: maxBirths,
        },
        deaths_per_1000_people: {
          min: minDeaths,
          max: maxDeaths,
        },
      });
    },
    setSelectedProfileData({ commit, state }, id) {
      commit("changeSelectedProfileData", {
        meta: state.metadata[id],
        name: id,
      });
    },
    updateComparisonArea({ commit }, polygon) {
      commit("changeComparisonArea", polygon);
    },
    updateCategories({ commit }, categories) {
      commit("changeCategories", categories);
    },
    setMap({ commit, state }, map) {
      commit("mapSet", map);
    },
  },
  getters: {
    getProfileDataDict: (state) => state.profileDataDict,
    getSelectedProfileData: (state) => state.selectedProfileData,
    getComparisonArea: (state) => state.comparisonArea,
    getCategories: (state) => state.categories,
    getMap: (state) => state.map,
  },
  namespaced: true,
};

import { api } from "@/api";

class ReportService {
    async AreaStatistics(area) {
        try {
            return await api.post("api/report/area", area);
        } catch (error) {
            console.log(error);
            return error;
        }
    }

    async ReportMetadata(scenarioid) {
        const params = {
            scenarioid,
        };
        try {
            return await api.get("api/reportMetadata/scenario", { params });
        } catch (error) {
            console.log(error);
            return error;
        }
    }
}

export default new ReportService();

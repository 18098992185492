import WMTS from "ol/source/WMTS";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import TileLayer from "ol/layer/Tile";
import { useKeycloakStore } from "@/store/KeycloakStore";

const createLayer = (layer, name, visible) => {
  const keycloakStore = useKeycloakStore();

  return new TileLayer({
    name: name,
    visible: visible,
    source: new WMTS({
      url: "api/lantmateriet/" + layer,
      format: "image/png",
      projection: "EPSG:3006",
      wrapX: false,
      axisMode: "natural",
      tileGrid: new WMTSTileGrid({
        origin: [-1200000, 8500000],
        resolutions: [4096, 2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5,],
        matrixIds: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13",],
        extent: [-1200000, 4305696, 2994304, 8500000],
      }),
      version: "1.0.0",
      style: "default",
      matrixSet: "3006",
      crossOrigin: "anonymous",
      tileLoadFunction: function (imageTile, src) {
        const image = imageTile.getImage();
        var options = {
          headers: {
            Accept: "image/png",
            "Content-Type": "image/png",
            Authorization: `Bearer ${keycloakStore.instance.token}`,
          },
        };
        fetch(src, options)
          .then((response) => {
            if (response.status == 401 || response.status == 403) {
              window.location.href = "/unauthorized";
              return;
            }
            return response.blob();
          })
          .then((img) => {
            image.src = window.URL.createObjectURL(img);
          })
          .catch((error) => {
            console.log(error);
          });
      },
    })
  })
}

const getLight = () => {
  const light = createLayer("topowebb", "LM Light", true);
  const lightText = createLayer("topowebb-skikt", "LM Light Text", true);
  lightText.setZIndex(99);
  return { light, lightText };
}

const getDark = () => {
  const dark = createLayer("topowebb-dark", "LM Dark", false);
  const darkText = createLayer("topowebb-skikt-dark", "LM Dark Text", false);
  darkText.setZIndex(99);
  return { dark, darkText };
}

export { getLight, getDark };

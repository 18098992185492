import { api } from "@/api";

const getScenarioId = () => sessionStorage.getItem("scenarioId") ?? "2021";

class ProfileDataService {
  async getProfileData() {
    const scenarioId = getScenarioId();
    return api.get(`api/profileData/${scenarioId}`);
  }
}

export default new ProfileDataService();

import { toRaw } from "vue";
import GeoJSON from "ol/format/GeoJSON";
import { useToolStore } from "@/store/ToolStore";
import { useMapStore } from "@/store/MapStore";
import { useLogsumStore } from "@/store/LogsumStore";
import { usePopulationStore } from "@/store/PopulationStore";
import { useReportStore } from "@/store/ReportStore";
import { useMinasidorAreaStore } from "@/store/MinasidorAreaStore";

const _renderSearch = (geoJson) => {
    if (!geoJson) {
        return;
    }
    const mapStore = useMapStore();
    const toolStore = useToolStore();
    const source = mapStore.getReferenceLayer.getSource();

    toolStore.referenceSource = source;
    source.clear();

    const feature = new GeoJSON().readFeature(geoJson);
    source.addFeature(feature);
};

export const renderSavedSelection = (id) => {
    const mapStore = useMapStore();
    const toolStore = useToolStore();
    const minaSidorStore = useMinasidorAreaStore();
    const source = mapStore.getDrawLayer.getSource();

    toolStore.source = source;
    source.clear();

    const areas = minaSidorStore.savedAreas.filter((x) => x.id === id);
    const [area] = areas;
    const geometry = area.geometry;

    let data = toRaw(geometry);
    const feature = new GeoJSON().readFeature(data);

    source.addFeature(feature);
    const extent = source.getExtent();
    if (extent.length > 0) {
        mapStore.getMap.getView().fit(extent, {
            padding: [150, 150, 150, 150],
            duration: 500,
            maxZoom: 18,
        });
    }

    const areaInformation = {
        name: area.name,
    };
    toolStore.setActiveArea(feature, areaInformation);
};

export const zoomTo = (sourceType) => {
    if (sourceType === "compare") {
        const mapStore = useMapStore();
        const source = mapStore.getReferenceLayer.getSource();
        const [feature] = source.getFeatures();

        if (!feature) {
            mapStore.getMap.getView().setZoom(6);

            return;
        }

        const extent = source.getExtent();

        mapStore.getMap.getView().fit(extent, {
            padding: [150, 150, 150, 150],
            duration: 500,
            maxZoom: 18,
        });
    }
    if (sourceType === "selection") {
        const mapStore = useMapStore();
        const source = mapStore.getDrawLayer.getSource();

        const extent = source.getExtent();

        mapStore.getMap.getView().fit(extent, {
            padding: [150, 150, 150, 150],
            duration: 500,
            maxZoom: 18,
        });
    }
};

export const searchSavedAreaResultSelected = (geoJson) => {
    const feature = new GeoJSON().readFeature(geoJson);
    // const logsumStore = useLogsumStore();
    // const populationStore = usePopulationStore();
    const reportStore = useReportStore();

    // TODO: This does not work, throws exception table population.2022 does not exist?
    // logsumStore.selectedComparison = feature.getGeometry();
    // populationStore.setPopulationDataComparison(logsumStore.selectedComparison);
    reportStore.comparisonArea = feature;

    _renderSearch(geoJson);
};

export const searchSavedAreaResultDeselected = () => {
    const logsumStore = useLogsumStore();
    // const populationStore = usePopulationStore();
    const reportStore = useReportStore();

    logsumStore.selectedComparison = null;
    // populationStore.setPopulationDataComparison(logsumStore.selectedComparison);

    reportStore.comparisonArea = null;
    reportStore.statisticsComparison = null;
    reportStore.comparisonName = "Riket";
    reportStore.updateReport(false);

    _renderSearch(null);
};

import { defineStore } from "pinia";
import UserService from "@/services/UserService";

export const useUserStore = defineStore({
    id: "UserStore",
    state: () => ({
        sent: false,
        errorMessage: "",
        user: {},
    }),

    actions: {
        async sendMail() {
            try {
                await UserService.send();
                this.sent = true;
            } catch (error) {
                this.errorMessage = "Någonting gick fel.";
                console.log(error);
                return error;
            }
        },
    },
});

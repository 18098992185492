<script setup>
import { ref, computed } from "vue";
import router from "@/router";
import { useScenarioInformationStore } from "@/store/ScenarioInformationStore";
import TopNavigation from "@/components/navigation/TopNavigation.vue";

const scenarioStore = useScenarioInformationStore();

let compareFromId = ref("");
let compareToId = ref("");
let single = ref("");

const singleSelectionList = [
    { name: "Scenario 2022", id: "2022" },
    { name: "Scenario 2021", id: "2021" },
    { name: "Scenario 2020", id: "2020" },
    { name: "Scenario 2019", id: "2019" },
    { name: "Scenario 2018", id: "2018" },
    { name: "Scenario 2017", id: "2017" },
    { name: "Scenario 2016", id: "2016" },
    { name: "Scenario 2015", id: "2015" },
    { name: "Scenario 2014", id: "2014" },
    { name: "Scenario 2013", id: "2013" },
    { name: "Scenario 2012", id: "2012" },
    { name: "Scenario 2011", id: "2011" },
    { name: "Scenario 2010", id: "2010" },
    { name: "Scenario 2009", id: "2009" },
];

const props = defineProps({
    keycloak: Object,
    secretCompareMode: String,
});

const secretCompareModeEnabled = computed(() => {
    const secret = props.secretCompareMode;
    return secret && secret.length > 0 && secret === "compare";
});

const endSelectionList = computed(() => {
    return singleSelectionList.filter(
        (item) =>
            item.id !== compareFromId.value && item.id > compareFromId.value
    );
});

const setScenario = () => {
    if (single.value === "") return;
    let mode = "single";
    let scenarioId = single.value;

    const scenarioInformation = {
        mode,
        singleId: scenarioId,
        compareFromId: "",
        compareToId: "",
        scenarioId,
    };

    scenarioStore.storeScenario(scenarioInformation);
    goToMap();
};
const setEndScenario = () => {
    if (compareFromId.value === "" || compareToId.value === "") return;
    let mode = "compare";
    let scenarioId = compareFromId.value;

    const scenarioInformation = {
        mode,
        singleId: "",
        compareFromId: compareFromId.value,
        compareToId: compareToId.value,
        scenarioId,
    };

    scenarioStore.storeScenario(scenarioInformation);
    goToMap();
};
const goToMap = () => {
    router.push({ name: "MapView" });
};
</script>
<template>
    <TopNavigation></TopNavigation>

    <v-container fluid class="start-page">
        <div class="startpage-wrapper" style="margin: 3% auto 0">
            <v-row>
                <v-col md="8"> <h1 class="">Regionalanalys</h1></v-col>
            </v-row>
            <v-row>
                <v-col sm="12" md="8">
                    <p class="intro-p mb-10">
                        Regionalanalys är ett lättanvänt geografiskt
                        informationssystem som kan hjälpa dig i alla möjliga
                        typer av projekt kring samhällsutveckling. Här
                        kombineras analyser av tillgänglighet med flera olika
                        resesätt med socioekonomisk statistik. Analyserna är
                        oberoende av administrativa områden på grund av de
                        högupplösta geografiska databaserna. Välj nedan vilket
                        scenario (år) du vill titta på.
                    </p>
                    <v-row>
                        <v-col sm="12" md="8" lg="6">
                            <v-card class="puff">
                                <v-card-title
                                    >Titta på scenario
                                    <div class="icon-svg-single"></div
                                ></v-card-title>

                                <v-card-text>
                                    <v-select
                                        v-model="single"
                                        style="margin-bottom: 1em"
                                        hide-details
                                        density="compact"
                                        variant="solo"
                                        label="Välj ett scenario"
                                        item-title="name"
                                        item-value="id"
                                        :update:menu="setScenario()"
                                        :items="singleSelectionList"
                                    ></v-select>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col
                            sm="12"
                            md="8"
                            lg="6"
                            v-if="secretCompareModeEnabled"
                        >
                            <v-card class="puff">
                                <v-card-title
                                    >Jämför två scenario
                                    <div class="icon-svg-single"></div
                                ></v-card-title>

                                <v-card-text>
                                    <v-select
                                        v-model="compareFromId"
                                        style="margin-bottom: 1em"
                                        hide-details
                                        density="compact"
                                        variant="solo"
                                        label="Välj startår"
                                        item-title="name"
                                        item-value="id"
                                        :items="singleSelectionList"
                                    ></v-select>
                                    <v-select
                                        v-model="compareToId"
                                        style="margin-bottom: 1em"
                                        hide-details
                                        :disabled="compareFromId === ''"
                                        density="compact"
                                        variant="solo"
                                        label="Välj jämförelseår"
                                        item-title="name"
                                        item-value="id"
                                        :update:menu="setEndScenario()"
                                        :items="endSelectionList"
                                    ></v-select>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col sm="12" md="4">
                    <v-card class="side-puff">
                        <v-card-text class="pa-10">
                            <h2 class="mb-4">
                                Regionalanalys är ditt stöd inom
                                samhällsutveckling
                            </h2>
                            <p class="side-p mb-2">
                                Regionalanalys har, i samarbete mellan
                                Tillväxtverket och Trafikverket, gått från att
                                vara en idé till en prototyp till en testbar
                                version till där vi är idag – en fullt fungerade
                                version med flertalet data och genomarbetade
                                metoder för att beräkna tillgänglighet.
                            </p>
                            <p class="side-p mb-2">
                                Vi vill dock inte stanna där utan vårt mål är
                                att fortsätta utvecklingen av Regionalanalys och
                                öppna för ännu fler möjligheter och stöd till
                                alla som arbetar inom samhällsutveckling.
                            </p>
                            <p class="side-p mb-2">
                                Du som använder Regionalanalys är därför alltid
                                välkommen med kommentarer för att ge oss mer
                                förståelse för dina behov och hjälpa oss i rätt
                                riktning framåt.
                            </p>
                        </v-card-text>
                        <v-card-text
                            class="ml-4 mr-4 mb-4 pa-4"
                            style="background: #006d71; color: white"
                        >
                            <h3 class="pa-3 caption-m">
                                Kontakta oss med synpunkter och frågor om
                                Regionalanalys
                            </h3>
                            <p class="p-s pa-3 side-p">
                                <a
                                    target="_blank"
                                    class="no-link"
                                    style="color: white"
                                    href="https://pipos.se/vara-tjanster/regionalanalys/support---regionalanalys"
                                >
                                    Till supporten ></a
                                >
                            </p>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div class="tillvaxtverket-logga"></div>
    </v-container>
</template>

<style lang="scss">
.start-page {
    background-image: url("@/assets/images/start-compressed.png");
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 1920px;
    height: 100vh;
    overflow: auto;
    .v-card-title {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1em;
        margin-bottom: 0.5em;
    }
    .puff {
        text-align: center;

        .link {
            display: flex;
            justify-content: end;
            width: fit-content;
        }
    }
    .startpage-wrapper {
        width: 90%;
        // margin: 2em 4em auto;

        h1 {
            font-weight: 600;
            font-size: 2.5em;
            font-family: "NoeDisplay-Bold", Arial, sans-serif;
        }

        h2 {
            font-weight: 600;
            font-size: 1.5em;
            line-height: 1.3em;
        }

        .intro-p {
            font-size: 1.1em;
            line-height: 1.7em;
        }

        .side-p {
            font-size: 1.1em;
            line-height: 1.7em;
        }

        .no-link:hover,
        .no-link:visited,
        .no-link:active {
            // color: white;
            text-decoration: none;
        }
    }
    .box {
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .icon-svg-single {
        background-image: url(../assets/images/single.png);
        background-position: center;
        background-size: contain;
        height: 60px;
        width: 174px;
    }
    .icon-svg-compare {
        background-image: url(../assets/images/compare.png);
        background-position: center;
        background-size: contain;
        height: 73px;
        width: 174px;
    }
    .puff-wrapper {
        display: flex;
        flex-direction: column;
        justify-self: left;
        width: 550px;
        gap: 1.5em;

        .v-card.puff {
            justify-content: center;
            display: flex;
            align-items: center;

            cursor: pointer;
            height: 130px;

            a,
            a.link:visited,
            a.link:hover,
            a.link:active,
            a.link:focus {
                color: #7e7e7e;
                text-decoration: none;
            }

            .no-btn {
                font-family: inherit;
                font-size: 20px;
                font-weight: 500;
                background-color: transparent;
                letter-spacing: inherit;
                text-transform: none;
                box-shadow: none;
                height: 150px;
                opacity: 1;

                .v-btn__content {
                    flex-direction: column;
                }
            }
        }
        .v-card.puff:hover {
            background-color: rgb(240 240 240);
        }
    }
}
@import "@/assets/styles/project/_variables.scss";
// $tablet: 960px;
@media (max-width: $tablet) {
    .v-switch .v-selection-control {
        flex-direction: column-reverse;
        font-size: 12px;
    }
    .start-page {
        .puff-wrapper {
            width: 100%;
            .v-card.puff {
                width: 100%;
            }
        }
    }
}

// $desktop: 1280px;
@media (min-width: $desktop) {
    .start-page {
        .startpage-wrapper {
            width: 85%;
        }
    }
}

// $desktop-xl: 1920px;
@media (min-width: $desktop-xl) {
    .start-page {
        .startpage-wrapper {
            width: 75%;
            h1 {
                font-weight: 600;
                font-size: 3em;
            }

            h2 {
                font-size: 1.5em;
                line-height: 1.3em;
            }

            .intro-p {
                font-size: 1.1em;
                line-height: 1.7em;
            }

            .side-p {
                font-size: 1.1em;
                line-height: 1.4em;
            }
        }
        .intro-p {
            margin-right: 10em;
        }
    }

    .v-card.side-puff {
        p {
            font-size: 1.1em;
            line-height: 1.5em;
        }
    }
}

// $desktop-xxl: 2560px;
@media (min-width: $desktop-xxl) {
    .start-page {
        .startpage-wrapper {
            width: 65%;
        }
    }
}
</style>

import IsochroneWorker from "@/helpers/worker";
import { watch } from "vue";
import GeoJSON from "ol/format/GeoJSON";
import { useKeycloakStore } from "@/store/KeycloakStore";
import { useAccessibilityStore } from "@/store/AccessibilityStore";
import { useMapStore } from "@/store/MapStore";
import { useIsochroneStore } from "@/store/IsochroneStore";
import { useReportStore } from "@/store/ReportStore";
import { debounce } from "@/utils/debounce";
import { useBasePath } from "@/composable/useBasePath";
import { useToolStore } from "@/store/ToolStore";
import { Feature } from "ol";
import { Polygon } from "ol/geom";
import { storeToRefs } from "pinia";

class WorkerService {
    async init() {
        this._keycloakStore = useKeycloakStore();
        this._accessibilityStore = useAccessibilityStore();
        this._mapStore = useMapStore();
        this._isochroneStore = useIsochroneStore();
        this._currentNetwork = null;
        this._filteredService = {};
        this._isochroneWorker = new IsochroneWorker();
        this._reportStore = useReportStore();
        this._toolStore = useToolStore();
        const { basePath } = useBasePath();
        this._basePath = basePath;

        this.updateIsochroneDataDebounce = debounce(
            this.generatePointIsochroneBackend,
            500
        );

        watch(
            () => [
                this._isochroneStore.markedFeature,
                ...this._isochroneStore.features,
            ],
            async () => await this.generatePointIsochrone()
        );

        watch(
            () => this._accessibilityStore.travelingOptions,
            async () => await this.generatePointIsochrone(),
            { deep: true }
        );

        await this._isochroneWorker.init(
            this._keycloakStore.instance.token,
            this._basePath
        );
    }

    showAccessibilityLayer() {
        return this._serviceTypeStore.serviceTypes.some((s) => s.heatMapOn);
    }
    async generatePointIsochrone() {
        let features = this._isochroneStore.markedFeature
            ? [this._isochroneStore.markedFeature]
            : this._isochroneStore.features;

        if (!features || features.length == 0) {
            this._isochroneStore.isochrone = null;
            return;
        }
        // Om det är områden för nya eller raderad service sätt en färg på isochronerna
        const color = this._isochroneStore.markedFeature
            ? null
            : this._isochroneStore.featuresColor;

        const coordinates = features.map((feature) =>
            feature.getGeometry().getCoordinates()
        );
        const options = this._accessibilityStore.travelingOptions;
        const network = `${options.type}_${this._accessibilityStore.getTravelMode}`;
        const factor = options.mode == "time" ? 60 * 1000 : 1000;
        const thresholds = [options.selectedIntervalSwipe * factor];
        const radius =
            this._accessibilityStore.limitSettings[options.type].distance.max *
            1000;
        const settings = {
            mode: network,
            thresholds: thresholds,
            blurLevel: 0.1,
            radius,
            target: {
                x: coordinates[0][0],
                y: coordinates[0][1],
            },
            startTime: this._accessibilityStore.getTransitStartTimeInt,
        };

        // const data = await this._isochroneWorker.generate(settings);
        const featureCollection = await this._isochroneWorker.generateIsochrone(
            settings
        );
        const feature = new GeoJSON().readFeatures(featureCollection)[0];
        if (color && feature) {
            feature.set("color", color);
        }
        this._isochroneStore.isochrone = feature;
        await this.updateIsochroneData(featureCollection);
    }

    updateIsochroneData(featureCollection) {
        if (featureCollection.features.length > 0) {
            let polygon = featureCollection.features[0].geometry.coordinates[0];
            let count =
                featureCollection.features[0].geometry.coordinates[0][0].length;

            for (
                let i = 1;
                i < featureCollection.features[0].geometry.coordinates.length;
                i++
            ) {
                if (
                    featureCollection.features[0].geometry.coordinates[i][0]
                        .length > count
                ) {
                    count =
                        featureCollection.features[0].geometry.coordinates[i][0]
                            .length;
                    polygon =
                        featureCollection.features[0].geometry.coordinates[i];
                }
            }

            const feature = new Feature({
                geometry: new Polygon(polygon),
                name: "Isochrone",
                type: "Polygon",
            });

            this._toolStore.setActiveArea(feature);
        } else {
            this._toolStore.setActiveArea(null);
            this._isochroneStore.isochrone = [];
        }
    }
}

export default new WorkerService();

<template>
    <v-row>
        <v-col cols="12" class="pa-2"
            >Kartinställningar för reseanledningar</v-col
        >
    </v-row>
    <v-row class="align-center mt-4">
        <v-col class="pa-2 align-center d-flex">
            <v-label class="mr-3"><b>Punktlager</b></v-label>
            <div style="min-height: 24px; min-width: 24px;" class="d-flex align-center justify-center">
            <v-icon :style="circleSize" >mdi-checkbox-blank-circle</v-icon>
            </div>
        </v-col>
    </v-row>

    <v-row class="align-center mt-0" :style="{ minWidth: '300px' }">
        <v-col cols="10" class="pa-2">
            <v-slider
                dense
                hide-details
                v-model="pointLayerCircleSize"
                :max="8"
                :step="0.25"
                thumb-size="20"
                label="Storlek"
                :disabled="!categoryStore.getActivePointCategories.length"
            ></v-slider>
        </v-col>
        <v-col cols="2" class="pa-2">
            <InformationPopUp :text="Texts.helpInformation.no9.text">
            </InformationPopUp>
        </v-col>
    </v-row>
    <v-row class="align-center mt-0" :style="{ minWidth: '300px' }">
        <v-col cols="10" class="pa-2">
            <v-slider
                dense
                hide-details
                v-model="pointLayerOpacity"
                :max="100"
                :step="1"
                thumb-label
                thumb-size="20"
                label="Genomskinlighet"
                :disabled="!categoryStore.getActivePointCategories.length"
            ></v-slider>
        </v-col>
        <v-col cols="2" class="pa-2">
            <InformationPopUp :text="Texts.helpInformation.no10.text">
            </InformationPopUp>
        </v-col>
    </v-row>
</template>

<script setup>
import { useCategoryStore } from "@/store/CategoryStore";
import { useMapStore } from "@/store/MapStore";
import { storeToRefs } from "pinia";
import Texts from "@/texts/texts.json";
import InformationPopUp from "@/components/general/InformationPopUp.vue";
import { computed } from "vue";
const { pointLayerCircleSize, pointLayerOpacity } = storeToRefs(useMapStore());
const categoryStore = useCategoryStore();

const circleSize = computed(() => {
    return `font-size: ${8 + (pointLayerCircleSize.value * 2)}px;`;
});
</script>

<script setup>
import { watch, ref, toRaw } from "vue";
import GeoJSON from "ol/format/GeoJSON";
import { MultiPolygon, Polygon } from "ol/geom";
import { CalculationHelper } from "@/utils/calculationHelper";
import { MaIcon } from "@/utils/maIcons";
import { useMapStore } from "@/store/MapStore";
import { useToolStore } from "@/store/ToolStore";
import SearchService from "@/services/SearchService";

const mapStore = useMapStore();
const toolStore = useToolStore();

const props = defineProps({
    showAreaPanel: {
        type: Boolean,
        required: false,
    },
});

let search = ref("");
let select = ref("");
let items = ref([]);
let loading = ref(false);
let source = null;

watch(search, async (val) => {
    val && val !== select.value && (await querySelections(val));
});

watch(select, async (val) => itemSelected(val));

const querySelections = async (text) => {
    loading.value = true;
    const response = await SearchService.searchByPattern(text);
    items.value = response.data;
    loading.value = false;
};

const itemSelected = async (item) => {
    if (item == null) return;
    const response = await SearchService.getDetails(item?.type, item?.id);
    const calculate = CalculationHelper();
    if (response.status == 200) {
        const data = response.data;
        let properties = data.properties;
        
        if (response.data.geometry.type == "Polygon") {
            properties.area = calculate.getArea(new Polygon(response.data.geometry.coordinates));
        } else {
            properties.area = calculate.getArea(new MultiPolygon(response.data.geometry.coordinates));
        }
        properties.date = "";

        if (source == null) {
            source = mapStore.getDrawLayer.getSource();
        }
        toolStore.source = source;
        source.clear();
        let geometry = toRaw(data.geometry);

        const feature = new GeoJSON().readFeature(geometry);
        feature.setProperties(properties);
        source.addFeature(feature);

        const extent = source.getExtent();
        mapStore.getMap.getView().fit(extent, {
            padding: [150, 150, 150, 150],
            duration: 500,
            maxZoom: 18,
        });

        const areaInformation = {
            name: item.name,
        };
        toolStore.setActiveArea(feature, areaInformation);
    }
};
</script>
<script>
export default {
    name: "AreaPanel",
};
</script>
<template>
    <div class="area-table-wrapper">
        <v-card
            style="margin-top: 1em"
            class="area-table"
            v-show="props.showAreaPanel"
        >
            <div class="panel-selector">
                <div class="caption-xs mb-4">
                    Hämta ett område för län, kommun eller ort
                </div>
                <v-autocomplete
                    v-model="select"
                    v-model:search="search"
                    :loading="loading"
                    :items="items"
                    item-value="name"
                    item-title="name"
                    :no-filter="true"
                    class="search"
                    variant="solo"
                    hide-no-data
                    clearable
                    density="compact"
                    return-object
                    prepend-inner-icon="mdi-magnify"
                    menu-icon=""
                    label="Sök område"
                >
                    <template v-slot:item="{ props, item }">
                        <v-list-item
                            v-bind="props"
                            :prepend-icon="MaIcon[item?.raw?.type]"
                            :title="item?.raw?.name"
                            :subtitle="item?.raw?.location"
                        ></v-list-item>
                    </template>
                </v-autocomplete>
            </div>
        </v-card>
    </div>
</template>
<style lang="scss">
.area-table-wrapper {
    position: absolute;
    right: 3em;
    top: 13.4em;
    width: 350px;
    z-index: 200;

    .area-table {
        width: 350px;
        .v-table > .v-table__wrapper > table > tbody > tr > td {
            padding: 4px 8px;
            height: 35px;
            font-size: 12px;
        }

        .v-table--density-default
            > .v-table__wrapper
            > table
            > thead
            > tr
            > th {
            height: 35px;
            font-size: 12px;
            padding-left: 4px;
            background-color: #8d8d8d;
            color: white;

            .v-icon {
                color: white;
            }
        }
        tbody {
            tr.select:hover td:not(:last-child) {
                background-color: lightgrey;
            }

            td {
                padding: 0 8px;
            }

            td.select:hover {
                cursor: pointer;
            }
        }
        .v-label {
            font-size: 12px;
        }
    }
}
.panel-selector {
    padding: 0.5em;
    background-color: rgb(243 243 243);
    font-size: 14px;
}
</style>

import { defineStore } from "pinia";
import PopulationService from "@/services/PopulationService";
import { ToolType } from "@/constants";
const defaultSelectionArea = [
    {
        name: "Män",
        data: [
            414, 5132, 24686, 64699, 125935, 219254, 268233, 265210, 285462,
            324658, 339035, 338455, 323615, 338542, 378916, 369377, 307498,
            298045, 320338, 319463, 305880,
        ],
        stackGroup: "Male",
    },
    {
        name: "Kvinnor",
        data: [
            414, 5132, 24686, 64699, 125935, 219254, 268233, 265210, 285462,
            324658, 339035, 338455, 323615, 338542, 378916, 369377, 307498,
            298045, 320338, 319463, 305880,
        ],
        stackGroup: "Female",
    },
];
const defaultComparisonArea = [
    {
        name: "Män jämförelseområde",
        data: [
            414, 5132, 24686, 64699, 125935, 219254, 268233, 265210, 285462,
            324658, 339035, 338455, 323615, 338542, 378916, 369377, 307498,
            298045, 320338, 319463, 305880,
        ],
        stackGroup: "Male",
    },
    {
        name: "Kvinnor jämförelseområde",
        data: [
            414, 5132, 24686, 64699, 125935, 219254, 268233, 265210, 285462,
            324658, 339035, 338455, 323615, 338542, 378916, 369377, 307498,
            298045, 320338, 319463, 305880,
        ],
        stackGroup: "Female",
    },
];
export const usePopulationStore = defineStore({
    id: "PopulationStore",
    state: () => ({
        comparisonArea: defaultComparisonArea,
        selectionArea: defaultSelectionArea,
    }),
    actions: {
        async getPopulationData(location) {
            let chartData = false;
            if (location.type === ToolType.CIRCLE) {
                const response = await PopulationService.getPopulationCircle(
                    location
                );
                chartData = response.data;
            }
            if (location.type === ToolType.POLYGON) {
                const response = await PopulationService.getPopulationPolygon(
                    location
                );
                chartData = response.data;
            }
            if (chartData && chartData.length > 0) {
                this.selectionArea = chartData;
                //   commit("changeLocationData", chartData);
            } else {
                this.comparisonArea = defaultComparisonArea;
                this.selectionArea = defaultSelectionArea;
            }
        },
        async setPopulationDataComparison(area) {
            const response = await PopulationService.getPopulationPolygon({
                geometry: area,
            });
            const chartData = response.data;
            if (chartData && chartData.length > 0) {
                this.comparisonArea = chartData;
                //   commit("changeLocationDataComparison", chartData);
            } else {
                this.comparisonArea = defaultComparisonArea;
                this.selectionArea = defaultSelectionArea;
                //   commit("resetLocationData");
            }
        },
    },
    getters: {
        comparisonAreaPercent: (state) => {
            const clone = JSON.parse(JSON.stringify(state.comparisonArea));

            const total = clone[0].data.reduce((acc, a) => acc + a, 0);
            const adjusted = clone[0].data.map(
                (v) => Math.round((v / total) * 10000) / 100
            );
            clone[0].data = adjusted;

            const total1 = clone[1].data.reduce((acc, a) => acc + a, 0);
            const adjusted1 = clone[1].data.map(
                (v) => Math.round((v / total1) * 10000) / 100
            );
            clone[1].data = adjusted1;

            return clone;
        },
        selectionAreaPercent: (state) => {
            const clone = JSON.parse(JSON.stringify(state.selectionArea));

            const total = clone[0].data.reduce((acc, a) => acc + a, 0);
            const adjusted = clone[0].data.map(
                (v) => Math.round((v / total) * 10000) / 100
            );
            clone[0].data = adjusted;

            const total1 = clone[1].data.reduce((acc, a) => acc + a, 0);
            const adjusted1 = clone[1].data.map(
                (v) => Math.round((v / total1) * 10000) / 100
            );
            clone[1].data = adjusted1;

            return clone;
        },
    },
});

<script setup>
import { ref } from "vue";
import { useMinasidorSettingsStore } from "@/store/MinasidorSettingsStore";
import { getBackgroundUrl } from "@/components/wizard/wizard.js";

const pageNo = ref(1);
const showAgain = ref(true);
const settingsStore = useMinasidorSettingsStore();

const closeAndSave = async () => {
    await settingsStore.saveWizardSetting(showAgain.value);
    settingsStore.settings.properties.wizard = "hide";
};
</script>
<script>
export default {
    name: "WizardDialog",
};
</script>
<template>
    <v-container
        fluid
        fill-height
        class="wizard-background"
        :style="{ backgroundImage: getBackgroundUrl(pageNo) }"
    ></v-container>
    <div class="wizard-dialog">
        <v-dialog
            v-model="settingsStore.showWizard"
            persistent
            height="500px"
            width="660px"
        >
            <v-card class="card-wrapper">
                <v-card-actions style="display: flex; flex-direction: row">
                    <v-checkbox
                        v-model="showAgain"
                        hide-no-data
                        density="compact"
                    >
                        <template v-slot:label>
                            <div>Visa inte igen</div>
                        </template>
                    </v-checkbox>
                    <v-btn @click="closeAndSave()" style="color: white">
                        <v-icon
                            icon="mdi mdi-close-circle"
                            style="font-size: 2em; color: white"
                        ></v-icon
                    ></v-btn>
                </v-card-actions>
                <v-card-text v-show="pageNo === 1" class="text-center">
                    <h2 class="pb-3">Välkommen till nya Regionalanalys!</h2>
                    <p>
                        Det övergripande syftet med Regionalanalys är att enkelt
                        kunna definiera, beskriva och jämföra olika geografiska
                        områden inom Sverige. Detta görs dels genom statistik
                        som beskriver områdenas karaktär, men dels också genom
                        att beskriva områdena utifrån dess tillgänglighet till
                        platser dit människor har anledning att ta sig
                        (reseanledningar). Här kommer en kort guide som visar
                        dig runt. Klicka på pilar eller siffror för att bläddra
                        i guiden eller tryck på krysset för att gå direkt till
                        Regionalanalys.
                    </p>
                </v-card-text>
                <v-card-text v-show="pageNo === 2"
                    ><h2 class="pb-3 text-center">Reseanledningar</h2>
                    <p>
                        Här kan du slå på lager som visar olika reseanledningar
                        i kartan. Reseanledningar är de olika mål som människor
                        har för att ta sig till olika platser. Dessa är
                        uppdelade i ett antal kategorier och underkategorier. Du
                        kan dels slå på reseanledningarna som punkter i kartan
                        som visar deras ungefärliga geografiska placering och
                        dels som konturkartor som visualiserar de
                        reseanledningar du valt i ett sammanvägt
                        tillgänglighetindex i kartan. Tillgänglighetsindexet
                        påverkas av vilken beräkningsmetod du väljer - vi
                        återkommer till det i steg 4 i den här guiden.
                    </p></v-card-text
                >
                <v-card-text v-show="pageNo === 3">
                    <h2 class="pb-3 text-center">
                        Kartinställningar för reseanledningar
                    </h2>
                    <p>
                        Här kan du göra inställningar för hur du vill att punkt-
                        och konturlagren för reseanledningarna ska visualiseras
                        i kartan. Du kan justera storlekar på punkter,
                        genomskinlighet och tröskelvärden för konturkartorna.
                    </p></v-card-text
                >
                <v-card-text v-show="pageNo === 4">
                    <h2 class="pb-3 text-center">
                        Välj beräkningsmodell och färdsätt
                    </h2>
                    <p>
                        Här väljer du vilken metod som ska användas för att
                        beräkna hur tillgänglighetsindexet till reseanledningar
                        ska mätas samt vilket eller vilka färdsätt som ska tas
                        med som variabler i beräkningen av
                        tillgänglighetsindexet. Det du väljer här kommer slå
                        igenom i rapporter och konturkartor som beskriver det
                        sammanvägda tillgänglighetsindexet för reseanledningar
                        för olika områden.
                    </p></v-card-text
                >
                <v-card-text v-show="pageNo === 5">
                    <h2 class="pb-3 text-center">Populationslager</h2>
                    <p>
                        Här kan du slå på olika lager i kartan som indikerar på
                        att det finns registrerade folkbokförda, deltidsboende
                        och sysselsatta. Genom dessa lager kan du till exempel
                        bedöma om det har någon betydelse att ett område i
                        kartan har väldigt låg tillgänglighet i förhållande till
                        de reseanledningar som du har valt att titta på i
                        kartan.
                    </p></v-card-text
                >
                <v-card-text v-show="pageNo === 6">
                    <h2 class="pb-3 text-center">
                        Verktyg för att välja områden
                    </h2>
                    <p>
                        Här kan du med hjälp av olika verktyg skapa eller hämta
                        upp geografiska områden i kartan. När du skapat/hämtat
                        ett område får du upp en rapport med uppgifter om vad
                        som finns inom det område du valt. I rapporten får du
                        också möjlighet att jämföra det valda området med andra
                        områden både vad gäller vad som finns inom områdena men
                        också hur tillgängligheten till olika reseanledningar
                        ser ut.
                    </p></v-card-text
                >
                <v-card-text v-show="pageNo === 7">
                    <h2 class="pb-3 text-center">Teckenförklaring</h2>
                    <p>
                        I teckenförklaringen kan du se information om vad som
                        visas i kartan för tillfället.
                    </p></v-card-text
                >
                <v-card-text v-show="pageNo === 8">
                    <h2 class="pb-3 text-center">Behöver du hjälp?</h2>
                    <p>
                        Om du känner dig osäker när du navigerar runt i
                        Regionalanalys kan du alltid klicka på de frågetecken
                        som finns i anslutning till de olika funktionerna. Där
                        kan du läsa mer om vad du kan göra just där och hur det
                        påverkar andra delar av tjänsten. Annars är du välkommen
                        att kontakta vår support som du finner uppe i högra
                        hörnet i toppmenyn, där även denna guide finns.
                    </p></v-card-text
                >

                <v-card-actions>
                    <v-pagination
                        class="wizard-pager"
                        v-model="pageNo"
                        :length="8"
                        rounded="circle"
                        prev-icon="mdi-menu-left"
                        next-icon="mdi-menu-right"
                    ></v-pagination>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<style lang="scss">
@import "@/assets/styles/project/_variables.scss";
.wizard-background {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;

    max-width: 100%;
    min-height: 100vh;
    h2 {
        text-align: center;
        padding-bottom: 1.5em;
    }
}
.v-overlay__scrim {
    opacity: 0 !important;
}
.v-card.card-wrapper {
    background-color: black;
    opacity: 0.7;
    color: white;
    height: 400px;
    width: 100%;
    text-align: center;

    .v-pagination {
        width: 100%;
    }

    a {
        text-decoration: none;
    }
    .v-input__control {
        .v-icon,
        .v-label {
            opacity: 1;
            color: white;
        }
    }
    .v-input {
        display: flex;
        justify-content: end;
    }
    .v-input__details {
        display: none;
    }
}
</style>

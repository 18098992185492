import { createRouter, createWebHistory } from "vue-router";

import Unauthorized from "@/components/UnauthorizedView.vue";

import ProfileView from "../views/ProfileView.vue";
import Home from "../views/Home.vue";
import MapView from "../views/MapView.vue";
import { useBasePath } from "@/composable/useBasePath";
import StartPage from "@/views/StartPage.vue";

const routes = [
    {
        path: "/start/:secretCompareMode?",
        name: "Start",
        component: StartPage,
        props: true
    },
    {
        path: "/:category?",
        name: "Home",
        component: Home,
    },
    {
        path: "/map",
        name: "MapView",
        component: MapView,
    },
    {
        path: "/profile",
        name: "ProfileView",
        component: ProfileView,
    },
    {
        path: "/unauthorized",
        name: "Unauthorized",
        component: Unauthorized,
    },
];

const { subPath } = useBasePath();

const router = createRouter({
    history: createWebHistory(subPath),
    routes,
});

export default router;

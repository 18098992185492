import { api } from "@/api";
import GeoJSON from "ol/format/GeoJSON";

const getScenarioId = () => sessionStorage.getItem("scenarioId") ?? "2021";

class PopulationService {
  async getPopulationAll() {
    const params = {
      scenarioid: getScenarioId(),
    };
    return api.get("api/population/all", { params });
  }

  async getPopulationCircle(circle) {
    const params = {
      x: circle.center[1],
      y: circle.center[0],
      radius: circle.radius,
      scenarioid: getScenarioId(),
    };
    return api.get("api/population/circle", { params });
  }

  async getPopulationArea(id) {
    const params = {
      id,
      scenarioid: getScenarioId(),
    };
    return api.get("api/population/area", { params });
  }

  async getPopulationPolygon(polygon) {
    let geoJson = null;
    if (polygon.geometry) {
      geoJson = new GeoJSON().writeGeometryObject(polygon.geometry, 'EPSG:3006', 'EPSG:3006');
    } 
    const body = {
      scenarioId: getScenarioId(),
      polygon: geoJson,
    };
    return api.post("api/population/polygon", body);
  }

  async getLayer(categories) {
    const params = {
        categories,
        year: getScenarioId(),
    }
    return await api.get("davinci/populationdensity", { params });
  }
}

export default new PopulationService();

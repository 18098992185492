export const formatNumber = (value) => {
    if (value < 0) {
        return Math.floor(value);
    } else {
        return Math.round(value);
    }
};
export const meterToKm = (distance) => {
    return Math.round(distance / 1000).toLocaleString("se-SE");
};
export const meter2ToKm2 = (area) => {
    return Math.round(area / 1000000).toLocaleString("se-SE");
};
export const formatBigNumber = (value) =>
    value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

export const getAreaFormated = (drawObject) => {
    const options = { projection: "EPSG:3006" };
    const temp = drawObject.getArea(options);
    let area = formatArea(temp);
    return area;
};

export const formatArea = (area) => {
    if (typeof area === "string") {
        area = parseInt(area);
    }
    let output;
    output = (Math.round((area / 1000000) * 100) / 100).toFixed(1);
    let temp = output.toString().replace(".", ",");
    return temp + " km²";
};

<template>
    <div id="zoomContainer" ref="zoomContainer"></div>
    <v-tooltip v-if="zoomIsLoaded"
        text="Zooma in i kartan" 
        :max-width="300"
        activator="button.ol-zoom-in"
    >
    </v-tooltip>
    <v-tooltip v-if="zoomIsLoaded"
        text="Zooma ut i kartan" 
        :max-width="300"
        activator="button.ol-zoom-out"
    >
    </v-tooltip>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const zoomIsLoaded = ref(false);
const zoomContainer = ref(null);

const waitForZoom = () => {
    // Wait for zoomContainer to have any children elements
    let counter = 0;
    return new Promise((resolve, reject) => {
        let timer = setInterval(() => {
            counter++;
            if (counter > 20) {
                reject("Zoom control not loaded in 10 seconds");
                clearInterval(timer);
            }
            if (zoomContainer.value && zoomContainer.value.children.length > 0) {
                resolve();
            }
        }, 500)
    });
    
};

onMounted(() => {
    waitForZoom().then(() => {
        zoomIsLoaded.value = true;
    });
});
</script>

<style lang="scss">
#zoomContainer {
    margin-top: 1em;
    
    .ol-control {
        position: relative !important;
    }

    .ol-control button:hover,
    .ol-control button:focus {
        outline: none;
    }

    .ol-zoom {
        gap: 0.3em;
        display: flex;
        flex-direction: column;
        top: auto !important;
        left: auto !important;

        button {
            border-radius: 6px;
        }
    }
}
</style>
